<div class="header-top">
    <div class="container">
        <div class="row align-items-center">
            <div class="col-lg-12">
                <div class="d-flex align-items-left justify-content-start  pr-5">
                    <a [routerLink]="['/get-app-link']" class="ml-3 mr-3 text-dark ">
                        <div class="icon d-flex align-items-center">
                            <i class="feather-user h6 pt-2 pr-2 d-bloc"></i> <span style="margin-bottom: -4px;">Get the App</span>
                        </div>
                    </a>
                    <a [routerLink]="['/offers']" class=" mr-3 text-dark ">
                        <div class="icon d-flex align-items-center">
                            <i class="feather-disc h6  pt-2 pr-2 d-bloc"></i> <span style="margin-bottom: -4px;">Offers</span>
                        </div>
                    </a>
                    <!-- <div class="dropdown mr-4 m-none"> -->
                    <a href="#" class="dropdown-toggle text-dark py-2 d-block" id="dropdownMenuButton"
                        data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
                        <i class="feather-smartphone h6  pt-2 pr-2 d-bloc"></i><span>Mobile</span>
                    </a>
                    <div class="dropdown-menu dropdown-menu-left" aria-labelledby="dropdownMenuButton">
                        <a class="dropdown-item" [href]="contactList?.mobile_app_url?.customer?.android"
                            target="_blank">Android App</a>
                        <a class="dropdown-item" [href]="contactList?.mobile_app_url?.customer?.ios" target="_blank"
                            *ngIf="contactList?.mobile_app_url?.customer?.ios !== '#'">
                            iOS App
                        </a>
                    </div>
                    <!-- </div> -->
                </div>
            </div>
        </div>
    </div>
</div>

<header class="section-header">
    <section class="header-main shadow-sm bg-white">
        <div class="container">
            <div class="row align-items-center">

                <div class="col-4 d-flex align-items-center sp_class1 m-none">
                    <div class="dbutton_1 mr-1">
                        <a [routerLink]="['/home']" class="brand-wrap de_1 mb-0 text-dark" [title]="constant.CHAT_MY_ORDER">
                            <img alt="#" class="img-fluid" src="assets/img/logo_1.svg">
                        </a>
                        <ng-container *ngIf="!localStorageSwitchGroupOrderData">
                            <a [ngClass]="headerFulfillmentType == 'Delivery' ? 'dnew2_btn' : 'dnew_btn'"  (click)="onClickFulfillmentType('Delivery')">
                                <span class="b23">Delivery</span>
                            </a>
                            <a [ngClass]="headerFulfillmentType == 'Pickup' ? 'dnew2_btn' : 'dnew_btn'"   (click)="onClickFulfillmentType('Pickup')">
                                <span class="b23">Pickup</span>
                            </a>
                        
                            <b *ngIf="selectedCurrentLocation" class="pad_1">{{ headerFulfillmentType == 'Delivery' ? 'to' : 'near' }}</b>
                       </ng-container>
                    </div>
                    <div class="dropdown mr-3" *ngIf="!localStorageSwitchGroupOrderData">
                        <a class="text-dark dropdown-toggle d-flex align-items-center py-3" href="#" id="navbarDropdown1" role="button" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
                            <!-- <div>
                                <i class="feather-map-pin mr-2 bg-light rounded-pill p-2 icofont-size"></i>
                            </div> -->
                            <div>
                                <b *ngIf="selectedCurrentLocation || displayHeaderSearchLocation" class="pad_2">{{ headerFulfillmentType == 'Delivery' ? 'to' : 'near' }}</b>
                                <p class="text-dark mb-0 small" *ngIf="!selectedCurrentLocation && !displayHeaderSearchLocation">Select Location</p>
                                <p class="text-dark mb-0 small gh">{{displayHeaderSearchLocation}}</p>
                            </div>
                        </a>

                        <div class="dropdown-menu p-0 drop-loc" aria-labelledby="navbarDropdown1">
                            <div class="osahan-country">
                                <div class="search_location border-bottom bg-white pt-3 pb-3 text-center" *ngIf="selectedCurrentLocation && headerFulfillmentType">
                                    <p class="text-dark mb-0 small">{{ headerFulfillmentType == 'Delivery' ? 'Deliver to:' : 'Pickup near:' }}</p>
                                    <p class="text-primary mb-0 small tdt"><b>{{ selectedCurrentLocation }}</b></p>
                                </div>

                                <div class="search_location bg-white p-2 text-right">
                                    <div class="input-group rounded shadow-sm overflow-hidden">
                                        <!-- <div class="input-group-prepend">
                                            <button class="border-0 btn btn-outline-secondary text-dark bg-white btn-block">
                                                <i class="feather-search"></i>
                                            </button>
                                        </div> -->
                                        <input [type]="selectedCurrentLocation ? 'search' : 'text'" class="shadow-none border-0 form-control phs" [placeholder]="selectedCurrentLocation ? 'Search new address...' : 'Search for address...'" [(ngModel)]="currentLocation" (ngModelChange)="getAddress($event)" (keydown)="locationkeyDown($event)"
                                            (keyup.enter)="searchOnLocationEnter()">
                                    </div>
                                </div>
                                <div class="p-3 border-bottom" aria-labelledby="userLocationSearchInput">
                                    <a class="text-decoration-none dropdown-item search-input-width"
                                        (click)="onClickCurrentLocation()" [class.active]="arrowkeyUserLocation === 0">
                                        <a class="font-weight-bold text-black m-0">
                                            <i class="feather-map-pin"></i>
                                            Use Current Location
                                        </a>
                                    </a>
                                    <ng-container *ngIf="placesList?.length">
                                        <ng-container *ngFor="let place of placesList; let i = index">
                                            <a  class="text-decoration-none auto-fild dropdown-item search-input-width px-0 py-3 w-100 px-3 text-dark" (click)="selectLocation(place)"
                                                [class.active]="(i + 1) === arrowkeyUserLocation" *ngIf="place?.description">
                                                {{ place.description }}
                                            </a>
                                            <a  class="text-decoration-none dropdown-item auto-fild search-input-width px-0 py-3 w-100 px-3 text-dark" (click)="selectLocation(place)"
                                                [class.active]="(i + 1) == arrowkeyUserLocation" *ngIf="!place?.description">
                                                <b>{{ place.label }}</b> - {{ place.street }}, 
                                                <span *ngIf="place.address_line_2">{{ place.address_line_2 }}, </span>{{ place.city }}, {{ place.state }} {{ place.zipcode }}
                                            </a>
                                        </ng-container>
                                    </ng-container>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>

                <div class="col-4">
                    <div class="d-flex align-items-center sp_class sright" *ngIf="!localStorageSwitchGroupOrderData">
                        <div class="dropdown d-search mr-2 ml-1 m-none">
                            <div class="input-group rounded shadow-sm overflow-hidden" id="storeSearchInput" aria-haspopup="true" aria-expanded="false">
                                <!-- <div class="input-group-prepend">
                                    <button class="btn input-group-text bg-white  border-right-0 text-primary">
                                        <a (click)="searchOnEnter()" [title]="constant.SEARCH">
                                                <i class="feather-search"></i>
                                        </a>
                                    </button>
                                </div> -->
                                <input type="text" class="shadow-none border-1 border-right-0 form-control search-height" placeholder="Search for Restaurants or Items" (keydown)="keyDown($event)"
                                    [(ngModel)]="searchLocationValue" (ngModelChange)="onSearchLocation($event)" (keyup.enter)="searchOnEnter()">
                                <div class="input-group-prepend mr-0">
                                    <a class="btn input-group-text bg-primary form-control border-left-0 text-primary search-height" (click)="searchOnEnter()" [title]="constant.SEARCH">
                                        <i class="feather-search text-white"></i>
                                    </a>
                                </div>
                                <!-- <div class="input-group-prepend" style="margin-right: 0px;">
                                    <a class="btn  bg-white  border text-primary" (click)="searchOnEnter()" [title]="constant.SEARCH">
                                        <i class="feather-arrow-right-circle"></i>
                                    </a>
                                </div> -->
                            </div>
                            <div class="dropdown-menu search-drop dropdown-menu-left" aria-labelledby="storeSearchInput" *ngIf="filterList?.length && searchLocationValue">
                                <a class="dropdown-item search-input-width" *ngFor="let filter of filterList; let i = index" (click)="selectPlace(filter)" [class.active]="i == arrowkeyLocation">
                                    <div class="d-flex a1 p-2">
                                        <ng-container *ngIf="filter?.card_image?.url">
                                            <img alt="osahan" [src]="filter?.card_image?.url" class="mr-3 rounded-circle img-fluid logo-height-width">
                                        </ng-container>

                                        <ng-container *ngIf="filter?.image?.url">
                                            <img alt="osahan" [src]="filter?.image?.url" class="mr-3 rounded-circle img-fluid logo-height-width">
                                        </ng-container>

                                        <ng-container *ngIf="!filter?.image?.url && !filter?.card_image?.url">
                                            <img alt="osahan" [src]="filter?.sku ? 'assets/img/icons/item_placeholder.png' : 'assets/img/icons/restaurant_placeholder.png'"
                                                class="mr-3 rounded-circle img-fluid logo-height-width">
                                        </ng-container>

                                        <div class="d-flex flex-column">
                                            <span class="mb-0 search-font">{{ filter.name }}</span>
                                            <span class="mb-0 small">{{ filter?.sku ? 'Item' : 'Restaurant'}}</span>
                                        </div>
                                    </div>
                                </a>

                                <a class="dropdown-item search-input-width text-primary" *ngIf="showMoreRestaurant" (click)="searchOnEnter()"
                                    [class.active]="arrowkeyLocation == 5" [title]="constant.SHOW_MORE">
                                    Show more
                                </a>
                            </div>
                        </div>
                    </div>
                </div>

                <div class="col-4">
                    <div class="d-flex align-items-center sp_class2 pr-5">
                        <!-- <div class="dropdown d-search mr-2 ml-1 m-none">
                            <div class="input-group rounded shadow-sm overflow-hidden" id="storeSearchInput" aria-haspopup="true" aria-expanded="false">
                                <input type="text" class="shadow-none border-1 border-right-0 form-control search-height" placeholder="Search for Restaurants or Items" (keydown)="keyDown($event)"
                                    [(ngModel)]="searchLocationValue" (ngModelChange)="onSearchLocation($event)" (keyup.enter)="searchOnEnter()">
                                <div class="input-group-prepend mr-0">
                                    <a class="btn input-group-text bg-primary form-control border-left-0 text-primary search-height" (click)="searchOnEnter()" [title]="constant.SEARCH">
                                        <i class="feather-search text-white"></i>
                                    </a>
                                </div>
                            </div>
                            <div class="dropdown-menu search-drop dropdown-menu-left" aria-labelledby="storeSearchInput" *ngIf="filterList?.length && searchLocationValue">
                                <a class="dropdown-item search-input-width" *ngFor="let filter of filterList; let i = index" (click)="selectPlace(filter)" [class.active]="i == arrowkeyLocation">
                                    <div class="d-flex a1 p-2">
                                        <ng-container *ngIf="filter?.card_image?.url">
                                            <img alt="osahan" [src]="filter?.card_image?.url"
                                                class="mr-3 rounded-circle img-fluid logo-height-width">
                                        </ng-container>

                                        <ng-container *ngIf="filter?.image?.url">
                                            <img alt="osahan" [src]="filter?.image?.url"
                                                class="mr-3 rounded-circle img-fluid logo-height-width">
                                        </ng-container>

                                        <ng-container *ngIf="!filter?.image?.url && !filter?.card_image?.url">
                                            <img alt="osahan"
                                                [src]="filter?.sku ? 'assets/img/icons/item_placeholder.png' : 'assets/img/icons/restaurant_placeholder.png'"
                                                class="mr-3 rounded-circle img-fluid logo-height-width">
                                        </ng-container>

                                        <div class="d-flex flex-column">
                                            <span class="mb-0 search-font">{{ filter.name }}</span>
                                            <span class="mb-0 small">{{ filter?.sku ? 'Item' : 'Restaurant'}}</span>
                                        </div>
                                    </div>
                                </a>

                                <a class="dropdown-item search-input-width text-primary" *ngIf="showMoreRestaurant" routerLink="/search" [class.active]="arrowkeyLocation == 5"
                                    [queryParams]="{searchText: searchLocationValue}" [title]="constant.SHOW_MORE">
                                    Show more
                                </a>
                            </div>
                        </div> -->

                        <!-- near by -->
                        <a [routerLink]="['/near-by']" class="widget-header near_1 text-black btn m-none" [title]="constant.RESTAURANT_NEARBY_VIEW" *ngIf="!localStorageSwitchGroupOrderData">
                            <div class="icon d-flex align-items-center">
                                <span>{{constant.RESTAURANT_NEARBY_VIEW}}</span>
                            </div>
                        </a>

                        <!-- Sign In/ Sign Out -->
                        <div class="dropdown mr-3 m-none" *ngIf="!isLogin">
                            <a href="#" class="dropdown-toggle text-dark py-3 d-block" id="dropdownMenuButton"
                                data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
                                <!-- <i class="feather-user h6 mr-2 mb-0"></i> -->
                                <span>{{constant.SIGN_IN}} / {{constant.SIGN_UP}}</span>
                            </a>
                            <div class="dropdown-menu dropdown-menu-left" aria-labelledby="dropdownMenuButton">
                                <a class="dropdown-item" routerLink="/login"> <i
                                        class="feather-user mr-2"></i>{{constant.SIGN_IN}}</a>
                                <a class="dropdown-item" routerLink="/signup"><i class="feather-edit mr-2"></i>
                                    {{constant.SIGN_UP}}</a>
                            </div>
                        </div>

                        <!-- Notifications -->
                        <div class="m-none" *ngIf="isLogin">
                            <a href="#" class="nav-link1 widget-header1 text-dark py-2 b-right" [title]="constant.RESTAURANT_NOTIFICATION" role="button" id="notificationListButton" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false" (click)="onClickNotificationDropdown()"  data-bs-auto-close="outside" aria-expanded="false">
                                <div class="icon d-flex align-items-center">
                                    <i class="feather-bell  mb-0" [ngClass]="totalUnReadNotificationCount > 0 ? 'icon_active' : 'icon_inactive'"></i>
                                </div>
                            </a>
                            <div class="dropdown-menu notification-dropdown-menu dropdown-menu-right" (click)="$event.stopPropagation()" aria-labelledby="notificationListButton">
                                <ng-container *ngIf="notifications.length > 0; else emptyNotification">
                                    <div class="col-md-12 px-0 border-top">
                                        <div class="p-3 border-bottom gold-members" *ngFor="let notification of notifications; index as i" [ngClass]="notification.read ? 'read-notification' : ''">
                                            <!-- <span class="float-right">
                                                <button class="btn btn-outline-primary btn-sm"
                                                    *ngIf="!notification.read"
                                                    (click)="markAsReadNotification(notification._id)"
                                                    [title]="constant.MARK_AS_READ">
                                                    <i class="fa fa-check"></i>
                                                </button>

                                                <button class="btn btn-sm read-notification" *ngIf="notification.read">
                                                    <i class="fa fa-check"></i>
                                                </button>

                                                <button class="btn btn-outline-three  btn-sm  ml-2"
                                                    (click)="deleteNotification(notification._id)"
                                                    [title]="constant.DELETE_TOOLTIP">
                                                    <i class="fa fa-trash"></i>
                                                </button>
                                            </span> -->

                                            <div class="media nt-short">
                                                <!-- <div class="mr-3 font-weight-bold">{{i + 1}}</div> -->
                                                <div class="media-body">
                                                    <h6 class="mb-1">{{notification.title}}</h6>
                                                    <p class="text-muted mb-0 small">{{notification.body}}</p>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </ng-container>
                                <ng-template #emptyNotification>
                                    <div class="col-md-12 px-0 border-top">
                                        <div class="media-body text-center">
                                            <img alt="#" src="assets/img/extra/push_notifications.svg" class="img-fluid or-img item-img w-100">
                                            <h6 class="mb-1 pt-2" style="text-align: center;">No unread Notifications found.</h6>
                                        </div>
                                    </div>
                                </ng-template>
                                <div class="p-3 text-center">
                                    <a class="link" (click)="navigateToNotification()" [title]="constant.VIEW_ALL_NOTIFICATION">
                                        {{constant.VIEW_ALL_NOTIFICATION}}
                                    </a>
                                </div>
                            </div>
                        </div>
                        
                        <!--favrate-->

                        <a [routerLink]="['/favorites']" class="nav-link1 widget-header1 text-dark py-2 b-right" [title]="constant.RESTAURANT_FAVORITE" role="button" *ngIf="isLogin">
                            <div class="icon d-flex align-items-center">
                                <i class="feather-heart  icon_inacive  mb-0"></i>
                            </div>
                        </a>

                        <!---end favrate-->

                        <!-- order-->

                        <a [routerLink]="['/account/orders']" [routerLink]="['/account/orders']" class="nav-link1 widget-header1 text-dark py-2"
                            [title]="constant.RESTAURANT_MY_ORDERS" role="button" *ngIf="isLogin">
                            <div class="icon d-flex align-items-center">
                                <i class="feather-box icon_inacive  mb-0"></i>
                            </div>
                        </a>

                        <!-- end order-->

                        <!-- cart -->
                        <div class="dropdown  m-none" *ngIf="isLogin && (((!isInGroup || !localStorageSwitchGroupOrderData) && cartOrderList?.items?.length  > 0) || (isInGroup && getGroupCartTotalItem() > 0) || ( getGroupCartTotalItem() > 0 ) && localStorageSwitchGroupOrderData)">
                            <a class="nav-link1 widget-header1 text-dark py-2 b-right " style="margin-left: -5px;" (click)="goToCheckout()" id="dropdownMenuButton1" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
                                <div class="icon d-flex align-items-center">
                                    <i class="feather-shopping-cart icon_inacive mr-1 mb-0"></i>
                                    <span class="badge badge-success" *ngIf="isLogin">
                                        <ng-container *ngIf="!localStorageSwitchGroupOrderData; else inGroupTemplate">
                                            <ng-container *ngIf="cartOrderList?.items?.length > 0">
                                                {{cartOrderList?.items?.length}}
                                            </ng-container>
                                        </ng-container>
                                        <ng-template #inGroupTemplate>
                                            <ng-container *ngIf="getGroupCartTotalItem() > 0">
                                                {{ getGroupCartTotalItem() }}
                                            </ng-container>
                                        </ng-template>
                                    </span>
                                </div>
                            </a>

                            <div class="dropdown-menu cart-dropdown-menu dropdown-menu-right" aria-labelledby="dropdownMenuButton1">
                                <div class="row p-3" *ngIf="!localStorageSwitchGroupOrderData && groupCartList?.store_details?.name">
                                    <div class="col-sm-7 box203 w-100">
                                        <h5 class="text-white p-2 pt-3 font-weight-bold text-center">Individual Order Cart</h5>
                                    </div>
                                    <div class="col-sm-5 text-center bg-warning">
                                         <a class="btn d-block ml-auto text-dark" [title]="constant.SWITCH_TO_GROUP_ORDER_MODE" (click)="switchToOtherCart('group')">
                                             <div class="icon d-flex align-items-center">
                                                 <small *ngIf="groupCartList?.customer_id != loggedInUserId">
                                                    You are in a group order with <b>{{ groupCartList?.group_host_name }}</b>.<br>
                                                    <b>Switch</b> to Group Order
                                                 </small>
                                                 <small *ngIf="groupCartList?.customer_id == loggedInUserId">
                                                    You are Hosting a <b>Group Order</b>.<br>
                                                    <b>Switch</b> to Group Order
                                                </small>
                                                 <i class="fas feather-repeat pl-1"></i>
                                             </div>
                                         </a>
                                    </div>
                                </div>

                                <!-- Switch Normal Cart -->
                                <ng-container class="osahan-country" *ngIf="!localStorageSwitchGroupOrderData; else openGroupCartTemplate">
                                    <div class="osahan-cart-item rounded rounded shadow-sm overflow-hidden scroll bg-white sticky_sidebar"
                                        *ngIf="isLogin && cartOrderList?.items?.length">

                                        <div class="border-bottom  osahan-cart-item-profile bg-white px-3 py-2">
                                            <div class="row">
                                                <div class="col-8">
                                                    <h6 class="mb-1 font-weight-bold">
                                                        <a class="view-cart store-font" (click)="goToRestuarantPage()">{{cartOrderList?.store_details?.name}}</a>
                                                    </h6>
                                                </div>
                                                <div class="col-4">
                                                    <button
                                                        class="btn btn-outline-primary btn-sm float-right justify-content-end"
                                                        data-toggle="modal" data-target="#clearCartItemModel"
                                                        [title]="constant.CLEAR_CART">
                                                        {{constant.CLEAR_CART}}
                                                    </button>
                                                </div>
                                            </div>
                                        </div>

                                        <div class="normal-cart-body-height">
                                            <ng-container *ngFor="let cartItem of cartOrderList.items">
                                                <!-- <div class="bg_ex" *ngIf="!cartItem?.is_active || !cartItem?.in_stock"> -->
                                                <div class="bg_ex" *ngIf="!cartItem?.is_active || (cartItem?.in_stock == false || cartItem?.snoozed == true)">
                                                    <div class="container">
                                                        <div class="row">
                                                            <div class="col-lg-12 text-center">
                                                                <h6 class="mb-0 text-white fs-10">{{ constant.INACTIVE_ITEM_REMOVE }}</h6>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>

                                                <div class="bg-white border-bottom py-2" >
                                                    <div class="row gold-members line-word  d-flex align-items-center justify-content-between px-3 py-2">
                                                        <div class="col-md-6">
                                                            <div class="media align-items-center">
    
                                                                <!-- <div class="mr-2"
                                                                    [ngClass]="cartItem.is_veg ? 'text-success' : 'text-danger'">
                                                                    &middot;
                                                                </div> -->
    
                                                                <div class="media-body">
                                                                    <p class="m-0">
                                                                        
                                                                        <a class="text-info" (click)="openItemAddUpdateModal(cartItem, false)" title="{{cartItem.name}}">
                                                                            {{cartItem.name}}
                                                                        </a>
                                                                    </p>
                                                                    <div class="subProduct" *ngIf="cartItem.sub_products.length > 0">
                                                                        <div *ngFor="let sub of cartItem.sub_products">
                                                                            <span>{{ sub.name }}</span>
                                                                            <span class="text-dark  mb-0 t-pre  small" >
                                                                                {{sub.price | currency:'USD' }}{{ " ("+ "Quantity : " +sub.quantity+")" }}
                                                                           </span>
                                                                        
                                                                        </div>
                                                                    </div> 
                                                                    <!-- if variant and topping are in product -->
                                                                    <!-- <ng-container *ngIf="cartItem.toppings?.length && cartItem?.variants?.length">
                                                                        <p class="text-gray newalign small">
                                                                            Toppings:
                                                                            {{getOrderVariantToppingDetail(cartItem,'topping')}}
                                                                        </p>
                                                                        <p class="text-gray newalign small">
                                                                            {{getOrderVariantToppingDetail(cartItem,'variant')}}
                                                                        </p>
                                                                    </ng-container> -->
    
                                                                    <!-- if only topping is in product -->
                                                                    <!-- <ng-container *ngIf="cartItem.toppings?.length && !cartItem?.variants?.length">
                                                                        <p class="text-gray newalign small">
                                                                            Toppings:
                                                                            {{getOrderVariantToppingDetail(cartItem,'topping')}}
                                                                        </p>
                                                                    </ng-container> -->
    
                                                                    <!-- if only variant is in product -->
                                                                    <!-- <ng-container *ngIf="!cartItem.toppings?.length && cartItem.variants?.length">
                                                                        <p class="text-gray newalign small">
                                                                            {{getOrderVariantToppingDetail(cartItem,'variant')}}
                                                                        </p>
                                                                    </ng-container> -->

                                                                    <!-- If sold out option in the item -->
                                                                    <!-- <p class="text-gray newalign small" *ngIf="cartItem?.sold_out_action">
                                                                        Sold out option: {{ cartItem?.sold_out_action }}
                                                                    </p> -->
                                                                </div>
    
                                                            </div>
                                                        </div>
    
                                                        <div class="col-md-1">
                                                            <span data-toggle="modal" data-target="#deleteCartItemModelV2" (click)="confirmDeleteCartItem(cartItem._id)">
                                                                <i class="fa fa-trash text-danger a-cursor" [title]="constant.DELETE_TOOLTIP"></i>
                                                            </span>
                                                        </div>
    
                                                       <div class="col-md-3">
                                                            <span class="count-number" *ngIf="cartItem?.is_active && (cartItem?.in_stock == true || cartItem?.snoozed == false)">
                                                                <button type="button" class="btn-sm left dec btn btn-outline-secondary"
                                                                    (click)="onClickAddRemoveCartItem2(cartItem._id, 'remove')"
                                                                    [disabled]="cartAddRemoveLoading || (cartItem.quantity === 1) "
                                                                    [title]="cartOrderList?.store_details?.store_status === 'CUT_OFF_TIME' ? constant.STOP_TO_TAKE_ORDER :
                                                                             cartOrderList?.store_details?.store_status === 'CLOSED' ? constant.RESTAURANT_CLOSED :
                                                                            (cartItem.quantity === 1) ? '' : constant.DECREASE_QTY">
                             
                                                                            <i class="feather-minus"></i>
                                                                </button>
                                                                <input class="count-number-input" type="text" readonly=""
                                                                    value="{{cartItem.quantity}}">
                                                                <button type="button" class="btn-sm right inc btn btn-outline-secondary"
                                                                    (click)="onClickAddRemoveCartItem2(cartItem._id, 'add')"
                                                                    [disabled]="cartAddRemoveLoading "
                                                                    [title]="cartOrderList?.store_details?.store_status == 'CUT_OFF_TIME' ? constant.STOP_TO_TAKE_ORDER :
                                                                             cartOrderList?.store_details?.store_status == 'CLOSED' ? constant.RESTAURANT_CLOSED : 
                                                                             cartItem?.quantity >= 25 ? constant.QTY_MAX_LIMIT : constant.INCREASE_QTY">
                                                                    <i class="feather-plus"></i>
                                                                </button>
                                                            </span>
                                                        </div>

                                                        <div class="col-md-2">
                                                            <p class="text-gray mb-0 float-right ml-0 text-muted small">
                                                                {{ (cartItem.total_price) | currency:'USD' }}
                                                            </p>
                                                        </div>
                                                    </div>
                                                </div>
                                            </ng-container>
                                        </div>

                                        <div class="bg-white p-3 clearfix border-bottom">
                                            <p class="mb-1">
                                                Subtotal
                                                <span class="float-right text-dark">
                                                    {{cartOrderList?.sub_total | currency:'USD'}}
                                                </span>

                                                
                                            </p>

                                            <p class="mb-1 text-success" *ngIf="cartOrderList?.discount?.amount > 0">
                                                Promo ({{cartOrderList?.discount?.code}}) &nbsp;
                                                <i class="fa fa-trash text-danger a-cursor"
                                                    (click)="removeDiscountOnCartItem()"
                                                    [title]="constant.DELETE_TOOLTIP"></i>
                                                <span class="float-right text-success">
                                                    - {{cartOrderList?.discount?.amount | currency: 'USD'}}
                                                </span>
                                            </p>
                                        </div>

                                        <div class="p-3" *ngIf="!isInCartPage">
                                            <a class="btn btn-success btn-block btn-lg"
                                                [routerLink]="['/checkout/normal']"
                                                routerLinkActive="router-link-active" [title]="constant.CHECKOUT">{{constant.CHECKOUT}} <i class="fa fa-arrow-right"></i>
                                            </a>
                                        </div>

                                    </div>

                                    <div class="osahan-cart-item rounded rounded shadow-sm overflow-hidden bg-white sticky_sidebar"
                                        *ngIf="!isLogin || !cartOrderList?.items?.length">
                                        <div class="media-body text-center">
                                            <img alt="#" src="assets/img/extra/empty_cart.svg"
                                                class="img-fluid or-img item-img w-100">
                                            <h6 class="mb-1 pt-2" style="text-align: center;">Your cart is empty </h6>
                                            <h6 class="mb-1 pt-0" style="text-align: center;">Add items to get started
                                            </h6>
                                        </div>
                                    </div>
                                </ng-container>

                                <!-- Switch Group Cart -->
                                <ng-template #openGroupCartTemplate>
                                    <ng-container *ngTemplateOutlet="groupOrderCartTemplate"></ng-container>
                                    <!-- <app-group-order-cart></app-group-order-cart> -->
                                </ng-template>
                            </div>
                        </div>


                        <a class="toggle text-dark" href="#">
                            <span></span>
                        </a>
                    </div>

                </div>
            </div>
        </div>
    </section>

    <ng-container *ngIf="groupCartList && groupCartList?.customer_id && checkCurrentPageURL() == true && isLogin && !localStorageSwitchGroupOrderData">
        <!-- For host -->
        <div class="bg-warning bg-warning position-relative" *ngIf="(groupCartList.customer_id == loggedInUserId)">
            <div class="container">
                <div class="p-1">
                    <div class="align-items-center">
                        <div  class="mb-0 align-items-center row">
                            <div class="col-sm-2 "></div>
                            <div class="col-sm-8 col-10">
                                <p class="text-dark mb-0">
                                    You are Hosting a Group Order.&nbsp;
                                    <a class="swgroup" (click)="switchToCart()">Switch to Group Order</a>&nbsp;to add items and manage the order.
                                </p>
                            </div>
                            <div class="col-sm-2 col-2 text-right">
                                <a class="btn btn-sm  ml-auto text-dark" (click)="switchToCart()" [title]="constant.SWITCH_TO_GROUP_ORDER_MODE">
                                    <div class="icon d-flex align-items-center">
                                        <i class="fas feather-repeat h5 mb-0 pr-2"></i>
                                    </div>    
                                </a>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    
        <!-- For guest -->
        <div class="bg-warning bg-warning position-relative" *ngIf="(groupCartList.customer_id != loggedInUserId)">
            <div class="container">
                <div class="p-1">
                    <div class="align-items-center">
                        <div  class="mb-0 align-items-center row">
                            <div class="col-sm-2 "></div>
                            <div class="col-sm-8 col-10"> 
                                <p class="text-dark mb-0">
                                    You are still in a Group Order with {{ groupCartList?.group_host_name }}.&nbsp;
                                    <a class="swgroup" (click)="switchToCart()">Switch to Group Order</a>&nbsp;to finish adding items.
                                </p>
                            </div>
                            <div class="col-sm-2 col-2 text-right">
                                <a class="btn btn-sm  ml-auto text-dark" (click)="switchToCart()" [title]="constant.SWITCH_TO_GROUP_ORDER_MODE">
                                    <div class="icon d-flex align-items-center">
                                        <i class="fas feather-repeat h5 mb-0 pr-2"></i>
                                    </div>    
                                </a>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </ng-container>
</header>

<div id="gMap" #gMap></div>

<!-- for Responsive -->
<div class="d-none fixed-header">
    <div class="bg-primary pl-3 d-flex align-items-center" [ngClass]="{'mat-elevation-z5' : isSticky, 'sticky_2' : !isSticky}">
        <a class="toggle togglew pl-1 toggle-2" href="#"><span></span></a>

        <a [routerLink]="['/home']" class="brand-wrap mb-0">
            <img alt="#" class="img-fluid" src="assets/img/logo_1.svg">
        </a>
        <div class="gr pl-2" *ngIf="isLogin && localStorageSwitchGroupOrderData">
            <h5 class="text-white mb-0">Group Order</h5>
            <p class="small text-white mb-0" *ngIf="groupCartList?.group_host_name">Hosted by {{ groupCartList?.group_host_name }}</p>
        </div>
        <ng-container *ngIf="!localStorageSwitchGroupOrderData">
            <div class="dropdown pl-2">
    
                <a class="dropdown-toggle d-flex align-items-center" href="#" id="navbarDropdown" role="button" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false" style="color:#fff">
                    <!-- <div>
                        <i class=" text-dark feather-map-pin mr-2 bg-light rounded-pill p-2 icofont-size"></i>
                    </div> -->
                    <div style="color:#fff">
                        <p class="mb-0 small" style="color:#fff">
                            <b *ngIf="selectedCurrentLocation || displayHeaderSearchLocation">{{ headerFulfillmentType == 'Delivery' ? 'Deliver to' : 'Pickup near' }}</b>
                            <span *ngIf="!selectedCurrentLocation && !displayHeaderSearchLocation">Select Location</span>
                        </p>
                        <p class="mb-0 small text-white gh">{{ displayHeaderSearchLocation }}</p>
                    </div>
                </a>
    
                <div class="dropdown-menu moblie-search p-0" aria-labelledby="navbarDropdown">
                    <div class="osahan-country">
                        <div class="search_location border-bottom bg-white p-3 text-center">
                            <a [ngClass]="headerFulfillmentType == 'Delivery' ? 'dnew2_btn' : 'dnew_btn'" type="button" class="m-1 btn" (click)="onClickFulfillmentType('Delivery')">
                                <span class="b23">Delivery</span>
                            </a>
                            <a [ngClass]="headerFulfillmentType == 'Pickup' ? 'dnew2_btn' : 'dnew_btn'" type="button" class="m-1 btn" (click)="onClickFulfillmentType('Pickup')">
                                <span class="b23">Pickup</span>
                            </a>
                        </div>

                        <div class="search_location border-bottom bg-white p-3 text-center" *ngIf="selectedCurrentLocation && headerFulfillmentType">
                            {{ headerFulfillmentType == 'Delivery' ? 'Deliver to:' : 'Pickup near:' }}<br>
                            <span class="text-primary"><b>{{ selectedCurrentLocation }}</b></span>
                        </div>
                        
                        <div class="search_location bg-white p-2 text-right">
                            <div class="input-group rounded shadow-sm overflow-hidden">
                                <!-- <div class="input-group-prepend">
                                    <button class="border-0 btn btn-outline-secondary text-dark bg-white btn-block">
                                        <i class="feather-search"></i>
                                    </button>
                                </div> -->
                                <input [type]="selectedCurrentLocation ? 'search' : 'text'" class="shadow-none border-0 form-control phs" [placeholder]="selectedCurrentLocation ? 'Search new address' : 'Search for address...'" [(ngModel)]="currentLocation" (ngModelChange)="getAddress($event)">
                            </div>
                        </div>
                        <div class="p-3 border-bottom">
                            <a class="text-decoration-none" (click)="onClickCurrentLocation()">
                                <p class="font-weight-bold text-primary m-0">
                                    <i class="feather-map-pin"></i>
                                    Use Current Location
                                </p>
                            </a>
                        </div>
                        <div class="filter" *ngIf="placesList?.length">
                            <div class="custom-control  border-bottom px-0 ">
                                <ng-container *ngFor="let place of placesList; let i = index">
                                    <li class="py-3 auto-fild w-100 px-3" (click)="selectLocation(place)" *ngIf="place?.description">
                                        {{ place.description }}
                                    </li>
                                    <li class="py-3 auto-fild w-100 px-3" (click)="selectLocation(place)" *ngIf="!place?.description">
                                        <b>{{ place.label }}</b> - {{ place.street }}, 
                                        <span *ngIf="place.address_line_2">{{ place.address_line_2 }}, </span>{{ place.city }}, {{ place.state }}, {{ place.country }} ,{{ place.zipcode }}
                                    </li>
                                </ng-container>
                            </div>
                        </div>
                    </div>
                </div>
    
            </div>
            <a [routerLink]="['/account/notifications']"  class="text-white small font-weight-bold  pl-4  d-block text-decoration-none" *ngIf="isLogin">
                <i class="feather-bell  mb-0"  [ngClass]="totalUnReadNotificationCount > 0 ? 'icon_active' : 'icon_minactive'"></i>
                <!-- <span class="badge badge-success badge-align" *ngIf="totalUnReadNotificationCount > 0">{{ totalUnReadNotificationCount }}</span> -->
            </a>
        </ng-container>
    </div>

    <div class="container new-containe">
        <div class="p-3 d-none" *ngIf="(cartOrderList && !localStorageSwitchGroupOrderData) || (groupCartList && localStorageSwitchGroupOrderData)" [ngClass]="{'mat-elevation-z5' : isSticky, 'bg-success': (getCurrentUserOrderStatus() && getCurrentUserCartLength() && localStorageSwitchGroupOrderData), 'bg-primary': (!getCurrentUserCartLength() || !getCurrentUserOrderStatus() || !localStorageSwitchGroupOrderData)}">
            <div class="row align-items-center">
    
                <!-- For Normal order -->
                <ng-container *ngIf="!localStorageSwitchGroupOrderData">
                    <div class="vvol" [ngClass]="!localStorageSwitchGroupOrderData && cartOrderList?.items?.length > 0 ?  'col-7' : 'col-8'">
                        <!---new serch test-->
                        <div class="dropdown d-search">
                            <div class="input-group rounded shadow-sm overflow-hidden force-opaque" id="storeSearchInput"
                                aria-haspopup="true" aria-expanded="false">
        
                                <input type="text" class="shadow-none border-0 border-right-0 form-control  force-opaque force-opaque2"
                                    placeholder="Search for Restaurants" (keydown)="keyDown($event)"
                                    [(ngModel)]="searchLocationValue" (ngModelChange)="onSearchLocation($event)"
                                    (keyup.enter)="searchOnEnter()">
        
                                <div class="input-group-prepend mr-0">
                                    <a class="btn input-group-text bg-white form-control border-left-0 border-top-0 border-bottom-0 text-primary"
                                        (click)="searchOnEnter()">
                                        <i class="feather-search"></i>
                                    </a>
                                </div>
        
                            </div>
                            <div class="dropdown-menu search-drop dropdown-menu-left" aria-labelledby="storeSearchInput"
                                *ngIf="filterList?.length && searchLocationValue">
                                <a class="dropdown-item search-input-width" *ngFor="let filter of filterList; let i = index"
                                    (click)="selectPlace(filter)" [class.active]="i === arrowkeyLocation">
        
        
                                    <div class="d-flex a1 p-2">
                                        <ng-container *ngIf="filter?.card_image?.url">
                                            <img alt="osahan" [src]="filter?.card_image?.url"
                                                class="mr-3 rounded-circle img-fluid logo-height-width">
                                        </ng-container>
        
                                        <ng-container *ngIf="filter?.image?.url">
                                            <img alt="osahan" [src]="filter?.image?.url"
                                                class="mr-3 rounded-circle img-fluid logo-height-width">
                                        </ng-container>
        
                                        <ng-container *ngIf="!filter?.image?.url && !filter?.card_image?.url">
                                            <img alt="osahan"
                                                [src]="filter?.sku ? 'assets/img/icons/item_placeholder.png' : 'assets/img/icons/restaurant_placeholder.png'"
                                                class="mr-3 rounded-circle img-fluid logo-height-width">
                                        </ng-container>
        
                                        <div class="d-flex flex-column">
                                            <span class="mb-0 search-font">{{ filter.name }}</span>
                                            <span class="mb-0 small">{{ filter?.sku ? 'Item' : 'Restaurant'}}</span>
                                        </div>
                                    </div>
                                </a>
                                <a class="dropdown-item search-input-width text-primary" *ngIf="showMoreRestaurant"
                                    (click)="searchOnEnter()"
                                    [class.active]="arrowkeyLocation === 5" [title]="constant.SHOW_MORE">
                                    {{constant.SHOW_MORE}}
                                </a>
                            </div>
                        </div>
                        <!-- end test-->
                    </div>
                    <div class="col-2">
                        <a [routerLink]="['/near-by']" class="text-white small font-weight-bold   d-block text-decoration-none">
                            {{constant.RESTAURANT_NEARBY_VIEW}}
                        </a>
                    </div>
                </ng-container>
    
                <!-- end for normal order -->
    
                <!-- For Group order -->
                <ng-container *ngIf="isLogin && localStorageSwitchGroupOrderData">
                    <!-- [ngClass]="getCurrentUserOrderStatus() ? 'col-10 text-center' : 'col-10'" -->
                    <div class="col-9">
                        <h5 class="text-white mb-0 notem" *ngIf="getCurrentUserOrderStatus() && getCurrentUserCartLength()">
                            <i class="feather-check pr-2"></i>
                            {{ constant.GROUP_ORDER.SAVED_ITEM }}
                            <span>
                                <a class="text-white review-order" [routerLink]="['/checkout/group']" [title]="constant.GROUP_ORDER.REVIEW_ORDER">
                                    {{ constant.GROUP_ORDER.REVIEW_ORDER }}
                                </a>
                            </span>
                        </h5>
                        <h5 class="text-white mb-0 d-flex align-items-center  notem w-100" *ngIf="!getCurrentUserCartLength() && !getCurrentUserOrderStatus()">
                            <i class="feather-clock pr-2"></i>
                            <span> Please <b>add your items</b> to the group order.</span>                        
                        </h5>
                        <h5 class="text-white mb-0 d-flex align-items-center notem w-100" *ngIf="getCurrentUserCartLength() && !getCurrentUserOrderStatus()">
                            <i class="feather-clock pr-2"></i>
                        <span> Please <b>finish adding items</b> to your <b>Group Order</b> and then <b>Save Your Order</b>.</span> 
                        </h5>
                    </div>
                </ng-container>
    
                <!-- end for normal order -->
    
                <ng-conatiner *ngIf="isLogin">
                    <ng-container *ngIf="!localStorageSwitchGroupOrderData; else inGroupTemplate">
                        <ng-container *ngIf="cartOrderList?.items?.length > 0">
                            <div class="col-2">
                                <a (click)="goToCheckout()"
                                    class="text-white small font-weight-bold pt-2  d-block text-decoration-none">
                                    <div class="icon d-flex align-items-center">
                                        <i class="feather-shopping-cart h6 mr-2"></i>
                                        <span class="badge badge-success h6">
                                            {{cartOrderList?.items?.length}}
                                        </span>
                                    </div>
                                </a>
                            </div>
                        </ng-container>
                    </ng-container>
                    <ng-template #inGroupTemplate>
                        <ng-container *ngIf="!getCurrentUserOrderStatus()">
                            <div class="col-3 text-right">
                                <a (click)="goToCheckout()"
                                    class="text-white small font-weight-bold pt-2 d-block text-decoration-none">
                                    <div class="icon d-flex align-items-center">
                                        <i class="feather-shopping-cart h6 mr-2"></i>
                                        <span class="badge badge-success h6" *ngIf="getGroupCartTotalItem() > 0">
                                            {{ getGroupCartTotalItem() }}
                                        </span>
                                    </div>
                                </a>
    
                            </div>
                        </ng-container>
                    </ng-template>
                </ng-conatiner>
    
                <div [ngClass]="{'hidden-toggle': isSticky, '' : !isSticky}">
                    <a class="toggle togglew pl-1 toggle-2" href="#"><span></span></a>
                </div>
            </div>
        </div>
        <div class="d-none" *ngIf="groupCartList && checkCurrentPageURL() == true && isLogin && localStorageSwitchGroupOrderData">
            <div class="p-1 bg-warning" *ngIf="groupCartList?.group_host_note" [ngClass]="{'mat-elevation-z5' : isSticky, 'sticky_2' : !isSticky}">
                <h5 class="text-left small mb-0" style="font-style: italic; margin-left: 16px; word-break: break-word;">
                    <b *ngIf="groupCartList?.customer_id == loggedInUser?._id">Your Notes:</b>
                    <b *ngIf="groupCartList?.customer_id != loggedInUser?._id">Host Notes:</b>
                    {{ groupCartList?.group_host_note }}
                </h5>
            </div>
            <div class="p-1 bg-white" [ngClass]="{'mat-elevation-z5' : isSticky, 'sticky_2' : !isSticky}">
                <h5 class="text-dark small mb-0" [ngClass]="{'text-left': groupCartList?.customer_id != loggedInUser?._id, 'text-center': groupCartList?.customer_id == loggedInUser?._id}">
                    To browse other restaurants switch to
                    <a class="swgroup" (click)="switchToOtherCart('normal')"><b>Individual Order</b></a> mode<ng-container *ngIf="groupCartList?.customer_id != loggedInUser?._id">
                        OR you can <a class="swgroup" (click)="leaveGroupOrder()"><b>Leave Group Order</b></a> permanently</ng-container>.
                </h5>
            </div>
        </div>
        <div class="p-2 align-items-center bg-warning" *ngIf="groupCartList && groupCartList?.customer_id && checkCurrentPageURL() == true && isLogin && !localStorageSwitchGroupOrderData">
            <!-- For host -->
            <div class="bg-warning " *ngIf="(groupCartList.customer_id == loggedInUserId)" [ngClass]="{'mat-elevation-z5' : isSticky, 'sticky_2' : !isSticky}">
                <div class="container">
                    <div class="p-1">
                        <div class="align-items-center">
                            <div  class="mb-0 align-items-center row">
                                <div class="col-sm-2 "></div>
                                <div class="col-sm-8 col-10">
                                    <p class="text-dark mb-0">
                                        You are Hosting a Group Order.&nbsp;
                                        <a class="swgroup" (click)="switchToCart()">Switch to Group Order</a>&nbsp;to add items and manage the order.
                                    </p>
                                </div>
                                <div class="col-sm-2 col-2 text-right">
                                    <a class="btn btn-sm  ml-auto text-dark" (click)="switchToCart()" [title]="constant.SWITCH_TO_GROUP_ORDER_MODE">
                                        <div class="icon d-flex align-items-center">
                                            <i class="fas feather-repeat  mb-0 h5 pr-2"></i>
                                        </div>    
                                    </a>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        
            <!-- For guest -->
            <div class="bg-warning " *ngIf="(groupCartList.customer_id != loggedInUserId)" [ngClass]="{'mat-elevation-z5' : isSticky, 'sticky_2' : !isSticky}">
                <div class="container">
                    <div class="p-1">
                        <div class="align-items-center">
                            <div  class="mb-0 align-items-center row">
                                <div class="col-sm-2 "></div>
                                <div class="col-sm-8 col-10"> 
                                    <p class="text-dark mb-0">
                                        You are still in a Group Order with {{ groupCartList?.group_host_name }}.&nbsp;
                                        <a class="swgroup" (click)="switchToCart()">Switch to Group Order</a>&nbsp;to finish adding items.
                                    </p>
                                </div>
                                <div class="col-sm-2 col-2 text-right">
                                    <a class="btn btn-sm  ml-auto text-dark" (click)="switchToCart()" [title]="constant.SWITCH_TO_GROUP_ORDER_MODE">
                                        <div class="icon d-flex align-items-center">
                                            <i class="fas feather-repeat h5 mb-0 pr-2"></i>
                                        </div>    
                                    </a>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>    
 


 <!---moblie msg group order-->



 <!--end Group order-->

</div>
<div class="sticky-hd"></div>

<!-- cart item delete modal -->
<div class="modal fade" id="deleteCartItemModel" tabindex="-1" role="dialog" aria-labelledby="deleteCartItemModalLabel"
    aria-hidden="true">
    <div class="modal-dialog modal-dialog-centered">
        <div class="modal-content">
            <div class="modal-header">
                <h5 class="modal-title">Delete Item</h5>
                <button type="button" class="close" data-dismiss="modal" aria-label="Close"
                    [title]="constant.CLOSE_TOOLTIP">
                    <span aria-hidden="true">&times;</span>
                </button>
            </div>
            <div class="modal-body text-center">
                <h5 class="m-0 text-danger"> {{constant.ARE_YOU_SURE}} delete this item?</h5>
            </div>
            <div class="modal-footer p-0 border-0">
                <div class="col-6 m-0 p-0">
                    <button type="button" class="btn border btn-lg btn-block" data-dismiss="modal"
                        [title]="constant.CLOSE_TOOLTIP">
                        {{constant.CLOSE_TOOLTIP}}
                    </button>
                </div>
                <div class="col-6 m-0 p-0">
                    <button type="button" class="btn btn-primary btn-lg btn-block" data-dismiss="modal"
                        (click)="deleteCartItem()" [title]="constant.DELETE_TOOLTIP">
                        {{constant.DELETE_TOOLTIP}}
                    </button>
                </div>
            </div>
        </div>
    </div>
</div>
<!-- cart item delete modal -->
<div class="modal fade" id="deleteCartItemModelV2" tabindex="-1" role="dialog" aria-labelledby="deleteCartItemModalLabel"
    aria-hidden="true">
    <div class="modal-dialog modal-dialog-centered">
        <div class="modal-content">
            <div class="modal-header">
                <h5 class="modal-title">Delete Item</h5>
                <button type="button" class="close" data-dismiss="modal" aria-label="Close"
                    [title]="constant.CLOSE_TOOLTIP">
                    <span aria-hidden="true">&times;</span>
                </button>
            </div>
            <div class="modal-body text-center">
                <h5 class="m-0 text-danger"> {{constant.ARE_YOU_SURE}} delete this item?</h5>
            </div>
            <div class="modal-footer p-0 border-0">
                <div class="col-6 m-0 p-0">
                    <button type="button" class="btn border btn-lg btn-block" data-dismiss="modal"
                        [title]="constant.CLOSE_TOOLTIP">
                        {{constant.CLOSE_TOOLTIP}}
                    </button>
                </div>
                <div class="col-6 m-0 p-0">
                    <button type="button" class="btn btn-primary btn-lg btn-block" data-dismiss="modal"
                        (click)="deleteCartItemV2()" [title]="constant.DELETE_TOOLTIP">
                        {{constant.DELETE_TOOLTIP}}
                    </button>
                </div>
            </div>
        </div>
    </div>
</div>

<!-- clear cart item modal -->
<div class="modal fade" id="clearCartItemModel" tabindex="-1" role="dialog" aria-labelledby="clearCartItemModalLabel"
    aria-hidden="true">
    <div class="modal-dialog modal-dialog-centered">
        <div class="modal-content">
            <div class="modal-header">
                <h5 class="modal-title">Clear Cart</h5>
                <button type="button" class="close" data-dismiss="modal" aria-label="Close"
                    [title]="constant.CLOSE_TOOLTIP">
                    <span aria-hidden="true">&times;</span>
                </button>
            </div>
            <div class="modal-body text-center">
                <h5 class="m-0 text-danger">{{constant.ARE_YOU_SURE_CLEAR_CART}}</h5>
            </div>
            <div class="modal-footer p-0 border-0">
                <div class="col-6 m-0 p-0">
                    <button type="button" class="btn border btn-lg btn-block" data-dismiss="modal"
                        [title]="constant.CLOSE_TOOLTIP">
                        {{constant.CLOSE_TOOLTIP}}
                    </button>
                </div>
                <div class="col-6 m-0 p-0">
                    <button type="button" class="btn btn-primary btn-lg btn-block" data-dismiss="modal"
                        (click)="clearNormalCart()" [title]="constant.CLEAR">
                        {{constant.CLEAR}}
                    </button>
                </div>
            </div>
        </div>
    </div>
</div>


<!-- Group Cart -->

<ng-template #groupOrderCartTemplate>
    <div class="osahan-cart-item rounded rounded shadow-sm overflow-hidden scroll bg-white sticky_sidebar">
        <div class="row p-3">
            <div class="col-sm-7 box203  w-100">
                <h5 class="text-white p-2 box204 mb-0 font-weight-bold text-center w-100">Group Order Cart</h5>
            </div>
            <div class="col-sm-5 text-center bg-warning">
                 <a class="btn d-block ml-auto text-dark" [title]="'Swtich' + constant.SWITCH_TO_INDIVIDUAL_ORDER_MODE" (click)="switchToOtherCart('normal')">
                     <div class="icon">
                         <i class="fas feather-repeat"></i><br/>
                         <small><b>Switch</b>{{ constant.SWITCH_TO_INDIVIDUAL_ORDER_MODE }}</small>
                     </div>
                 </a>
            </div>
        </div>

        <div class="border-bottom osahan-cart-item-profile bg-white p-2">
            <h6 class="mb-1 font-weight-bold">
                <span class="text-primary" style="cursor: pointer;" (click)="goToDetailsPage(groupCartList?.store_details)">{{ groupCartList?.store_details?.name }}</span>
                <span style="float:right;">
                    <!-- <a class="view-cart" *ngIf="groupCartList?.customer_id == loggedInUserId"
                        [title]="constant.VIEW_DETAILS" (click)="openViewGroupOrderModal()">
                        {{constant.VIEW_DETAILS}}
                    </a> -->
                    <a class="view-cart" *ngIf="groupCartList?.customer_id !== loggedInUserId"
                        (click)="leaveGroupOrder()" [title]="constant.LEAVE_GROUP_ORDER">
                        {{ constant.LEAVE_GROUP_ORDER }}
                    </a>
                </span>
            </h6>
            <p>
                <!-- <b>Order Type: {{ groupCartList?.group_order_type }}, {{getGroupOrderTime(groupCartList?.created)}}</b> -->
                <b>Order Type: {{ groupCartList?.group_order_type }}</b>
                <ng-container *ngIf="groupCartList?.customer_id == loggedInUserId">
                    <br><small>Contact restaurant or submit order to get an ETA.</small>
                </ng-container>
            </p>
        </div>

        <div class="bg-white border-bottom py-2 group-cart-height" *ngIf="groupCartList?.carts?.length">
            <div class="orderTitle u-block">
                <h6 class="pl-2">Your Items</h6>
            </div>

            <!-- for Loggedin User -->

            <ng-container *ngFor="let groupCart of groupCartList?.carts; let i = index">
                <ng-container *ngIf="groupCart.customer_id == loggedInUserId">
                    <ng-container *ngIf="groupCart?.items?.length; else noItemTemplate">
                        <ng-container class="bg-white" *ngFor="let gItem of groupCart?.items">
                            <div class="bg_ex" *ngIf="!gItem?.is_active || (gItem?.in_stock == false || gItem?.snoozed == true )">
                                <div class="container">
                                    <div class="row">
                                        <div class="col-lg-12 text-center">
                                            <h6 class="mb-0 text-white fs-10">{{ constant.INACTIVE_ITEM_REMOVE }}</h6>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div class="row border-bottom p-1 pt-3 pb-3" [ngClass]="gItem.is_active && (gItem.in_stock == true || gItem?.snoozed == false) ? '' : 'd_inactive card-width'">
                                <div class="col-md-6 col-12">
                                    <div class="media align-items-center">
            
                                        <!-- <div class="mr-2" [ngClass]="gItem.is_veg ? 'text-success' : 'text-danger'">
                                            &middot;
                                        </div> -->
            
                                        <div class="media-body">
                                            <p class="m-0">
                                                <a class="text-info" (click)="openItemAddUpdateModalForGroup(gItem, groupCart)" title="{{ gItem.name }}">
                                                    {{ gItem.name }}
                                                </a>
                                            </p>
            
                                            <!-- if variant and topping are in product -->
                                            <ng-container *ngIf="gItem.toppings?.length && gItem.variants?.length">
                                                <p class="text-gray newalign small">
                                                    Toppings: {{getOrderVariantToppingDetail(gItem,'topping')}}
                                                </p>
                                                <p class="text-gray newalign small">
                                                    {{getOrderVariantToppingDetail(gItem,'variant')}}
                                                </p>
                                            </ng-container>
            
                                            <!-- if only topping is in pritemuct -->
                                            <ng-container *ngIf="gItem.toppings?.length && !gItem.variants?.length">
                                                <p class="text-gray newalign small">
                                                    Toppings: {{getOrderVariantToppingDetail(gItem,'topping')}}
                                                </p>
                                            </ng-container>
            
                                            <!-- if only variant is in pritemuct -->
                                            <ng-container *ngIf="!gItem.toppings?.length && gItem.variants?.length">
                                                <p class="text-gray newalign small">
                                                    {{getOrderVariantToppingDetail(gItem,'variant')}}
                                                </p>
                                            </ng-container>

                                            <!-- If sold out option in the item -->
                                            <p class="text-gray newalign small" *ngIf="gItem?.sold_out_action">
                                                Sold out option: {{ gItem?.sold_out_action }}
                                            </p>
                                        </div>
            
                                    </div>
                                </div>
            
                                <div class="col-md-1 col-2">
                                    <span>
                                        <button class="btn" data-toggle="modal" data-target="#deleteGroupCartItemModel"
                                            (click)="confirmDeleteGroupCartItem(groupCart.id, gItem._id)"
                                            [disabled]="checkFriendSubmitOrder()"
                                            [title]="checkFriendSubmitOrder() === true ? constant.ALREADY_SUBMITTED_ORDER : constant.DELETE_TOOLTIP">
                                            <i class="fa fa-trash text-danger a-cursor"></i>
                                        </button>
                                    </span>
                                </div>
            
                                <div class="col-md-3 col-7">
                                    <div class="d-flex align-items-center" >
                                        <span class="count-number float-right">
                                            <button type="button" class="btn-sm left dec btn btn-outline-secondary"
                                                (click)="onClickAddRemoveGroupCartItem(groupCart.id, gItem._id, 'remove')"
                                                [disabled]="cartAddRemoveLoading || (gItem.quantity === 1) || groupCartList?.store_details?.store_status !== 'OPEN' || checkFriendSubmitOrder()"
                                                [title]="groupCartList?.store_details?.store_status === 'CUT_OFF_TIME' ? constant.STOP_TO_TAKE_ORDER :
                                                            groupCartList?.store_details?.store_status === 'CLOSED' ? constant.RESTAURANT_CLOSED :
                                                        checkFriendSubmitOrder() === true ? constant.ALREADY_SUBMITTED_ORDER :
                                                        (gItem.quantity === 1) ? '' : constant.DECREASE_QTY">
                                                <i class="feather-minus"></i>
                                            </button>
                                            <input class="count-number-input" type="text" readonly=""
                                                value="{{gItem.quantity}}">
                                            <button type="button" class="btn-sm right inc btn btn-outline-secondary"
                                                (click)="onClickAddRemoveGroupCartItem(groupCart.id, gItem._id, 'add')"
                                                [disabled]="cartAddRemoveLoading || groupCartList?.store_details?.store_status != 'OPEN' || checkFriendSubmitOrder() || gItem?.quantity >= 25"
                                                [title]="groupCartList?.store_details?.store_status == 'CUT_OFF_TIME' ? constant.STOP_TO_TAKE_ORDER :
                                                         groupCartList?.store_details?.store_status == 'CLOSED' ? constant.RESTAURANT_CLOSED :
                                                         checkFriendSubmitOrder() == true ? constant.ALREADY_SUBMITTED_ORDER :
                                                         gItem?.quantity >= 25 ? constant.QTY_MAX_LIMIT :constant.INCREASE_QTY">
                                                <i class="feather-plus"></i>
                                            </button>
                                        </span>
            
                                    </div>
                                </div>
            
                                <div class="col-md-2 col-3">
                                    <div class="d-flex align-items-center">
                                        <p class="text-gray mb-0 float-right ml-2 text-muted small">
                                            {{gItem.total_price | currency:'USD'}}
                                        </p>
                                    </div>
                                </div>
                            </div>
                        </ng-container>
                    </ng-container>
        
                    <ng-template #noItemTemplate>
                        You haven't added any items yet.
                    </ng-template>
                </ng-container>
            </ng-container>

            <ng-container *ngIf="groupCartList.customer_id == loggedInUserId">

                <div class="orderTitle u-block col-md-12 mt-2">
                    <h6 class="pl-2">
                        <b>{{ groupCartList?.carts?.length - 1 }} {{ (groupCartList?.carts?.length - 1) < 2 ? 'Guest' : 'Guests' }}</b>
                    </h6>
                </div>

                <ng-conatiner *ngFor="let groupCart of groupCartList?.carts; let i = index">
                    <ng-container *ngIf="groupCart.customer_id != loggedInUserId">
                        <div id="accordion" class="col-md-12">
                            <div class="p-3 bg-light border-bottom" id="headingTag{{i}}" data-toggle="collapse"
                                attr.data-target="#collapseTag{{i}}" aria-expanded="true" aria-controls="collapseTag{{i}}">
    
                                <h6 class="m-0">
                                    {{groupCart.customer_name}}
                                    {{groupCartList?.customer_id == groupCart.customer_id ? '(Host)' : ''}}
    
                                    <span [ngClass]="!groupCart.submission_time ? 'text-primary' : 'text-success'"
                                        *ngIf="loggedInUserId == groupCartList?.customer_id"> -
                                        {{!groupCart.submission_time ? 'Selection In Progress' : 'Ready For Checkout'}}
                                    </span>
    
                                    <span data-toggle="modal" data-target="#deleteGroupCartFriendModel" (click)="confirmDeleteGroupCartFriend(groupCart)"
                                        *ngIf="loggedInUserId == groupCartList?.customer_id"
                                        [title]="constant.DELETE_TOOLTIP">
                                        &nbsp;<i class="fa fa-trash text-danger a-cursor"></i>
                                    </span>
                                    <i class="feather-chevron-down float-right"></i>
                                </h6>
                            </div>
    
                            <div id="collapseTag{{i}}" class="collapse show" aria-labelledby="headingTag{{i}}" data-parent="#accordion">
                                <ng-container *ngIf="groupCart?.items?.length; else noItemTemplate">
                                    <ng-container *ngFor="let gItem of groupCart?.items">
                                        <div class="bg_ex" *ngIf="!gItem?.is_active || (gItem?.in_stock == false || gItem?.snoozed == true)">
                                            <div class="container">
                                                <div class="row">
                                                    <div class="col-lg-12 text-center">
                                                        <h6 class="mb-0 text-white fs-10">{{ constant.INACTIVE_ITEM_REMOVE }}</h6>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                        <div class="row p-1 border-bottom pt-3 pb-3" [ngClass]="gItem.is_active && (gItem.in_stock == true || gItem?.snoozed == false) ? '' : 'd_inactive card-width'">
    
                                            <div class="col-md-6 col-12">
                                                <div class="media align-items-center">
    
                                                    <!-- <div class="mr-2"
                                                        [ngClass]="gItem.is_veg ? 'text-success' : 'text-danger'">
                                                        &middot;
                                                    </div> -->
    
                                                    <div class="media-body">
                                                        <p class="m-0">{{gItem.name | titlecase }}</p>
    
                                                        <!-- if variant and topping are in product -->
                                                        <ng-container
                                                            *ngIf="gItem.toppings?.length && gItem.variants?.length">
                                                            <p class="text-gray newalign small">
                                                                Toppings: {{getOrderVariantToppingDetail(gItem,'topping')}}
                                                            </p>
                                                            <p class="text-gray newalign small">
                                                                {{getOrderVariantToppingDetail(gItem,'variant')}}
                                                            </p>
                                                        </ng-container>
    
                                                        <!-- if only topping is in product -->
                                                        <ng-container
                                                            *ngIf="gItem.toppings?.length && !gItem.variants?.length">
                                                            <p class="text-gray newalign small">
                                                                Toppings: {{getOrderVariantToppingDetail(gItem,'topping')}}
                                                            </p>
                                                        </ng-container>
    
                                                        <!-- if only variant is in product -->
                                                        <ng-container *ngIf="!gItem.toppings?.length && gItem.variants?.length">
                                                            <p class="text-gray newalign small">
                                                                {{getOrderVariantToppingDetail(gItem,'variant')}}
                                                            </p>
                                                        </ng-container>

                                                        <!-- If sold out option in the item -->
                                                        <p class="text-gray newalign small" *ngIf="gItem?.sold_out_action">
                                                            Sold out option: {{ gItem?.sold_out_action }}
                                                        </p>
                                                        
                                                    </div>
    
                                                </div>
                                            </div>
    
                                            <div class="col-md-1 col-2">
                                                <span data-toggle="modal" data-target="#deleteGroupCartItemModel" (click)="confirmDeleteGroupCartItem(groupCart.id, gItem._id)"
                                                    *ngIf="loggedInUserId == groupCartList?.customer_id" [title]="constant.DELETE_TOOLTIP">
                                                    <i class="fa fa-trash text-danger a-cursor"></i>
                                                </span>
                                            </div>
    
                                            <div class="col-md-3 col-7 ">
                                                <div class="d-flex align-items-center" *ngIf="gItem.is_active && (gItem?.in_stock == true || gItem?.snoozed == false)">
                                                    <span class="count-number float-right"
                                                        *ngIf="loggedInUserId == groupCartList?.customer_id">
                                                        <button type="button"
                                                            class="btn-sm left dec btn btn-outline-secondary"
                                                            (click)="onClickAddRemoveGroupCartItem(groupCart.id, gItem._id, 'remove')"
                                                            [disabled]="cartAddRemoveLoading || (gItem.quantity == 1) || groupCartList?.store_details?.store_status != 'OPEN'"
                                                            [title]="groupCartList?.store_details?.store_status == 'CUT_OFF_TIME' ? constant.STOP_TO_TAKE_ORDER :
                                                                    groupCartList?.store_details?.store_status == 'CLOSED' ? constant.RESTAURANT_CLOSED :
                                                                    gItem.quantity == 1 ? '' : constant.DECREASE_QTY">
                                                            <i class="feather-minus"></i>
                                                        </button>
                                                        <input class="count-number-input" type="text" readonly=""
                                                            value="{{gItem.quantity}}">
                                                        <button type="button"
                                                            class="btn-sm right inc btn btn-outline-secondary"
                                                            (click)="onClickAddRemoveGroupCartItem(groupCart.id, gItem._id, 'add')"
                                                            [disabled]="cartAddRemoveLoading || groupCartList?.store_details?.store_status != 'OPEN' || gItem?.quantity >= 25"
                                                            [title]="groupCartList?.store_details?.store_status == 'CUT_OFF_TIME' ? constant.STOP_TO_TAKE_ORDER :
                                                                     groupCartList?.store_details?.store_status == 'CLOSED' ? constant.RESTAURANT_CLOSED :
                                                                    gItem?.quantity >= 25 ? constant.QTY_MAX_LIMIT : constant.INCREASE_QTY">
                                                            <i class="feather-plus"></i>
                                                        </button>
                                                    </span>
                                                </div>
                                            </div>
    
                                            <div class="col-md-2 col-3">
                                                <div class="d-flex align-items-center">
                                                    <p class="text-gray mb-0 float-right ml-2 text-muted small">
                                                        {{gItem.total_price | currency:'USD'}}
                                                    </p>
                                                </div>
                                            </div>
    
                                        </div>
                                        <!-- <hr> -->
                                    </ng-container>
                                </ng-container>
    
                                <ng-template #noItemTemplate>
                                    No Item
                                </ng-template>
                            </div>
                        </div>
                    </ng-container>
                </ng-conatiner>

            </ng-container>

        </div>

        <div class="bg-white p-3 clearfix border-bottom">
            <p class="mb-1">
                Subtotal
                <span class="float-right text-dark">
                    {{ getGuestSubtotal() != null ? (getGuestSubtotal() | currency:'USD') : (groupCartList?.sub_total | currency:'USD') }}
                </span>
            </p>

            <p class="mb-1 text-success" *ngIf="groupCartList?.discount?.amount > 0 && (groupCartList.customer_id == loggedInUserId)">
                Promo ({{groupCartList?.discount?.code}}) &nbsp;
                <i class="fa fa-trash text-danger a-cursor" [title]="constant.DELETE_TOOLTIP"
                    (click)="removeDiscountOnCartItem()" *ngIf="loggedInUserId == groupCartList?.customer_id"></i>
                <span class="float-right text-success">
                    - {{groupCartList?.discount?.amount | currency: 'USD'}}
                </span>
            </p>
        </div>

        <div class="p-3" *ngIf="!isInCartPage"> 
            <button class="btn btn-success btn-block btn-lg" [routerLink]="['/checkout/group']"
                routerLinkActive="router-link-active" [title]="constant.CHECKOUT">
                {{constant.CHECKOUT}}<i class="feather-arrow-right"></i>
            </button>
        </div>

    </div>
</ng-template>

<!-- Delete Group Item  -->
<div class="modal fade" id="deleteGroupCartItemModel" tabindex="-1" role="dialog"
    aria-labelledby="deleteCartItemModalLabel" aria-hidden="true">
    <div class="modal-dialog modal-dialog-centered">
        <div class="modal-content">
            <div class="modal-header">
                <h5 class="modal-title">Delete Item</h5>
                <button type="button" class="close" data-dismiss="modal" aria-label="Close"
                    [title]="constant.CLOSE_TOOLTIP">
                    <span aria-hidden="true">&times;</span>
                </button>
            </div>
            <div class="modal-body text-center">
                <h5 class="m-0 text-danger">{{constant.ARE_YOU_SURE}} delete this item?</h5>
            </div>
            <div class="modal-footer p-0 border-0">
                <div class="col-6 m-0 p-0">
                    <button type="button" class="btn border btn-lg btn-block" data-dismiss="modal"
                        [title]="constant.CLOSE_TOOLTIP">
                        {{constant.CLOSE_TOOLTIP}}
                    </button>
                </div>
                <div class="col-6 m-0 p-0">
                    <button type="button" class="btn btn-primary btn-lg btn-block" data-dismiss="modal"
                        [title]="constant.DELETE_TOOLTIP" (click)="deleteGroupCartItem()">
                        {{constant.DELETE_TOOLTIP}}
                    </button>
                </div>
            </div>
        </div>
    </div>
</div>

<!-- Delete Friend -->
<div class="modal fade" id="deleteGroupCartFriendModel" tabindex="-1" role="dialog"
    aria-labelledby="deleteCartFriendModalLabel" aria-hidden="true">
    <div class="modal-dialog modal-dialog-centered">
        <div class="modal-content">
            <div class="modal-header">
                <h5 class="modal-title">Remove Guest</h5>
                <button type="button" class="close" data-dismiss="modal" aria-label="Close"
                    [title]="constant.CLOSE_TOOLTIP">
                    <span aria-hidden="true">&times;</span>
                </button>
            </div>
            <div class="modal-body text-center">
                <h5 class="m-0 text-danger">{{constant.ARE_YOU_SURE}} remove guest?</h5>
            </div>
            <div class="modal-footer p-0 border-0">
                <div class="col-6 m-0 p-0">
                    <button type="button" class="btn border btn-lg btn-block" data-dismiss="modal"
                        [title]="constant.CLOSE_TOOLTIP">
                        {{constant.CLOSE_TOOLTIP}}
                    </button>
                </div>
                <div class="col-6 m-0 p-0">
                    <button type="button" class="btn btn-primary btn-lg btn-block" data-dismiss="modal"
                        (click)="deleteGroupCartFriend()" [title]="constant.REMOVE">
                        {{constant.REMOVE}}
                    </button>
                </div>
            </div>
        </div>
    </div>
</div>

<!-- End -->