
<div class="modal-dialog modal-dialog-centered">
    <div class="modal-content">
        <div class="modal-header">
            <h5 class="modal-title">Group Order at {{data?.store_details?.name}}</h5>
            <button type="button" class="close" (click)="close()">
                <span aria-hidden="true">&times;</span>
            </button>
        </div>
        <ng-container>
            <div class="modal-body modal-height">
                <h6>You are Hosting this Group Order. You will be responsible 
                    for completing the payment once everyone's items have
                    been added.</h6>
                <b>Details</b>
                <a href="javascript:void(0)" class="links-change" (click)="openCreateGroupOrderModal()"
                    [title]="data?.group_order_type == 'Delivery' && data.store_details?.fulfillment_types?.includes('Pickup') ? constant.GROUP_ORDER.CHANGE_TO_PICKUP :
                             data?.group_order_type == 'Pickup' && data.store_details?.fulfillment_types?.includes('Delivery') ? constant.GROUP_ORDER.CHANGE_TO_DELIVERY : 'Update Details'">
                    {{ data?.group_order_type == 'Delivery' && data.store_details?.fulfillment_types?.includes('Pickup') ? constant.GROUP_ORDER.CHANGE_TO_PICKUP :
                       data?.group_order_type == 'Pickup' && data.store_details?.fulfillment_types?.includes('Delivery') ? constant.GROUP_ORDER.CHANGE_TO_DELIVERY : 'Update Details' }}
                </a><br>
                <ng-container *ngIf="data?.group_order_type === 'Delivery'">
                    <p class="remove-gap mb-0">Order Type: Delivery</p>
                    <p class="remove-gap mb-0">
                        Delivery Address:
                        <a class="text-primary" (click)="redirectToMap(data?.delivery_address)">
                            {{data?.delivery_address?.street}},
                            <span *ngIf="data?.delivery_address?.address_line_2">{{ data?.delivery_address?.address_line_2 }}, </span>
                            {{data?.delivery_address?.city}},
                            {{data?.delivery_address?.state}}
                            {{data?.delivery_address?.zipcode}}
                        </a>
                    </p>
                    <!-- <p class="remove-gap mb-0">{{getGroupOrderTime(data?.created)}}</p> -->
                </ng-container>

                <ng-container *ngIf="data?.group_order_type === 'Pickup'">
                    <p class="remove-gap mb-0">Order Type: Pickup</p>
                    <p class="remove-gap mb-0">
                        Restaurant Address:
                        <a class="text-primary" (click)="redirectToMap(data?.store_details?.address)">
                            {{data?.store_details?.address?.street}},
                            <span *ngIf="data?.store_details?.address?.address_line_2">{{ data?.store_details?.address?.address_line_2 }}, </span>
                            {{data?.store_details?.address?.city}},
                            {{data?.store_details?.address?.state}}
                            {{data?.store_details?.address?.zipcode}}
                        </a>
                    </p>
                    <!-- <p class="mb-0">{{getGroupOrderTime(data?.created)}}</p> -->
                </ng-container>

                <br>
                <b>You + {{ data?.carts?.length == 1 ? 'No Guests (Send invites!)' : (data?.carts?.length == 2) ? '1 Guest' : (data?.carts?.length - 1) + ' Guests' }}</b>
                <a class="links-change" (click)="copyLink()" *ngIf="!isCopied" [title]="constant.URL_COPY_LINK">{{constant.URL_COPY_LINK}}</a>
                <a class="links-change" *ngIf="isCopied">Link Copied!</a>
                <a class="links-change-group" (click)="openSharePopUpModal()" [title]="constant.SHARE_INVITE_LINK">{{constant.SHARE_INVITE_LINK }}</a>&nbsp;
                <p>Invite up to 20 guests. Your guests appear here once they join the group order.</p>
                <div class="u-flex u-flex-wrap">
                    <ng-container *ngFor="let friend of data?.carts; let f = index">
                        <span class="groupOrder-friends" title="You" *ngIf="friend.group_admin">You</span>
                        <span class="groupOrder-friends2" *ngIf="!friend.group_admin">
                            <a id="popover{{f}}" data-toggle="popover" data-html='true' [attr.data-content]="friend.customer_name">
                                {{ getFriendAvatar(friend.customer_name) }}
                            </a>
                        </span>
                    </ng-container>
                </div>

            <hr>
            <a class="link-but text-danger" data-toggle="modal" data-target="#cancelGroupOrderModel" [title]="constant.CANCEL_GROUP_ORDER">
                {{constant.CANCEL_GROUP_ORDER}}
            </a><br />
            <hr>
        </div>
            <div class="modal-footer p-0 border-0">
                <div class="col-12 m-0 p-0">
                    <button type="button" class="btn btn-primary btn-lg btn-block" (click)="close()" [title]="constant.CLOSE_TOOLTIP">
                        {{constant.CLOSE_TOOLTIP}}
                    </button>
                </div>
            </div>
        </ng-container>
    </div>
</div>

<!-- Cancel Group Order modal -->
<div class="modal fade" id="cancelGroupOrderModel" tabindex="-1" role="dialog" aria-labelledby="exampleModalLabel"
    aria-hidden="true">
    <div class="modal-dialog modal-dialog-centered">
        <div class="modal-content">
            <div class="modal-header">
                <h5 class="modal-title">Cancel Group Order</h5>
                <button type="button" class="close" data-dismiss="modal" aria-label="Close" [title]="constant.CLOSE_TOOLTIP">
                    <span aria-hidden="true">&times;</span>
                </button>
            </div>
            <div class="modal-body text-center">
                <h5 class="m-0 text-danger"> {{constant.ARE_YOU_SURE}} cancel group order?</h5>
            </div>
            <div class="modal-footer p-0 border-0">
                <div class="col-6 m-0 p-0">
                    <button type="button" class="btn border btn-lg btn-block" data-dismiss="modal" [title]="constant.DO_NOT_CANCEL">
                        {{constant.DO_NOT_CANCEL}}
                    </button>
                </div>
                <div class="col-6 m-0 p-0">
                    <button type="button"  class="btn btn-primary btn-lg btn-block" data-dismiss="modal" (click)="onClickcancelGroupOrder()" [title]="constant.YES_CANCEL">
                        {{constant.YES_CANCEL}}
                    </button>
                </div>
            </div>
        </div>
    </div>
</div>
