
<div class="modal-dialog modal-dialog-centered" *ngIf="isLoggedIn">
    <div class="modal-content">
        <div class="modal-header">
            <button type="button" class="back  mr-2" (click)="close()" [title]="constant.BACK" *ngIf="mode != 'add'">
                <i class="feather-arrow-left"></i>
            </button>
            <h5 class="modal-title"> {{ mode == 'add' ? 'Create' : 'Update' }} Group Order</h5>
            <button type="button" class="close" (click)="close()" [title]="constant.CLOSE_TOOLTIP">
                <span aria-hidden="true">&times;</span>
            </button>
        </div>

        <div class="modal-body modal-height">
            <h6>Invite up to 20 people. You can then checkout and pay once everyone has finished adding their items.</h6>
            <p class="pb-1">(You as the Host will be responsible for paying for the entire order. If any reimbursements are necessary from others, you can arrange that separately between each other.)</p>
            <ul class="nav nav-tabs" id="myTab" role="tablist">
                <li class="nav-item" role="presentation" *ngIf="data?.fulfillment_types?.includes('Delivery')">
                    <a class="px-0 py-3 nav-link text-dark h6 border-0 mb-0" [ngClass]="activeTab == 'Delivery' ? 'active' : ''" id="delivery-tab" data-toggle="tab" href="#delivery" role="tab" aria-controls="profile" aria-selected="false" (click)="changeTab('Delivery')">
                        Delivery
                    </a>
                </li>
                <li class="nav-item bottom-tab" role="presentation" *ngIf="data?.fulfillment_types?.includes('Pickup')">
                    <a class="px-0 py-3 nav-link text-dark h6 border-0 mb-0 ml-3" [ngClass]="activeTab == 'Pickup' ? 'active' : ''" id="pickup-tab" data-toggle="tab" href="#pickup" role="tab" aria-controls="profile" aria-selected="true" (click)="changeTab('Pickup')">
                        Pick Up
                    </a>
                </li>
            </ul>
            <div class="container">
                <div class="tab-content" id="myTabContent">
                    <div class="tab-pane fade py-4" [ngClass]="activeTab == 'Delivery' ? 'active show' : ''" id="delivery" role="tabpanel" aria-labelledby="delivery-tab" *ngIf="data?.fulfillment_types?.includes('Delivery')">
                        <div class="container">
                            <div class="col-12 ">
                                <select class="custom-select form-control" [(ngModel)]="selectedAddress" (change)="onChangerAddress($event)">
                                    <option value="option">-- Select Address --</option>
                                    <option *ngFor="let address of addressList" [value]="address.id">
                                        {{address.label}} - {{address.street}}, <span *ngIf="address.address_line_2">{{ address.address_line_2 }}, </span>{{address.city}}, {{address.state}} {{address.zipcode}}, {{address.country}}
                                    </option>
                                    <option value="add">+ Add New</option>
                                </select>
                            </div>
                        </div>
                    </div>
            
                    <div class="tab-pane fade py-4" [ngClass]="activeTab == 'Pickup' ? 'active show' : ''" id="pickup" role="tabpanel" aria-labelledby="pickup-tab" *ngIf="data?.fulfillment_types?.includes('Pickup')">
                        <h6 style="text-align: center;">Pick up your order</h6>   
                    </div>
                </div>
            </div>
            <h6>Guest Notes</h6>
            <small>(Use this section to inform your guests of important information about the group order.)</small>
            <textarea class="form-control" [placeholder]="constant.GROUP_ORDER.OWNER_NOTE" maxlength="500" [(ngModel)]="hostNote"></textarea>
        </div>

        <div class="modal-footer p-0 border-0">
            <div class="col-12 m-0 p-0">
                <button type="button" class="btn btn-primary btn-lg btn-block" (click)="createOrUpdateGroupOrder()" [disabled]="((!selectedAddress || isInRadius) && activeTab === 'Delivery') || !isResturantActive" 
                    [title]="(!selectedAddress && activeTab === 'Delivery') ? constant.SELECT_ADDRESS :
                                (isInRadius && activeTab === 'Delivery') ? constant.CHECKOUT_DELIVERING_CURRENTLY :
                                mode === 'add' ? constant.GROUP_ORDER.START_NEW_ORDER : constant.UPDATE_TOOLTIP">
                    {{mode === 'add' ? constant.GROUP_ORDER.START_NEW_ORDER : 'Update'}}
                </button>
            </div>
        </div>
    </div>
</div>

<!-- cart item delete modal -->
<div class="modal fade" id="replaceOrderModelCreateOrder" tabindex="-1" role="dialog">
    <div class="modal-dialog modal-dialog-centered">
        <div class="modal-content">
            <div class="modal-header">
                <h5 class="modal-title">Start a new group order?</h5>
                <button [disabled]="!isResturantActive" type="button" class="close" data-dismiss="modal" aria-label="Close" (click)="close()">
                    <span aria-hidden="true">&times;</span>
                </button>
            </div>
            <div class="modal-body text-center">
                <h5 class="m-0 text-danger"> This will replace items from
                    <a class="storename-hover" (click)="getRedirectStoreDetailsLink()">{{data?.name}}</a>
                </h5>
            </div>
            <div class="modal-footer p-0 border-0">
                <div class="col-6 m-0 p-0">
                    <button type="button" class="btn border btn-lg btn-block" (click)="getRedirectStoreDetailsLink()">No</button>
                </div>
                <div class="col-6 m-0 p-0">
                    <button type="button" id="replaceFlag" class="btn btn-primary btn-lg btn-block" data-dismiss="modal">Yes</button>
                </div>
            </div>
        </div>
    </div>
</div>
<div id="gMap" #gMap></div>