import { Component, OnInit } from '@angular/core';
import { ConstantProviderService } from 'src/app/providers/constant-provider.service';

@Component({
  selector: 'app-notfound',
  templateUrl: './notfound.component.html',
  styleUrls: ['./notfound.component.css']
})

export class NotfoundComponent implements OnInit {

  constructor(
    public constant: ConstantProviderService,
  ) { }

  ngOnInit(): void {
  }

}
