
<div class="modal-dialog modal-dialog-centered">
    <div class="modal-content">
        <div class="modal-header">
            <h5 class="modal-title">Delivery Address</h5>
            <button type="button" class="close" (click)="close()" aria-label="Close" [title]="constant.CLOSE_TOOLTIP">
                <span aria-hidden="true">&times;</span>
            </button>
        </div>
        <div class="modal-body modal-height">
            <form [formGroup]="addressForm"  autocomplete="off">
                <div class="form-row">
                    <div class="col-md-12 form-group">
                        <label class="form-label">Address Label<span class="text-danger">*</span></label>
                        <input placeholder="i.e. Home" type="text" class="form-control" [(ngModel)]="addressObject.label" formControlName="label">
                        <div class="invalid-feedback" *ngIf="label?.invalid && (label?.touched)" class="text-danger">
                            <div *ngIf="label?.errors?.required">* {{constant.ADDRESS_LABEL}}</div>
                        </div>
                    </div>
                    <!-- <div class="col-md-6 form-group">
                        <label class="form-label">First Name</label>
                        <input placeholder="First Name" type="text" class="form-control" [(ngModel)]="addressObject.firstname" formControlName="firstname">
                    </div>

                    <div class="col-md-6 form-group">
                        <label class="form-label">Last Name</label>
                        <input placeholder="Last Name" type="text" class="form-control" [(ngModel)]="addressObject.lastname" formControlName="lastname">
                    </div> -->
                    <div class="col-md-12 form-group">
                        <label class="form-label">Address Line 1<span class="text-danger">*</span></label>
                        <div class="dropdown">
                            <div id="addressAutoComplete" aria-haspopup="true" aria-expanded="false">
                                <input placeholder="Address Line 1" type="text" class="form-control" (ngModelChange)="getAddress($event)" [(ngModel)]="addressObject.street" formControlName="street">
                            </div>
                            <div class="dropdown-menu options-width " aria-labelledby="addressAutoComplete" *ngIf="places?.length > 0">
                                <span class="row">
                                    <a *ngFor="let place of places" class=" dropdown-item border-bottom col-sm-12 add-option" (click)="selectLocation(place)">
                                        {{ place.description }}
                                    </a>
                                </span>
                            </div>
                        </div>
                        <div class="invalid-feedback" *ngIf="street?.invalid && (street?.touched)" class="text-danger">
                            <div *ngIf="street?.errors?.required">* {{constant.STREET_NAME}}</div>
                        </div>
                    </div>
                    <div class="col-md-12 form-group">
                        <label class="form-label">Address Line 2 (Suite, Unit, Etc)</label>
                        <input placeholder="Address Line 2" type="text" class="form-control" [(ngModel)]="addressObject.address_line_2" formControlName="address_line_2">
                    </div>
                    <div class="col-md-4 form-group">
                        <label class="form-label">City<span class="text-danger">*</span></label>
                        <input placeholder="City" type="text" class="form-control"  [(ngModel)]="addressObject.city" formControlName="city">
                    </div>
                    <div class="col-md-4 form-group">
                        <label class="form-label">State<span class="text-danger">*</span></label>
                        <input placeholder="State" type="text" class="form-control"  [(ngModel)]="addressObject.state" formControlName="state">
                    </div>
                    <div class="col-md-4 form-group">
                        <label class="form-label">Zipcode<span class="text-danger">*</span></label>
                        <input placeholder="Zipcode" type="text" class="form-control"  [(ngModel)]="addressObject.zipcode" formControlName="zipcode">
                    </div>

                    <!-- <div class="col-md-6 form-group">
                        <label class="form-label">Phone</label>
                        <input placeholder="Phone" type="text" class="form-control" mask="(000) 000-0000" [(ngModel)]="addressObject.mobile" formControlName="mobile"
                            minlength="10">
                        <div class="invalid-feedback" *ngIf="mobile?.invalid && (mobile?.touched)" class="text-danger">
                            <div *ngIf="mobile?.errors?.minlength">* {{constant.PHONE_REQUIRED_LENGTH}}</div>
                        </div>
                    </div> -->
                    <!-- <div class="col-md-6 form-group">
                        <label class="form-label">Email Address</label>
                        <input placeholder="Email Address" type="text" class="form-control" [(ngModel)]="addressObject.email" formControlName="email"
                            [pattern]="constant.EMAIL_PATTERN">
                        <div class="invalid-feedback" *ngIf="email?.invalid && (email?.touched)" class="text-danger">
                            <div *ngIf="email?.errors?.pattern">* {{constant.EMAIL_REQUIRED_PATTERN}}</div>
                        </div>
                    </div> -->

                    <div class="col-md-12 form-group">
                        <label class="form-label">Access Instructions (optional)</label>
                        <textarea [placeholder]="constant.ADDRESS_SPECIAL_INSTRUCTION_TEXT" type="text" class="form-control" [(ngModel)]="addressObject.delivery_instruction" 
                            formControlName="delivery_instruction">
                        </textarea>
                    </div>

                    <!-- <div class="col-md-6 form-group">
                        <label class="form-label">Country <span class="text-danger">*</span></label>
                        <input placeholder="Country" type="text" class="form-control" readonly [(ngModel)]="addressObject.country" formControlName="country">
                    </div> -->
                    <!-- <div class="col-md-6 form-group">
                        <label class="form-label">Zipcode <span class="text-danger">*</span></label>
                        <input placeholder="Zipcode" type="text" class="form-control" readonly [(ngModel)]="addressObject.zipcode" formControlName="zipcode">
                    </div> -->

                    <div class="col-md-12 form-group">
                        <!-- <div class="custom-control custom-checkbox">
                            <input type="checkbox" id="custom-checkbox1" class="custom-control-input" [(ngModel)]="addressObject.default" formControlName="default">
                            <label for="custom-checkbox1 custom-control-label" class="pl-2">Default</label>
                        </div> -->
                        <div class="custom-control custom-checkbox" style="cursor: pointer;" >
                            <input type="checkbox" class="custom-control-input" id="custom-checkbox-address" [(ngModel)]="addressObject.default" formControlName="default">
                            <label class="custom-control-label" for="custom-checkbox-address" style="padding-top: 2px; cursor: pointer;">Set as my Default Delivery Address</label>
                         </div>
                    </div>

                    <div class="col-md-12 form-group" *ngIf="addressModeLabel == 'add' && (from.location == 'checkout' || from.location == 'create-group')">
                        <div class="custom-control custom-checkbox" style="cursor: pointer;" >
                            <input type="checkbox" class="custom-control-input" id="custom-future-checkbox-address" [(ngModel)]="addressObject.is_permanent" formControlName="is_permanent">
                            <label class="custom-control-label" for="custom-future-checkbox-address" style="padding-top: 2px; cursor: pointer;">Save Address for Future Orders</label>
                         </div>
                    </div>
          
                </div>
            </form>
        </div>
        <div class="modal-footer p-0 border-0">
            <div class="col-6 m-0 p-0">
                <button type="button" class="btn border btn-lg btn-block" (click)="close()" [title]="constant.CLOSE_TOOLTIP">
                    {{constant.CLOSE_TOOLTIP}}
                </button>
            </div>
            <div class="col-6 m-0 p-0">
                <button type="button" class="btn btn-primary btn-lg btn-block" (click)="addressChangesAddUpdate()" [title]="mode === 'edit' ? constant.UPDATE_ADDRESS : constant.ADD_ADDRESS">
                    {{mode === 'edit' ? constant.UPDATE_ADDRESS : constant.ADD_ADDRESS}}
                </button>
            </div>
        </div>
    </div>
</div>

<div id="gMap" #gMap></div>