import { Component, OnInit } from '@angular/core';
import { ShareStoreModel } from 'src/app/models/general';
import { SimpleModalComponent } from 'ngx-simple-modal';
import { Router } from '@angular/router';
import { HelperService } from 'src/app/services/helper.service';
import { environment } from 'src/environments/environment';
import { ToastrService } from 'ngx-toastr';
import { ConstantProviderService } from 'src/app/providers/constant-provider.service';

@Component({
  selector: 'app-share-store-pop-up',
  templateUrl: './share-store-pop-up.component.html',
  styleUrls: ['./share-store-pop-up.component.css']
})

export class ShareStorePopUpComponent extends SimpleModalComponent<ShareStoreModel, null> implements ShareStorePopUpComponent, OnInit {
  
  //#region variables

  public data: any;
  public type: any;
  public groupId: any;

  public groupOrderData: any;
  public emailTemplate: any = {};

  //#endregion

  //#region life cycle hook

  constructor(
    private helper: HelperService,
    private toastr: ToastrService,
    public constant: ConstantProviderService,
  ) {
    super();
  }

  ngOnInit(): void {
    this.groupOrderData = this.helper.getGroupCartList();
    this.groupId = this.groupOrderData.group_id;

    if (this.type == 'group') {
      this.emailTemplate.title = `Invitation: Join ${this.groupOrderData.group_host_first_name}’s Group Order at ${this.data.name}`;
      this.emailTemplate.description =
        `This is an invitation to join ${this.groupOrderData.group_host_name}’s order at ${this.data.name}. Be sure to get your order in before they place it!
To join the order, visit the link below:`
      this.emailTemplate.url = this.getRedirectStoreDetailsLink();
    
    } else {
      this.emailTemplate.title = 'Restaurant Link';
      this.emailTemplate.description = ``;
      this.emailTemplate.url = this.getRedirectStoreDetailsLink();
    }
  }

  //#endregion

  //#region public methods

  public getRedirectStoreDetailsLink(): any {
    const storeName = this.helper.convertToSlug(this.data.name);
    if (this.type === 'group') {
      return `${environment.WEBSITE_URL}/restaurant/${storeName}/${this.data._id}?group_id=${this.groupId}`;

    } else {
      return `${environment.WEBSITE_URL}/restaurant/${storeName}/${this.data._id}`;
    }
  }

  public copyLink() {
    this.toastr.success(this.constant.LINK_COPY ,this.constant.SUCCESS);
  }

  //#endregion
}
