import { NgModule, NO_ERRORS_SCHEMA, ErrorHandler } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';

import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { HeaderComponent } from './components/layouts/header/header.component';
import { FooterComponent } from './components/layouts/footer/footer.component';
import { NotfoundComponent } from './components/layouts/notfound/notfound.component';
import { environment } from 'src/environments/environment';
import { AngularFireModule } from '@angular/fire';
import { AngularFireMessagingModule } from '@angular/fire/messaging';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';

import { FCMMessagingService } from './services/fcm-messaging.service';

import { HttpClient } from '@angular/common/http';
import { HttpInterceptorModule } from './interceptors/http.interceptor.module';

import { SocialLoginModule, GoogleLoginProvider, FacebookLoginProvider, SocialAuthServiceConfig } from 'angularx-social-login';
import { AppleLoginProvider } from './providers/apple-login.provider';
import { BugsnagErrorHandler } from '@bugsnag/plugin-angular';
import Bugsnag from '@bugsnag/js';

import { TranslateModule, TranslateLoader } from '@ngx-translate/core';
import { TranslateHttpLoader } from '@ngx-translate/http-loader';
import { ToastrModule } from 'ngx-toastr';
import { SlickCarouselModule } from 'ngx-slick-carousel';
import { NgxMaskModule } from 'ngx-mask';
import { SimpleModalModule, defaultSimpleModalOptions, DefaultSimpleModalOptionConfig } from 'ngx-simple-modal';
import { OrderSuccessfulComponent } from './components/order-successful/order-successful.component';
import { OrderFailComponent } from './components/order-fail/order-fail.component';
import { AngularFirestoreModule } from '@angular/fire/firestore';
import { RECAPTCHA_V3_SITE_KEY } from 'ng-recaptcha';

export function translateHttpLoaderFactory(http: HttpClient) {
  return new TranslateHttpLoader(http, './assets/i18n/', '.json');
}

@NgModule({
  declarations: [
    AppComponent,
    HeaderComponent,
    FooterComponent,
    NotfoundComponent,
    OrderSuccessfulComponent,
    OrderFailComponent
  ],
  imports: [
    BrowserModule,
    AppRoutingModule,
    AngularFireModule.initializeApp(environment.firebaseConfig),
    AngularFireMessagingModule,
    AngularFirestoreModule,
    SocialLoginModule,
    TranslateModule.forRoot({
      loader: {
        provide: TranslateLoader,
        useFactory: translateHttpLoaderFactory,
        deps: [HttpClient]
      }
    }),
    HttpInterceptorModule,
    BrowserAnimationsModule,
    ToastrModule.forRoot({
      timeOut: 4000,
      preventDuplicates: true,
      maxOpened: 1,
      autoDismiss: true,
      positionClass: 'toast-top-right'
    }),
    FormsModule,
    ReactiveFormsModule,
    SlickCarouselModule,
    SimpleModalModule.forRoot({ container: 'modal-container' }, {...defaultSimpleModalOptions, ...{
      closeOnEscape: true,
      closeOnClickOutside: true,
    }}),
    NgxMaskModule.forRoot(),
  ],
  providers: [
    {
      provide: ErrorHandler,
      useFactory: errorHandlerFactory
    },
    FCMMessagingService,
    {
      provide: 'SocialAuthServiceConfig',
      useValue: {
        autoLogin: true,
        providers: [
          {
            id: GoogleLoginProvider.PROVIDER_ID,
            provider: new GoogleLoginProvider(environment.GOOGLE_CLINT_ID)
          },
          {
            id: FacebookLoginProvider.PROVIDER_ID,
            provider: new FacebookLoginProvider(environment.FB_CLINT_ID)
          },
          {
            id: AppleLoginProvider.PROVIDER_ID,
            provider: new AppleLoginProvider(environment.APPLE_CLINT_ID)
          }
        ]
      } as SocialAuthServiceConfig
    },
    {
      provide: DefaultSimpleModalOptionConfig,
      useValue: {...defaultSimpleModalOptions, ...{ closeOnEscape: true, closeOnClickOutside: true }}
    },
    { provide: RECAPTCHA_V3_SITE_KEY, useValue: environment.RECAPTCHA_SITE_KEY }
  ],
  schemas: [NO_ERRORS_SCHEMA],
  bootstrap: [AppComponent],
})
export class AppModule { }

export function errorHandlerFactory() {
  return new BugsnagErrorHandler();
}

Bugsnag.start({
  apiKey: '54ba42570d6025f0f27ca82abd487209',
  appVersion: '2.3.0',
  appType: 'website',
  releaseStage: 'staging',
});