// This file can be replaced during build by using the `fileReplacements` array.
// `ng build --prod` replaces `environment.ts` with `environment.prod.ts`.
// The list of file replacements can be found in `angular.json`.

export const environment = {
  // apiUrl: 'http://localhost:1408/',
  apiUrl: 'https://api.chatmyorder.com/',
  CMO_TICKET_API: 'https://support.chatmyorder.com/',
  CMO_RIDER_API: 'https://us-central1-cmo-riders-production.cloudfunctions.net/',
  CMO_FB_API: 'https://us-central1-chatmyorder.cloudfunctions.net/',
  WEBSITE_URL: 'https://chatmyorder.com',
  BUSINESS_PORTAL_URL: 'https://business.chatmyorder.com/',
  GOOGLE_OAUTH_CLIENT_ID: '157118548742-mneg4ntptnl6af6o58dgvfk866ok7uac.apps.googleusercontent.com',
  production: true,
  stripe: {
    pk: 'pk_live_51HEVLpHovF15i47CQuWiw6rhYq5bAestEoT18xLcioA79ulI7QncJmBSXVhJ9ouhQqaYyZ29w60xWu1CTWMgwFVD003chepQxk'
  },
  pubnub: {
    pk: 'pub-c-962429a0-4551-4e15-a631-55e21f8e0f60',
    sk: 'sub-c-911d4960-191f-11eb-bc34-ce6fd967af95'
  },
  pubnub_real_time: {
    sk: 'sub-c-fdb62a2a-2c2d-11ec-9e33-021bdbd01fcd'
  },
  firebaseConfig: {
    apiKey: 'AIzaSyA9cZUdxPO2KV3jSHHxEddCNe8Mu8s4NPM',
    authDomain: 'chatmyorder.firebaseapp.com',
    databaseURL: 'https://chatmyorder.firebaseio.com',
    projectId: 'chatmyorder',
    storageBucket: 'chatmyorder.appspot.com',
    messagingSenderId: '157118548742',
    appId: '1:157118548742:web:6c1b676a57b0530d9bac87',
    measurementId: 'G-P5WC0KDEZS',
  },
  GOOGLE_CLINT_ID: '157118548742-mneg4ntptnl6af6o58dgvfk866ok7uac.apps.googleusercontent.com',
  FB_CLINT_ID: '1289650318174187',
  APPLE_CLINT_ID: 'com.chatmyorder',
  APPLE_REDIRECT_URI: 'https://chatmyorder.com/redirect',
  INTERVAL_TIME_GET_STATUS_UPDATE : 2000,
  CMO_TERMS: 'https://blog.chatmyorder.com/terms/',
  CMO_POLICY: 'https://blog.chatmyorder.com/privacy-policy/',
  CMO_GUIDELINES:'https://blog.chatmyorder.com/guidelines-for-restaurants/',
  CMO_CODE_OF_CONDUCT:'https://blog.chatmyorder.com/code-of-conduct/',
  CMO_REFUND_AND_CANCELLATION_POLICY:'https://blog.chatmyorder.com/refund-policy/',
  CMO_FAQ: 'https://blog.chatmyorder.com/faq/',
  CMO_CONTACT_US: 'https://support.chatmyorder.com/en/Customer/create-ticket/',
  CMO_RIDER_LOGIN: 'https://cmodeliver.com/cmorider',
  MAINTENANCE_MODE_DOC: 'maintenanceModeDevCustomer',
  BROADCAST_CHANNEL: 'alert.cmo_broadcast_channel',
  CMO_BLOG_URL:'https://blog.chatmyorder.com',
  CMO_RESOURCE_LINK: 'https://cmodeliver.com',
  ENV: 'dev',
  RECAPTCHA_SITE_KEY: '6LdZbOIZAAAAAINT9fs-4JIXwWg-srNAP7ZCq5ZR'
};

/*
 * For easier debugging in development mode, you can import the following file
 * to ignore zone related error stack frames such as `zone.run`, `zoneDelegate.invokeTask`.
 *
 * This import should be commented out in production mode because it will have a negative impact
 * on performance if an error is thrown.
 */
// import 'zone.js/dist/zone-error';  // Included with Angular CLI.
