import { Component, OnInit, HostListener, ChangeDetectorRef } from '@angular/core';
import { Router } from '@angular/router';
import { CmoapiService } from 'src/app/services/cmoWebsiteApi.service';
import { environment } from 'src/environments/environment';
import { HelperService } from 'src/app/services/helper.service';
import { ToastrService } from 'ngx-toastr';
import { ConstantProviderService } from 'src/app/providers/constant-provider.service';
import { MessageService } from 'src/app/services/message.service';


@Component({
  selector: 'app-footer',
  templateUrl: './footer.component.html',
  styleUrls: ['./footer.component.css']
})
export class FooterComponent implements OnInit {

  //#region variables

  public contactList: any = [];

  public phoneNoLink: any;
  public emailAddLink: any;
  public loggedInUser: any;
  public currentYear: number;

  public businessSignInLink = environment.BUSINESS_PORTAL_URL;
  public businessSignUpLink = environment.BUSINESS_PORTAL_URL + 'auth/register';

  public cmoTermsLink = environment.CMO_TERMS;
  public cmoPolicyLink = environment.CMO_POLICY;
  public cmoFAQLink = environment.CMO_FAQ;
  public cmoGuidelines = environment.CMO_GUIDELINES;
  public cmoCodeOfConduct = environment.CMO_CODE_OF_CONDUCT;
  public cmoRefund_and_cancellation_policy = environment.CMO_REFUND_AND_CANCELLATION_POLICY;
  public cmoBlogLink = environment.CMO_BLOG_URL;
  public cmoHelpCenter = `${environment.CMO_BLOG_URL}/helpcenter/`;
  public cmoContactLink = environment.CMO_CONTACT_US;
  public cmoRiderLogin = environment.CMO_RIDER_LOGIN;
  public cmoBecomeRider = `${environment.CMO_RIDER_LOGIN}/#1#video-3`
  public cmoRiderResource = `${environment.CMO_RESOURCE_LINK}/cmorider/`;
  public cmoMerchantResource = `${environment.CMO_RESOURCE_LINK}/cmobusiness/`;

  public placeComponents = this.helperService.placeComponents;
  public autoCompleteElements = this.helperService.autoCompleteElements;
  public isScroll = true;
  public isLogin = false;

  public cartType = '';

  //#endregion

  //#region life cycle hook

  constructor(
    private router: Router,
    private helperService: HelperService,
    private cmoApiService: CmoapiService,
    private toastrService: ToastrService,
    private messageService: MessageService,
    private cdr: ChangeDetectorRef,
    public constant: ConstantProviderService,
  ) {
    if (window.location.pathname.includes('/group')) this.cartType = 'group';
    else this.cartType = 'normal';

    // subscribe subject events
    this.messageService.getMessage().subscribe((msg: any) => {
      if (msg == 'Footer-Profile-Pic') {
        window.location.reload()
      }
    })
  }

  ngOnInit(): void {

    this.currentYear = new Date().getFullYear();

    if (localStorage.getItem('chat-my-order:token')) this.isLogin = true;
    else this.isLogin = false;

    this.initializeSideBar();
    this.getCMOContactDetails();

    // initialize tooltips
    const tooltipEl: any = $('[data-toggle="tooltip"]');
    tooltipEl.tooltip();

    // initialize sliders
    const osEl: any = $('.osahan-slider');
    const osmEl: any = $('.osahan-slider-map');
    osEl.slick({
      centerMode: false,
      slidesToShow: 1,
      arrows: false,
      dots: true
    });

    // osahan-slider-map
    osmEl.slick({
      autoplay: true,
      slidesToShow: 5,
      arrows: true,
      responsive: [{
        breakpoint: 768,
        settings: {
          arrows: false,
          autoplay: true,
          centerMode: true,
          centerPadding: '40px',
          slidesToShow: 3
        }
      },
      {
        breakpoint: 480,
        settings: {
          arrows: false,
          autoplay: true,
          centerMode: true,
          centerPadding: '40px',
          slidesToShow: 3
        }
      }
      ]
    });
  }


  //#endregion

  //#region private mthods

  private initializeSideBar(): void {
    const mainNav: any = $('#main-nav');
    const toggle: any = $('.toggle');

    let liElm: HTMLLIElement = document.createElement('li');
    let linkElm: HTMLAnchorElement = document.createElement('a');
    let linkHeadElm: HTMLParagraphElement = document.createElement('p');
    let linkIconElm: HTMLElement = document.createElement('i');
    let linkTextElm: HTMLSpanElement = document.createElement('span');

    // linkHeadElm.setAttribute('class', 'h5 m-0');
    // const linkIconClass = (this.isLogin) ? 'feather-log-out text-danger' : 'feather-log-in';
    // linkIconElm.setAttribute('class', linkIconClass);
    // linkTextElm.textContent = (!this.isLogin) ? ' Sign In' : ' Sign Out';
    // const sidebarLinkClass = (this.isLogin) ? 'sidebar-link text-danger' : 'sidebar-link';
    // linkElm.setAttribute('class', sidebarLinkClass);
    // const routerLink = (!this.isLogin) ? '/login' : '/lo';
    // linkElm.setAttribute('data-router-link', routerLink);
    // linkHeadElm.appendChild(linkIconElm);
    // linkElm.appendChild(linkHeadElm);
    // linkElm.appendChild(linkTextElm);
    // liElm.appendChild(linkElm);

    linkHeadElm.setAttribute('class', 'h5 m-0');
    const linkIconClass = (this.isLogin) ? 'feather-log-out text-danger' : '';
    linkIconElm.setAttribute('class', linkIconClass);
    linkTextElm.textContent = (this.isLogin) ? ' Sign Out' : '' ;
    const sidebarLinkClass = (this.isLogin) ? 'sidebar-link text-danger' : '';
    linkElm.setAttribute('class', sidebarLinkClass);
    const routerLink = (this.isLogin) ? '/lo' : '';
    linkElm.setAttribute('data-router-link', routerLink);
    linkHeadElm.appendChild(linkIconElm);
    linkElm.appendChild(linkHeadElm);
    linkElm.appendChild(linkTextElm);
    liElm.appendChild(linkElm);
    if (this.isLogin) {
      $('#main-nav ul.bottom-nav').append(liElm);
    }

    if (this.isLogin) {
      for (let list of this.constant.SIDEBAR_OPTIONS) {
        let liElm1: HTMLLIElement = document.createElement('li');
        let linkElm1: HTMLAnchorElement = document.createElement('a');
        let linkIconElm1: HTMLElement = document.createElement('i');
        let linkTextElm1: HTMLSpanElement = document.createElement('span');

        linkElm1.setAttribute('class', 'sidebar-link');
        linkElm1.setAttribute('data-router-link', list.link);
        linkIconElm1.setAttribute('class', list.icon);
        linkTextElm1.textContent = list.text;

        linkElm1.appendChild(linkIconElm1);
        linkElm1.appendChild(linkTextElm1);
        liElm1.appendChild(linkElm1);

        if (window.innerWidth < 994 && list.in_responsive) {
          $('#main-nav ul.top-nav').append(liElm1);
        
        } else if (window.innerWidth > 993 && list.in_desktop) {
          $('#main-nav ul.top-nav').append(liElm1);
        }
      }
    }

    let titleHtmlCode: any;
    if (localStorage.getItem('cmo:loggedInUser')) {
      const user: any = localStorage.getItem('cmo:loggedInUser');
      this.loggedInUser = JSON.parse(user);

      if (this.loggedInUser?.profile_picture != null && this.loggedInUser?.profile_picture != 'null' && this.loggedInUser?.profile_picture) {
        titleHtmlCode = `
        <div class="aline-icon">
          <div class="left mr-3">
          <img class="circular_image" src="${this.loggedInUser?.profile_picture}">
           </div>    
          <div class="sp">
              <span class="text-white  small ">
              ${this.loggedInUser.firstname + " " + this.loggedInUser.lastname}    
              </span>
              <span class="ssp">
              <a class="bar-link p-link text-primary small pl-5">
                Account Settings
              </a>
             </span>
          </div>
        </div>`
      
      } else {
        titleHtmlCode = `
          <div class="aline-icon">
          <div class="left mr-3 ">
            <img alt="#" src="assets/img/man-user-profile.jpg"  class="circular_image">
            </div>
            <div class="sp">
                <span class="text-white  small ">
                  ${this.loggedInUser.firstname + " " + this.loggedInUser.lastname}               
                </span>
                <span class="ssp">
                <a class="bar-link p-link text-primary small pl-5">
                  Accout Settings
                </a>
               </span>
            </div>
          </div>`;
      }
    }


    const defaultOptions = {
      disableAt: false,
      customToggle: toggle,
      levelSpacing: 40,
      // navTitle:  `titleHtmlCode ${localStorage.getItem('cmo:profile-firstname')}`,
      navTitle: this.isLogin ? titleHtmlCode : '<a href="#">Chat My Order</a>',
      levelTitles: true,
      levelTitleAsBack: true,
      insertClose: 2,
      navClass: 'sidenav',
      removeOriginalNav: false
    };

    // call our plugin
    const Nav: any = mainNav.hcOffcanvasNav(defaultOptions);
    Nav.on('open', () => {
      Nav.update(true);
      $('a.bar-link').click((e) => {
        this.router.navigate(['account', 'profile']);
        Nav.close();
      });

      $('a.sidebar-link').click((e) => {
        const item: HTMLElement = e.target;
        const rLink = item.getAttribute('data-router-link');
        if (rLink) {
          if (rLink == '/lo') {
            this.logout();
            Nav.close();
          } else {
            this.router.navigateByUrl(rLink);
            Nav.close();
          }
        } else {
          return;
        }
      });
    });
    Nav.on('close', () => {
      $('a.sidebar-link').unbind('click');
    });
  }

  private getCMOContactDetails() {
    this.cmoApiService.getContactDetails().then((res: any) => {
      this.contactList = res.data;
      this.phoneNoLink = 'tel:+1' + this.contactList.contact_no;
      this.emailAddLink = 'mailto:' + this.contactList.sales_email;

    }, (error: any) => {
      const errMsg = this.helperService.getResponseErrorMessage(error);
      if (errMsg?.length) {
        this.toastrService.error(errMsg, this.constant.ERROR);
      }
    });
  }

  public goToProfile() {
    this.router.navigate(['account', 'profile']);
  }

  @HostListener('window:scroll', [])
  private handleScroll(): void {
    const windowScroll = window.pageYOffset;

    if (windowScroll < 400) this.isScroll = true;
    else this.isScroll = false;
  }

  //#endregion

  //#region public methods

  public logout() {
    this.toastrService.success(this.constant.SIGN_OUT_SUCCESS, this.constant.SUCCESS);
    this.helperService.clearLocalStorage('header-logout');
    window.location.replace('home');
  }

  public goToCheckout() {
    if (this.cartType === 'group') this.router.navigate(['checkout', 'group']);
    else this.router.navigate(['checkout', 'normal']);
  }

  public scrollHomeTo() {
    window.scroll({
      top: 0,
      left: 0,
      behavior: 'smooth'
    });
  }

  //#endregion
}
