import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import * as moment from 'moment';
import * as momentTz from 'moment-timezone';

import { AngularFireMessaging } from '@angular/fire/messaging';
import { SocialAuthService } from 'angularx-social-login';
import { mergeMap, retry } from 'rxjs/operators';
import { FormGroup } from '@angular/forms';
import { ConstantProviderService } from '../providers/constant-provider.service';
import { fromEvent } from 'rxjs';
import { Title } from '@angular/platform-browser';
declare var google: any;

@Injectable({
  providedIn: 'root',
})
export class HelperService {

  public normalCartList: any = [];
  public cartItemList: any = [];

  public groupCartList: any = [];

  public placeComponents: any = {
    locality: 'long_name',
    sublocality_level_1: 'long_name',
    postal_town: 'long_name',
    administrative_area_level_1: 'short_name',
    country: 'short_name',
    postal_code: 'short_name'
  };

  public autoCompleteElements: any = {
    locality: 'city',
    sublocality_level_1: 'city',
    postal_town: 'city',
    administrative_area_level_1: 'state',
    country: 'country',
    postal_code: 'zipcode'
  };

  public LAZY_LOAD_DEFUALT_IMAGE = 'assets/img/trending1.png';

  public COMPLETED_STATUS = ['Delivered', 'Picked Up'];
  public CANCELED_STATUS = ['Canceled'];
  public REFUNDED_STATUS = ['Refunded', 'Refund Requested'];
  public IN_PROGRESS_STATUS = ['Received', 'Being Prepared' ,'Ready For Pickup', 'On The Way', 'Accepted'];
  public ALL_STATUS = ['Delivered', 'Picked Up', 'Canceled', 'Refunded', 'Refund Rejected', 'Refund Requested', 'Received',
    'Ready For Pickup', 'Being Prepared', 'Confirmed', 'On The Way', 'Accepted', 'Action Required', 'Unclaimed','Failed'];
  public SOLD_OUT_OPTION_LIST = [
    // {
    //   title: 'Go with merchant recommendation',
    //   value: 0,
    // },
    {
      title: 'Use Alternative Item',
      value: 0,
    },
    {
      title: 'Refund This Item',
      value: 1,
    },
    {
      title: 'Cancel My Entire Order',
      value: 2,
    }
  ];
  public PER_PAGE_DATA = 20;

  constructor(
    private afMessaging: AngularFireMessaging,
    private socialAuthService: SocialAuthService,
    private constant: ConstantProviderService,
    private router: Router,
    private titleService : Title
  ) {
    this.checkInternetConnectivity();
  }

  public setTitle(title : string){
    if(title.length > 0){
      this.titleService.setTitle(`${title} | Chat My Order`);
    }
    else{
      this.titleService.setTitle('Chat My Order')
    }
  }

  public checkInternetConnectivity() {
    fromEvent(window, 'online').subscribe(e => {
      window.location.reload();
    });

    fromEvent(window, 'offline').subscribe(e => {
      alert('Please check your internet connectivity.');
      window.location.reload();
    });
  }

  public clearLocalStorage(label?: string) {
    localStorage.removeItem('chat-my-order:token');
    localStorage.removeItem('cmo:uid');
    localStorage.removeItem('cmo:account-avatar');
    localStorage.removeItem('cmo:profile-firstname');
    localStorage.removeItem('cmo:groupId');
    localStorage.removeItem('cmo:varified');
    localStorage.removeItem('cmo:id');
    localStorage.removeItem('bugsnag-anonymous-id');
    localStorage.removeItem('cmo:loggedInUser');
    localStorage.removeItem('switch_group_order');
    localStorage.removeItem('cmo:group_order_status');
    localStorage.removeItem('cmo:nearby');

    // remove location data
    localStorage.removeItem('chat-my-order:country');
    localStorage.removeItem('chat-my-order:state');
    localStorage.removeItem('chat-my-order:city');
    localStorage.removeItem('chat-my-order:street');
    localStorage.removeItem('chat-my-order:lat');
    localStorage.removeItem('chat-my-order:lng');
    localStorage.removeItem('chat-my-order:currentLocation')

    this.socialAuthService.authState.subscribe((user) => {
      if (user != null) {
        this.socialAuthService.signOut();
      }
    });
    if (window.location.protocol === 'https:') {
      this.deleteFCMToken();
    }
    if (label === 'header-logout') {
      this.router.navigate(['home']);

    } else if (label === 'maintenance-mode') {
      this.router.navigate(['maintenance-mode']);

    } else {
      this.router.navigate(['login'], {queryParams: { redirectTo: window.location.pathname}});
    }
  }

   //conver time from 24 hr format to 12 hr format
   public convertTimeFormat(timeArrayOfObjects:any){   
     timeArrayOfObjects.forEach((timeObj:any) => {

    if(timeObj.day_no!=null && timeObj.day_no && timeObj.day_no!=undefined){

      if(!(/\d{1,2}:\d{2} [AP]M/.test(timeObj?.start_time)) && !(/\d{1,2}:\d{2} [AP]M/.test(timeObj?.end_time))){
        const timeParts = timeObj?.start_time?.split(':');
        const timeParts2 = timeObj?.end_time?.split(':');

        let hours = parseInt(timeParts[0], 10);
        let hours2 = parseInt(timeParts2[0], 10);

        const minutes = timeParts[1];
        const minutes2 = timeParts2[1];

    
        const meridian = hours >= 12 ? 'PM' : 'AM';
        const meridian2 = hours2 >= 12 ? 'PM' : 'AM';
    
        hours = hours % 12;
        hours = hours || 12; // Convert 0 to 12
    
        hours2 = hours2 % 12;
        hours2 = hours2 || 12; // Convert 0 to 12

        timeObj.start_time = `${hours}:${minutes} ${meridian}`
        timeObj.end_time = `${hours2}:${minutes2} ${meridian2}`
      }
    }
    else{
      //for handling custom and special hours slots
      
      if(timeObj.day_no==null || timeObj.day_no==undefined){
      timeObj.forEach((ele:any) => {
        console.log('hii',ele.day_no)

        console.log('ppppooo')
        if(!(/\d{1,2}:\d{2} [AP]M/.test(ele?.start_time)) && !(/\d{1,2}:\d{2} [AP]M/.test(ele?.end_time))){
          const timeParts = ele?.start_time?.split(':');
          const timeParts2 = ele?.end_time?.split(':');
  
          let hours = parseInt(timeParts[0], 10);
          let hours2 = parseInt(timeParts2[0], 10);
  
          const minutes = timeParts[1];
          const minutes2 = timeParts2[1];
  
      
          const meridian = hours >= 12 ? 'PM' : 'AM';
          const meridian2 = hours2 >= 12 ? 'PM' : 'AM';
      
          hours = hours % 12;
          hours = hours || 12; // Convert 0 to 12
      
          hours2 = hours2 % 12;
          hours2 = hours2 || 12; // Convert 0 to 12
  
          ele.start_time = `${hours}:${minutes} ${meridian}`
          ele.end_time = `${hours2}:${minutes2} ${meridian2}`
        }

        
      });
    }
    }
      })
    
     
  }

  public deleteFCMToken() {
    this.afMessaging.getToken
      .pipe(mergeMap((token: any) => this.afMessaging.deleteToken(token)))
      .subscribe(
        (token) => {
          localStorage.removeItem('cmo:fcmToken');

        },
      );
  }

  public getToken(): any {
    const u = localStorage.getItem('chat-my-order:token');

    if (u) {
      return JSON.parse(u);
    }
  }

  // -------------------------------- Avatar call  --------------------------------
  public avatraCall(proRes: any) {
    if (proRes.firstname || proRes.lastname) {
      // get first and last name for my account image
      const firstname = proRes.firstname;
      const lastname = proRes.lastname;
      const initials = firstname.charAt(0).toUpperCase() + '' + lastname.charAt(0).toUpperCase();
      localStorage.setItem('cmo:account-avatar', initials);
      localStorage.setItem('cmo:profile-firstname', proRes.firstname);

    } else if (proRes.firstname || proRes.lastname) {
      const firstname = proRes.firstname;
      const lastname = proRes.lastname;
      const initials = firstname.charAt(0).toUpperCase() + '' + lastname.charAt(0).toUpperCase();
      localStorage.setItem('cmo:account-avatar', initials);
      localStorage.setItem('cmo:profile-firstname', proRes.firstname);

    } else {
      const initials = 'NU';
      const profileFirstname = 'User';
      localStorage.setItem('cmo:account-avatar', initials);
      localStorage.setItem('cmo:profile-firstname', profileFirstname);
    }
  }

  public getResponseErrorMessage(error: any) {
    if (error.status === 401) {
      this.clearLocalStorage();
      this.router.navigate(['login'], { queryParams: { redirectTo: window.location.pathname } });
    }
    else if (error?.error?.error?.details) return error?.error?.error?.details[0]?.message;
    else if (!error?.error?.error?.details) return error?.error?.message;
  }

  // -------------------------------- Letters only method --------------------------------
  public latterOnly(event: any): boolean {
    const charCode = (event.which) ? event.which : event.keyCode;
    if (charCode !== 32 && (charCode < 65 || charCode > 90) && (charCode < 97 || charCode > 122)) {
      return false;
    }
    return true;
  }

  // -------------------------------- Number only method --------------------------------
  public numberOnly(event: any): boolean {
    const charCode = (event.which) ? event.which : event.keyCode;
    if (charCode > 31 && (charCode < 48 || charCode > 57)) {
      return false;
    }
    return true;
  }

  // -------------------------------- Decimal only method --------------------------------
  public decimalOnly(event: any): boolean {
    const charCode = (event.which) ? event.which : event.keyCode;
    if (charCode === 46) return true;
    else if (charCode > 31 && (charCode < 48 || charCode > 57)) return false;
    else return true;
  }

  /**
  * Marks all controls in a form group as touched
  * @param formGroup - The form group to touch
  */
  public markFormGroupTouched(formGroup: FormGroup) {
    (<any>Object).values(formGroup.controls).forEach((control: any) => {
      control.markAsTouched();

      if (control.controls) {
        this.markFormGroupTouched(control);
      }
    });
  }

  public convertToSlug(text: string): string {
    return text.toString().toLowerCase()
      .replace(/\s+/g, '-')           // Replace spaces with -
      .replace(/[^\w\-]+/g, '')       // Remove all non-word chars
      .replace(/\-\-+/g, '-')         // Replace multiple - with single -
      .replace(/^-+/, '')             // Trim - from start of text
      .replace(/-+$/, '');
  }

  public getOrderVariantToppingDetail(orderData: any, name: any){
    const top: any[] = [];
    if (name == 'topping') {
      orderData.toppings.forEach((data: any) => {
        top.push(data.name);
      });
    
    } else {
      orderData.variants.forEach((data: any) => {
        const temp = `${data.name}: ${data.option.name}`;
        top.push(temp);
      });
    }
    return (name == 'topping') ? top.join(', ') : top.join('; ');
  }

  public getDistanceBetweenPoints(lat1: any, lng1: any, lat2: any, lng2: any) {
    const RADIANS: number = 180 / 3.14159265;

    const lt1 = lat1 / RADIANS;
    const lg1 = lng1 / RADIANS;
    const lt2 = lat2 / RADIANS;
    const lg2 = lng2 / RADIANS;

    const result = (3958.8) * Math.acos(Math.sin(lt1) * Math.sin(lt2) + Math.cos(lt1) * Math.cos(lt2) * Math.cos(lg2 - lg1));

    return result;
  }

  public refreshComponent(path: any) {
    this.router.routeReuseStrategy.shouldReuseRoute = () => false;
    this.router.onSameUrlNavigation = 'reload';
    this.router.navigate([path]);
  }

  public setNormalCartList(list: any) {
    this.normalCartList = list;
  }

  public getNormalCartList() {
    return this.normalCartList;
  }

  public setCartItem(list: any) {
    this.cartItemList = list;
  }

  public getCartItem() {
    return this.cartItemList;
  }

  public setGroupCartList(list: any) {
    this.groupCartList = list;
  }

  public getGroupCartList() {
    return this.groupCartList;
  }

  public checkRestaurantStatus(restaurantData: any) {
    const todayDay = momentTz.tz(restaurantData?.settings?.timezone_id).format('dddd'); // today's day
    const currentTime = momentTz.tz(restaurantData?.settings?.timezone_id).format('HH:mm'); // current time in 24hr format
    let isDelay = false;
    let isClose = false;

    if (restaurantData.business_hours) {
      restaurantData.business_hours.forEach((bHours: any) => {
        if (bHours.day === todayDay) {
  
          if (bHours.offday === true) { // restuarnat close
            isDelay = false;
            isClose = true;
  
          } else {
            const restaurantCloseTime24HourFormat = moment(bHours.close, ['hh:mm A']).format('HH:mm');
            const restaurantOpenTime24HourFormat = moment(bHours.open, ['hh:mm A']).format('HH:mm');
            const restaurantDelayTime = moment(bHours.close, ['hh:mm A']).subtract(restaurantData?.store_settings?.order_cutoff_time, 'minutes').format('HH:mm');
    
            if (currentTime < restaurantCloseTime24HourFormat && currentTime > restaurantDelayTime) { // check for restaurant in delay time
              isDelay = true;
              isClose = false;
    
            } else if (currentTime > restaurantCloseTime24HourFormat || currentTime < restaurantOpenTime24HourFormat) { // check for restaurant closed
              isDelay = false;
              isClose = true;
    
            } else {
              isDelay = false;
              isClose = false;
    
            }
          }
        }
      });
    }
    return {delay: isDelay, close: isClose};
  }

  public getDateFormat(date: any) {
    return moment(date).format('MM/DD/YYYY');
  }

  public scrollTop() {
    window.scroll({
      top: 0,
      left: 0,
      behavior: 'smooth'
    });
  }

  public getRandomNumberFromArray(data: any) {
    return data[Math.floor(Math.random() * data?.length)];
  }

  public get15DigitRandomNumber() {
    return Math.floor(Math.random() * 1000000000000000);
  }

  public getStoreStatus(status: string) {
    if (status == 'CUT_OFF_TIME') return 'STOP TAKING ORDERS';
    else if (status == 'OPEN') return 'OPEN NOW';
    else return status;
  }

  public getPriceLevelInfo(level: number) {
    const ind = this.constant.PRICE_LEVEL.findIndex((list: any) => { return list.value == level });
    if (ind > -1) return this.constant.PRICE_LEVEL[ind].label;
  }

  public getOrderStatusTrackingMessage(type: string, order: any, log?: any, actionReqType?: any, deliveryPartner?: any) {
    const timezoneId = order?.store_details?.settings?.timezone_id;
    let filterStatuses: any = this.constant.ORDER_TRACKING_MESSAGES.filter((list: any) => { return list.type == type });
    if (filterStatuses?.length) {
      const ind = filterStatuses[0]?.statuses.findIndex((list: any) => { return list.value == order?.status });
      if (ind > -1) {
        const timeFormat = moment(log?.timestamp).tz(timezoneId).format('hh:mm A');
        if (filterStatuses[0]?.type == 'Delivery') {
          
          // check overdue
          if (actionReqType && actionReqType?.type == 'ETA_OVERDUE'  && actionReqType?.left_time >= 0) {
            return `The restaurant is currently very busy, and they have informed us of an extended waiting period to prepare your food.<br><br>Please take an action below. otherwise, the order will be <b>canceled</b> if no action has been taken.`;
          
          } else if (actionReqType && actionReqType?.type == 'UNAVAILABLE_DELIVERY') {
            return `At this time, network demand is high, and we have no drivers available to delivery an order of the size requested. Please take an action below...`;
          
          } else {
            if (filterStatuses[0]?.statuses[ind].value == 'Delivered' || filterStatuses[0]?.statuses[ind].value == 'Refund Requested' ||
                filterStatuses[0]?.statuses[ind].value == 'Refunded' || filterStatuses[0]?.statuses[ind].value == 'Refund Rejected') {
              return `Your order was delivered at approximately ${timeFormat}.`;
            
            } else if (filterStatuses[0]?.statuses[ind].value == 'Canceled') {
              if (localStorage.getItem('cmo:id') == log.updated_by) {
                return 'This order was canceled by you before the restaurant began to prepare the food.<br><br>You were <b>not charged</b> for the order.';
              
              } else {
                return 'This order was canceled by the restaurant before they began to prepare the food.<br><br>You were <b>not charged</b> for the order.';
              }
            
            } else if (deliveryPartner != 'cmorider' && filterStatuses[0]?.statuses[ind].value == 'On The Way') {
              return 'Your order has been picked up and your driver is on the way to you.';

            } else {
              return filterStatuses[0]?.statuses[ind].label;
            }
          }

        } else if (filterStatuses[0]?.type == 'Pickup') {
          // check overdue
          if (actionReqType && actionReqType?.type == 'ETA_OVERDUE' && actionReqType?.left_time >= 0) {
            return `The restaurant is currently very busy, and they have informed us of an extended waiting period to prepare your food. Please take an action below. otherwise, the order will be <b>canceled</b> if no action has been taken.`;
          
          } else {
            if (filterStatuses[0]?.statuses[ind].value == 'Picked Up' || filterStatuses[0]?.statuses[ind].value == 'Refund Requested' ||
                filterStatuses[0]?.statuses[ind].value == 'Refunded' || filterStatuses[0]?.statuses[ind].value == 'Refund Rejected') {
              return `Your order was picked up at approximately ${timeFormat}.`;
            
            } else if (filterStatuses[0]?.statuses[ind].value == 'Ready For Pickup') {
              const rpTime = moment(log?.timestamp).tz(timezoneId).add(90, 'minutes').format('hh:mm A');
              return `Your order has been prepared and is waiting to be picked up.<br><br>Please Arrive by ${rpTime} or the order may be discarded.`;
            
            } else if (filterStatuses[0]?.statuses[ind].value == 'Canceled') {
              if (localStorage.getItem('cmo:id') == log.updated_by) {
                return 'This order was canceled by you before the restaurant began to prepare the food.<br><br>You were <b>not charged</b> for the order.';
              
              } else {
                return 'This order was canceled by the restaurant before they began to prepare the food.<br><br>You were <b>not charged</b> for the order.';
              }
            
            } else {
              return filterStatuses[0]?.statuses[ind].label;
            }
          }
        }
      }
    }
  }

  public getOrderStatusTitleMessage(type: string, status: string, actionReqType?: any, statusLog?: any) {
    if (actionReqType?.left_time >= 0) {
      return 'Your response is required.';

    } else if (status == 'Canceled') {
      const log = statusLog.find((list: any) => { return list.type == status });
      if (localStorage.getItem('cmo:id') == log.updated_by) {
        return 'You canceled the order.';
      
      } else {
        return 'Restaurant canceled the order.'
      }
    } else {
      let filterStatuses = this.constant.ORDER_STATUS_TITLE_MESSAGES.filter((list: any) => { return list.type == type });
      if (filterStatuses?.length) {
        const ind = filterStatuses[0]?.statuses.findIndex((list: any) => { return list.value == status });
        if (ind > -1) return filterStatuses[0]?.statuses[ind].label;
      }
    }
  }

  public getRestaurantPickupTime(restaurant: any) {
    for (let i = 0; i < restaurant?.apt_hours?.length; i++) {
      if (restaurant?.apt_hours[i].day == momentTz.tz(restaurant?.settings?.timezone_id).format('dddd')) {
        const time = momentTz.tz(restaurant?.settings?.timezone_id).format('hh:00 A');
        let splitTime = time.split(/[.:]/);
        // list.slot_number == splitTime[0]
        const findSlot = restaurant?.apt_hours[i].options.find((list: any) => { return list.slot == time });
        return findSlot?.min;
      }
    }
  }

  public getAfterDeliveredMessages(status: any) {
    if (status == 'Refund Requested') {
      return 'You requested a refund on this order and it is being reviewed.';
    
    } else if (status == 'Refund Rejected') {
      return 'You requested a refund on this order, but it was not approved.';
    }
  }

  public changeCardBrandName(brand: string): any {
    if (brand?.toLocaleLowerCase() == 'visa') return 'VISA';
    else if (brand?.toLocaleLowerCase() == 'mastercard') return 'MasterCard';
    else if (brand?.toLocaleLowerCase() == 'discover') return 'Discover';
    else if (brand?.toLocaleLowerCase() == 'amex') return 'American Express';
    else if (brand?.toLocaleLowerCase() == 'unionpay') return 'UnionPay';
    else if (brand?.toLocaleLowerCase() == 'jcb') return 'JCB';
  }

  public converToTimestamp(time: any) {
    let date = moment(time).format('MM/DD/YYYY hh:mm A');
    let diff = moment().diff(moment(date, 'MM/DD/YYYY hh:mm A'));
    let durationMin: any = moment.duration(diff).asMinutes();
    let durationHour: any = moment.duration(diff).asHours();
    let durationDay: any = moment.duration(diff).asDays();
    let durationYear: any = moment.duration(diff).asYears();

    if (durationMin < 1) return `Just now`;
    else if (parseInt(durationMin) == 1) return `${parseInt(durationMin)} min ago`;
    else if (parseInt(durationMin) > 1 && parseInt(durationMin) < 60) return `${parseInt(durationMin)} mins ago`;
    else if (parseInt(durationHour) == 1) return `${parseInt(durationHour)} hour ago`;
    else if (parseInt(durationHour) > 1 && parseInt(durationHour) < 24) return `${parseInt(durationHour)} hours ago`;
    else if (parseInt(durationDay) == 1) return `Yesterday ${moment(date, 'MM/DD/YYYY hh:mm A').format('@ h:mm A')}`;
    else if (parseInt(durationDay) > 1) return `${moment(date, 'MM/DD/YYYY hh:mm A').format('MMM DD @ h:mm A')}`;
    else if (parseInt(durationYear) >= 1) return `${moment(date, 'MM/DD/YYYY hh:mm A').format('MMM DD, YYYY @ h:MM A')}`;
  }

  public setFulfillmentType(type: string) {
    localStorage.setItem('header_fulfillment_type', type);
  }

  public getFulfillmentType() {
    return localStorage.getItem('header_fulfillment_type');
  }


  public preventUnwantedSocialLoginError(error: string): boolean {
    const msgs: any[] = [
      'popup_closed_by_user', 'User cancelled login or did not fully authorize.'
    ];

    const find = msgs.find((list: any) => { return list == error });
    if (find) return false;

    return true;
  }

  public findOrderStatusLog(log: Array<any>, status: string) {
    return log.find((obj: any) => { return obj.type == status });
  }

  public setNearByFilterOptions(object: object) {
    localStorage.setItem('cmo:nearby', JSON.stringify(object));
  }

  public getNearByFilterOptions() {
    const filters: any = localStorage.getItem('cmo:nearby');
    return JSON.parse(filters);
  }

  // async setCuisineIdLocaly(tag: any) {
  //   let selectedCuisineId = null;
  //   if (tag) {
  //     selectedCuisineId = tag?._id;
  //     await localStorage.setItem('selectedCuisineTag', selectedCuisineId);
  //   }
  // }

  // public getSelectedCuisineTag() {
  //   const selectedCuisineTagId = localStorage.getItem('selectedCuisineTag');
  //   if (selectedCuisineTagId) {
  //     return selectedCuisineTagId;
  //   } else {
  //     return null;
  //   }
  // }

}