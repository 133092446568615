import { Component, OnInit } from '@angular/core';
import { HelperService } from 'src/app/services/helper.service';
import { CmoapiService } from 'src/app/services/cmoWebsiteApi.service';
import { ToastrService } from 'ngx-toastr';
import { SimpleModalComponent, SimpleModalService } from 'ngx-simple-modal';
import { ItemAddUpdateModel } from 'src/app/models/general';
import { CreateGroupOrderComponent } from '../create-group-order/create-group-order.component';
import * as moment from 'moment';
import { environment } from 'src/environments/environment';
import { ClipboardService } from 'ngx-clipboard';
import { ShareStorePopUpComponent } from '../../share-store-pop-up/share-store-pop-up.component';
import { ConstantProviderService } from 'src/app/providers/constant-provider.service';
declare var $:any;

@Component({
  selector: 'app-view-group-order',
  templateUrl: './view-group-order.component.html',
  styleUrls: ['./view-group-order.component.css']
})

export class ViewGroupOrderComponent extends SimpleModalComponent<ItemAddUpdateModel, null> implements ItemAddUpdateModel, OnInit {

  //#region variables

  public data: any;

  public mode: string;
  public isResturantActive:boolean
  public isCopied = false;

  //#endregion

  //#region life cycle hook

  constructor(
    private helperService: HelperService,
    private cmoApiService: CmoapiService,
    private toastr: ToastrService,
    private simpleModalService: SimpleModalService,
    public clipboard: ClipboardService,
    public constant: ConstantProviderService,
  ) {
    super();
  }
  
  ngOnInit(): void {
    this.data = this.helperService.getGroupCartList();

    for (let i = 1; i <= this.data?.carts?.length - 1; i++) {
      $(document).ready(function() {
        $(`#popover${i}`).popover({
          placement: 'bottom',
          title: 'Guest Name',
          trigger: 'hover'
        }); 
      });
    }
  }

  //#endregion

  //#region public methods

  public openCreateGroupOrderModal() {
    // this.close();
    const modal = this.simpleModalService.addModal(CreateGroupOrderComponent, { data: this.data.store_details, mode: 'edit' });
    modal.subscribe((data: any) => {
      this.data = this.helperService.getGroupCartList();
    })
  }

  public getGroupOrderTime(time: any) {
    return moment(time).format('MMM DD, hh:mm a');
  }

  public getFriendAvatar(name: any) {
    const word = name.split(' ');
    const initials = word[0].charAt(0).toUpperCase() + '' + word[1].charAt(0).toUpperCase();
    return initials;
  }

  public copyLink() {
    this.isCopied = true;
    const storeName = this.helperService.convertToSlug(this.data.store_details.name);
    this.clipboard.copy(`${environment.WEBSITE_URL}/restaurant/${storeName}/${this.data.store_id}?group_id=${this.data.group_id}`);
    setTimeout(() => {
      this.isCopied = false;
    }, 2000);
  }

  public openSharePopUpModal(): void {
    // this.close();
    this.simpleModalService.addModal(ShareStorePopUpComponent, { data: this.data.store_details, type: 'group' });
  }

  public onClickcancelGroupOrder() {
    const storeName = this.helperService.convertToSlug(this.data.store_details.name);

    this.cmoApiService.cancelGroupOrder(this.data.group_id).then((res: any) => {
      this.toastr.success(res.message ,this.constant.SUCCESS);
      localStorage.removeItem('switch_group_order');
      localStorage.removeItem('cmo:group_order_status');
      window.location.replace('restaurant/' + storeName + '/' + this.data.store_details._id);
      this.close();

    }, (error: any) => {
      const errMsg = this.helperService.getResponseErrorMessage(error);
      if (errMsg?.length) {
        this.toastr.error(errMsg, this.constant.ERROR);
      }
    });
  }

  public redirectToMap(map: any) {
    const url = `https://maps.google.com/maps?q=${+map?.latitude},${map?.longitude}&hl=es;t=&amp;z=9&amp;ie=UTF8&amp;iwloc=&amp;output=embed`;
    window.open(url, '_blank');
  }

  //#endregion
}
