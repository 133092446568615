import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-order-successful',
  templateUrl: './order-successful.component.html',
  styleUrls: ['./order-successful.component.css']
})
export class OrderSuccessfulComponent implements OnInit {

  constructor() { }

  ngOnInit(): void {
  }

}
