<div class="modal-dialog modal-dialog-centered">
    <div class="modal-content">
        <div class="modal-header">
            <button type="button" class="back mr-2" (click)="close()" [title]="constant.BACK" *ngIf="type === 'group'">
                <i class="feather-arrow-left"></i>
            </button>
            <h5>Share Invitation Link</h5>
            <button type="button" class="close" (click)="close()" [title]="constant.CLOSE_TOOLTIP">
                <span aria-hidden="true">&times;</span>
            </button>
        </div>
        <div class="modal-body modal-height text-center">
            <button shareButton="facebook" class="btn btn-primary btn-sm" [url]="getRedirectStoreDetailsLink()" title="Facebook">
                <i class="fab fa-facebook"></i>
            </button> &nbsp;
            <button shareButton="twitter" class="btn btn-primary btn-sm" [url]="getRedirectStoreDetailsLink()" title="Twitter">
                <i class="fab fa-twitter"></i>
            </button>&nbsp;
            <button shareButton="whatsapp" class="btn btn-primary btn-sm" [url]="getRedirectStoreDetailsLink()" title="Whatsapp">
                <i class="fab fa-whatsapp"></i>
            </button>&nbsp;
            <button shareButton="email" class="btn btn-primary btn-sm" [url]="emailTemplate?.url" [title]="emailTemplate?.title" [description]="emailTemplate?.description">
                <i class="fas fa-envelope"></i>
            </button>&nbsp;
            <button shareButton="copy" class="btn btn-primary btn-sm" [url]="getRedirectStoreDetailsLink()" title="Copy Link" (click)="copyLink()">
                <i class="fas fa-link"></i>
            </button>
        </div>
        <!-- <div class="modal-footer p-0 border-0">
            <div class="col-6 m-0 p-0">
            </div>
            <div class="col-6 m-0 p-0">
            </div>
        </div> -->
    </div>
</div>