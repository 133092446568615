import { Component, OnInit, ChangeDetectorRef, ViewChild, ElementRef } from '@angular/core';
import { HelperService } from 'src/app/services/helper.service';
import { CmoapiService } from 'src/app/services/cmoWebsiteApi.service';
import { ToastrService } from 'ngx-toastr';
import { MessageService } from 'src/app/services/message.service';
import { SimpleModalComponent, SimpleModalService } from 'ngx-simple-modal';
import { CreateGroupOrderModel } from 'src/app/models/general';
import { Router } from '@angular/router';
import { InviteFriendComponent } from '../invite-friend/invite-friend.component';
import { AddressPopUpComponent } from '../../address-pop-up/address-pop-up.component';
import { ConstantProviderService } from 'src/app/providers/constant-provider.service';

declare var google: any;

@Component({
  selector: 'app-create-group-order',
  templateUrl: './create-group-order.component.html',
  styleUrls: ['./create-group-order.component.css']
})
export class CreateGroupOrderComponent extends SimpleModalComponent<CreateGroupOrderModel, null> implements CreateGroupOrderModel, OnInit {

  //#region variables

  @ViewChild('gMap', { static: true }) gMap: ElementRef;

  public data: any;
  public addressObject: any;
  public addressData: any;
  public autoCompleteService: any;
  public placeService: any;
  public map: any;
  public currentLocation: any;
  public groupOrderList: any;
  public selectedAddress: any = 'option';
  public hostNote: any;
  public isResturantActive:boolean 
  public activeTab: any = 'Delivery';
  
  public mode: string;

  public places: any = [];
  public addressList: any = [];

  public placeComponents = this.helperService.placeComponents;
  public autoCompleteElements = this.helperService.autoCompleteElements;

  public isLoggedIn = false;
  public isInRadius = false;

  //#endregion

  //#region life cycle hook

  constructor(
    private helperService: HelperService,
    private cmoApiService: CmoapiService,
    private cdr: ChangeDetectorRef,
    private toastr: ToastrService,
    private messageService: MessageService,
    private simpleModalService: SimpleModalService,
    public constant: ConstantProviderService,
  ) {
    super();
    this.addressObject = {
      id: '',
      label: '',
      firstname: '',
      lastname: '',
      street: '',
      city: '',
      state: '',
      country: '',
      zipcode: '',
      latitude: '',
      longitude: '',
      mobile: '',
      email: '',
      delivery_instruction: '',
    };
  }

  ngOnInit(): void {
    this.map = new google.maps.Map(this.gMap.nativeElement);
    this.autoCompleteService = new google.maps.places.AutocompleteService();
    this.placeService = new google.maps.places.PlacesService(this.map);

    if (localStorage.getItem('chat-my-order:token')) this.isLoggedIn = true;
    else this.isLoggedIn = false;

    this.groupOrderList = this.helperService.getGroupCartList();

    if (this.groupOrderList.delivery_address) {
      this.selectedAddress = this.groupOrderList.delivery_address.id;
      this.addressObject.id = this.groupOrderList.delivery_address.id;
      this.addressObject.label = this.groupOrderList.delivery_address.label;
      this.addressObject.firstname = this.groupOrderList.delivery_address.firstname;
      this.addressObject.lastname = this.groupOrderList.delivery_address.lastname;
      this.addressObject.street = this.groupOrderList.delivery_address.street;
      this.addressObject.address_line_2 = this.groupOrderList.delivery_address.address_line_2;
      this.addressObject.city = this.groupOrderList.delivery_address.city;
      this.addressObject.state = this.groupOrderList.delivery_address.state;
      this.addressObject.zipcode = this.groupOrderList.delivery_address.zipcode;
      this.addressObject.country = this.groupOrderList.delivery_address.country;
      this.addressObject.latitude = this.groupOrderList.delivery_address.latitude;
      this.addressObject.longitude = this.groupOrderList.delivery_address.longitude;
      this.addressObject.mobile = this.groupOrderList.delivery_address.mobile;
      this.addressObject.email = this.groupOrderList.delivery_address.email;
      this.addressObject.delivery_instruction = this.groupOrderList.delivery_address.delivery_instruction;
    }

    this.hostNote = this.groupOrderList.group_host_note;

    if (this.groupOrderList?.group_order_type) {
      this.activeTab = ((this.groupOrderList?.group_order_type == this.constant.ORDER_TYPES.PICKUP) && this.data?.fulfillment_types?.includes('Delivery')) ? this.constant.ORDER_TYPES.DELIVERY :
                       ((this.groupOrderList?.group_order_type == this.constant.ORDER_TYPES.DELIVERY) && this.data?.fulfillment_types?.includes('Pickup')) ? this.constant.ORDER_TYPES.PICKUP :
                       this.groupOrderList?.group_order_type;
    } else {
      this.activeTab = this.data?.fulfillment_types?.includes('Delivery') ? this.constant.ORDER_TYPES.DELIVERY :
                       this.data?.fulfillment_types?.includes('Pickup') ? this.constant.ORDER_TYPES.PICKUP :
                       this.activeTab;
    }

    this.cdr.detectChanges();
    this.getAllUserAddress();
  }

  //#endregion

  //#region private methods

  private getAllUserAddress() {
    this.cmoApiService.getUserAllAddresses(false).then((res: any) => {
      this.addressList = res.data;
    });
  }

  private getGeoLocation(lat: number, lng: number) {
    if (navigator.geolocation) {
      let geocoder = new google.maps.Geocoder();
      let latlng = new google.maps.LatLng(lat, lng);
      geocoder.geocode({ location: latlng}, (results: any) => {
        if (results[0]) {
          this.currentLocation = results[0].formatted_address;
          this.selectLocation(results[0]);
        }
      });
    }
  }

  private checkDistanceBetweenUserAndRestaurant(userAddressData: any) {
    if (this.data.settings.delivery_radius_miles > 0) {
      const distance = this.getDistanceBetweenPoints(this.data.address.latitude, this.data.address.longitude,
                        userAddressData.latitude, userAddressData.longitude);
      if (distance > this.data.settings.delivery_radius_miles) {
        this.isInRadius = true;
        this.toastr.warning(this.constant.CHECKOUT_DELIVERING_CURRENTLY , this.constant.OOPS);

      } else {
        this.isInRadius = false;
        this.toastr.success(this.constant.CHECKOUT_DELIVERING ,this.constant.SUCCESS);
      }

    } else {
      this.isInRadius = false;
      this.toastr.success(this.constant.CHECKOUT_DELIVERING ,this.constant.SUCCESS);
    }
  }

  private getDistanceBetweenPoints(lat1: any, lng1: any, lat2: any, lng2: any) {
    return this.helperService.getDistanceBetweenPoints(lat1, lng1, lat2, lng2);
  }

  //#endregion

  //#region public methods

  public changeTab(event: any) {
    this.activeTab = event
  }

  public getAddress(event: any) {
    const that = this;
    if (event.target.value) {
      this.autoCompleteService.getPlacePredictions({
        types: ['geocode'],
        input: event.target.value,
        componentRestrictions: { country: 'us' }
      }, (predictions: any, status: any) => {
        if (status === google.maps.places.PlacesServiceStatus.OK && predictions) {
          that.places = [];
          predictions.forEach((pred: any) => {
            that.places.push(pred);
          });
          this.cdr.detectChanges();

        } else {
          that.places = [];
        }
      });
    }
  }

  public selectLocation(place: any) {
    const that = this;
    that.places = [];
    that.placeService.getDetails({ placeId: place.place_id }, (details: any) => {

      this.currentLocation = details.formatted_address;
      that.addressObject.street = details.name;
      for (let i = 0; i < details.address_components.length; i++) {
        const addressType = details.address_components[i].types[0];
        if (that.placeComponents[addressType]) {
          const value = details.address_components[i][this.placeComponents[addressType]];
          that.addressObject[that.autoCompleteElements[addressType]] = value;
        }
      }
      that.addressObject.latitude = details.geometry.location.lat();
      that.addressObject.longitude = details.geometry.location.lng();
      this.cdr.detectChanges();
      
      this.checkDistanceBetweenUserAndRestaurant(that.addressObject)
    });
  }

  public onClickCurrentLocation() {
    navigator?.geolocation?.getCurrentPosition((potition: any) => {
      this.getGeoLocation(potition.coords.latitude, potition.coords.longitude);
    })
  }

  public async createOrUpdateGroupOrder() {
    if (this.hostNote && !this.hostNote.trim()?.length) {
      return this.toastr.error(this.constant.GROUP_ORDER.NOTE_VALIDATION, this.constant.ERROR);
    }

    const obj = {
      type: this.activeTab == 'Delivery' ? 'Delivery' : 'Pickup',
      delivery_address: this.activeTab == 'Delivery' ? {
        street: this.addressObject.street,
        label: this.addressObject.label,
        firstname: this.addressObject.firstname,
        lastname: this.addressObject.lastname,
        city: this.addressObject.city,
        address_line_2: this.addressObject.address_line_2,
        state: this.addressObject.state,
        country: this.addressObject.country,
        zipcode: this.addressObject.zipcode,
        latitude: this.addressObject.latitude,
        longitude: this.addressObject.longitude,
        id: this.addressObject.id,
        mobile: this.addressObject.mobile,
        email: this.addressObject.email,
        delivery_instruction: this.addressObject.delivery_instruction
      } : null,
      group_host_note: this.hostNote?.trim()?.length ? this.hostNote.trim() : null
    };

    if (this.mode == 'add') {
      if (Object.keys(this.groupOrderList).length > 0 && this.groupOrderList.store_id !== this.data._id) {
        const that = this;

        // open modal
        const mainNav: any = $('#replaceOrderModelCreateOrder');
        mainNav.modal('show');

        // catch close event
        mainNav.on('click', '#replaceFlag', function () {
          // add item into cart
          that.cmoApiService.createGroupOrder(that.data._id, obj).then((res: any) => {
            that.helperService.setGroupCartList(res.data);
            that.messageService.sendMessage('Create-Group-Order');
            that.close();
            that.openInviteFriendModal(res.data);

          }, (error: any) => {
            const errMsg = that.helperService.getResponseErrorMessage(error);
            if (errMsg?.length) {
              that.toastr.error(errMsg, that.constant.ERROR);
            }
          });
        });

      } else {
        this.cmoApiService.createGroupOrder(this.data._id, obj).then((res: any) => {
          this.helperService.setGroupCartList(res.data);
          this.messageService.sendMessage('Create-Group-Order');
          this.close();
          this.openInviteFriendModal(res.data);

        }, (error: any) => {
          const errMsg = this.helperService.getResponseErrorMessage(error);
          if (errMsg?.length) {
            this.toastr.error(errMsg, this.constant.ERROR);
          }
        });
      }

    } else {
      this.cmoApiService.updateGroupOrder(this.groupOrderList.group_id, obj).then((res: any) => {
        this.helperService.setGroupCartList(res.data);
        this.messageService.sendMessage('Update-Group-Order');
        this.close();
        this.toastr.success(res.message, this.constant.SUCCESS);

      }, (error: any) => {
        const errMsg = this.helperService.getResponseErrorMessage(error);
        if (errMsg?.length) {
          this.toastr.error(errMsg, this.constant.ERROR);
        }
      });
    }
  }

  public getRedirectStoreDetailsLink() {
    const mainNav: any = $('#replaceOrderModelCreateOrder');
    mainNav.modal('hide');
    const storeName = this.helperService.convertToSlug(this.data.name);
    this.close();
    window.location.replace('/restaurant/' + storeName + '/' + this.data._id);
  }

  public openInviteFriendModal(groupData: any) {
    this.simpleModalService.addModal(
      InviteFriendComponent,
      {
        data: {
          group: groupData,
          store_name: this.data.name
        },
        mode: 'add'
      }
    );
  }

  public onChangerAddress(event: any) {
    if (event.target.value == 'add') {
      this.addNewAddress();

    } else if (event.target.value != 'option') {
      const filterAddress = this.addressList.filter((list: any) => list.id == event.target.value);
      this.currentLocation = filterAddress[0].id;
      this.addressObject.id = filterAddress[0].id;
      this.addressObject.firstname = filterAddress[0].firstname,
      this.addressObject.lastname = filterAddress[0].lastname,
      this.addressObject.street = filterAddress[0].street;
      this.addressObject.address_line_2 = filterAddress[0].address_line_2;
      this.addressObject.city = filterAddress[0].city;
      this.addressObject.state = filterAddress[0].state;
      this.addressObject.zipcode = filterAddress[0].zipcode;
      this.addressObject.country = filterAddress[0].country;
      this.addressObject.latitude = filterAddress[0].latitude;
      this.addressObject.longitude = filterAddress[0].longitude;
      this.addressObject.mobile = filterAddress[0].mobile;
      this.addressObject.email = filterAddress[0].email;
      this.addressObject.delivery_instruction = filterAddress[0].delivery_instruction;
      this.checkDistanceBetweenUserAndRestaurant(filterAddress[0]);
    
    } else if (event.target.value == 'option') {
      this.resetAddressObj();
    }
  }

  private resetAddressObj() {
    this.currentLocation = null;
    this.addressObject = {
      id: '',
      label: '',
      firstname: '',
      lastname: '',
      street: '',
      city: '',
      state: '',
      country: '',
      zipcode: '',
      latitude: '',
      longitude: '',
      mobile: '',
      email: '',
      delivery_instruction: '',
    };
  }

  public addNewAddress() {
    this.close();
    this.simpleModalService.addModal(
      AddressPopUpComponent,
      {
        data: '',
        mode: 'add',
        from: {
          location: 'create-group',
          mode: this.mode,
          locationData: this.data
        }
      }
    );
  }

  //#endregion
}
