import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { LoginComponent } from './components/login/login.component';
import { SignupComponent } from './components/signup/signup.component';
import { NotfoundComponent } from './components/layouts/notfound/notfound.component';

const routes: Routes = [
  {
    path: '',
    redirectTo: 'home',
    pathMatch: 'full'
  },
  {
    path: 'home',
    loadChildren: () => import('./components/home/home.module').then(m => m.HomeModule)
  },
  {
    path: 'get-app-link',
    loadChildren: () => import('./components/mobile/mobile.module').then(m => m.MobileModule)
  },
  // {
  //   path: 'restaurant/:storeName/:id',
  //   loadChildren: () => import('./components/restaurant/restaurant.module').then(m => m.RestaurantModule)
  // },
  {
    path: 'restaurant',
    loadChildren: () => import('./components/restaurant/restaurant.module').then(m => m.RestaurantModule)
  },
  {
    path: 'offers',
    loadChildren: () => import('./components/offers/offers.module').then(m => m.OffersModule)
  },
  {
    path: 'checkout',
    loadChildren: () => import('./components/checkout/checkout.module').then(m => m.CheckoutModule)
  },
  {
    path: 'favorites',
    loadChildren: () => import('./components/favorites/favorites.module').then(m => m.FavoritesModule)
  },
  // {
  //   path: 'trending',
  //   loadChildren: () => import('./components/trending/trending.module').then(m => m.TrendingModule)
  // },
  // {
  //   path: 'popular',
  //   loadChildren: () => import('./components/popular/popular.module').then(m => m.PopularModule)
  // },
  {
    path: 'near-by',
    loadChildren: () => import('./components/near-by/near-by.module').then(m => m.NearByModule)
  },
  {
    path: 'account',
    loadChildren: () => import('./components/my-account/my-account.module').then(m => m.MyAccountModule)
  },
  {
    path: 'login',
    loadChildren: () => import('./components/login/login.module').then(m => m.LoginModule),
    data: { header: false }
  },
  {
    path: 'signup',
    loadChildren: () => import('./components/signup/signup.module').then(m => m.SignupModule),
    data: { header: false }
  },
  {
    path: 'forgot-password',
    loadChildren: () => import('./components/forgot-password/forgot-password.module').then(m => m.ForgotPasswordModule),
    data: { header: false }
  },
  {
    path: 'email-verification',
    loadChildren: () => import('./components/email-verification/email-verification.module').then(m => m.EmailVerificationModule),
    data: { header: false }
  },
  {
    path: 'reset-password',
    loadChildren: () => import('./components/reset-password/reset-password.module').then(m => m.ResetPasswordModule),
    data: { header: false }
  },
  {
    path: 'order-successful',
    loadChildren: () => import('./components/order-successful/order-successful.module').then(m => m.OrderSuccessfulModule),
  },
  {
    path: 'order-fail',
    loadChildren: () => import('./components/order-fail/order-fail.module').then(m => m.OrderFailModule),
  },
  {
    path: 'search',
    loadChildren: () => import('./components/search/search.module').then(m => m.SearchModule),
  },
  {
    path: 'grouporder/:id',
    loadChildren: () => import('./components/accept-group-invitation/accept-group-invitation.module').then(m => m.AcceptGroupInvitationModule),
  },
  {
    path: 'maintenance-mode',
    loadChildren: () => import('./components/maintenance-mode/maintenance-mode.module').then(m => m.MaintenanceModeModule),
    data: { header: false },
  },
  {
    path: 'contact-us',
    loadChildren: () => import('./components/contact-us/contact-us.module').then(m => m.ContactUsModule)
  },
  {
    path: '404',
    component: NotfoundComponent
  },
  {
    path: '**',
    redirectTo: '404'
  }
];

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule]
})
export class AppRoutingModule { }
