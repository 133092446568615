import {
  Component,
  OnInit,
  ViewChild,
  ElementRef,
  ChangeDetectorRef,
  HostListener,
} from '@angular/core';
import { HelperService } from 'src/app/services/helper.service';
import { environment } from 'src/environments/environment';
import { Subscription } from 'rxjs';
import { CmoapiService } from 'src/app/services/cmoWebsiteApi.service';
import { Router, ActivatedRoute, NavigationEnd } from '@angular/router';
import * as _ from 'underscore';
import { ToastrService } from 'ngx-toastr';
import { MessageService } from 'src/app/services/message.service';
import { ItemAddUpdatePopUpComponent } from '../shared/item-add-update-pop-up/item-add-update-pop-up.component';
import { SimpleModalService } from 'ngx-simple-modal';
import * as Pubnub from 'pubnub';
import * as moment from 'moment';
import { ViewGroupOrderComponent } from '../shared/group-order/view-group-order/view-group-order.component';
import { ConstantProviderService } from 'src/app/providers/constant-provider.service';

declare var google: any;
declare var $: any;

@Component({
  selector: 'app-header',
  templateUrl: './header.component.html',
  styleUrls: ['./header.component.css'],
})
export class HeaderComponent implements OnInit {
  //#region variables

  @ViewChild('gMap', { static: true }) gMap: ElementRef;

  public businessLink = environment.BUSINESS_PORTAL_URL;

  public placesList: any[] = [];
  public filterList: any = [];
  public notifications: any[] = [];
  public cartOrderList: any = [];
  public cartItemId: any = [];
  public groupCartList: any = [];
  public contactList: any = [];
  public allUserAddress: any[] = [];

  public addressObject: any;
  public autoCompleteService: any;
  public placeService: any;
  public map: any;
  public currentLocation: any;
  public selectedCurrentLocation: any = null;
  public searchLocationValue: any;
  public displayHeaderSearchLocation: any;
  public loggedInUserId: any;
  public loggedInUser: any;
  public localStorageSwitchGroupOrderData: any;
  public headerFulfillmentType: any;
  public cartType = '';

  public placeComponents = this.helperService.placeComponents;
  public autoCompleteElements = this.helperService.autoCompleteElements;

  public isSubmission: boolean;
  public isLogin: boolean;
  public isSticky: boolean = true;
  public hasLocation = false;
  public showMoreRestaurant = false;
  public cartAddRemoveLoading = false;
  public isClose = false;
  public isDelay = false;
  public isInGroup = false;
  public switchToGroup: boolean = false;

  public filterListSubscription: Subscription;

  public profileAvatar = localStorage.getItem('cmo:account-avatar');
  public profileName = localStorage.getItem('cmo:profile-firstname');

  public totalUnReadNotificationCount = 0;

  public cartId: string;
  public useCurrentLocationText: string;

  public arrowkeyLocation = -1;
  public arrowkeyUserLocation = -1;

  public pubnub: Pubnub;
  
  public isInCartPage:boolean = false
  public restaurantId: any = null;
  public customerCart: any;
  public storeDetails: any;
  public cartSubTotal: any = 0
  public singleCartItemId: any = '';

  //#endregion

  //#region life cycle hook

  constructor(
    private helperService: HelperService,
    private cdr: ChangeDetectorRef,
    private cmoApiService: CmoapiService,
    private router: Router,
    private toastr: ToastrService,
    private messageService: MessageService,
    private simpleModalService: SimpleModalService,
    private route: ActivatedRoute,
    public constant: ConstantProviderService
  ) {
    this.addressObject = {
      street: '',
      city: '',
      state: '',
      country: '',
    };
    this.onSearchLocation = _.debounce(this.onSearchLocation, 400);
    this.getAddress = _.debounce(this.getAddress, 1000);
    this.getLocationFromUser();

    if (window.location.pathname.includes('/group')) this.isInGroup = true;
    else this.isInGroup = false;

    if (window.location.pathname.includes('checkout/normal')) {
      this.localStorageSwitchGroupOrderData = null;
    } else {
      this.localStorageSwitchGroupOrderData =
        localStorage.getItem('switch_group_order');
    }

    this.loggedInUserId = localStorage.getItem('cmo:id');

    // get and set fulfillmentType
    const globalSelectedFulfillment = this.helperService.getFulfillmentType();
    if (
      !globalSelectedFulfillment ||
      globalSelectedFulfillment == null ||
      globalSelectedFulfillment == undefined
    ) {
      this.onClickFulfillmentType(constant.ORDER_TYPES.DELIVERY);
    } else {
      this.headerFulfillmentType = globalSelectedFulfillment;
    }

    this.getCMOContactDetails();
    this.getRestaurantIdOnDetailsPage();
  }

  ngOnInit(): void {
    // ------------------------- Map services -------------------------
    this.map = new google.maps.Map(this.gMap?.nativeElement);
    this.autoCompleteService = new google.maps.places.AutocompleteService();
    this.placeService = new google.maps.places.PlacesService(this.map);

    this.messageService.getMessage().subscribe((message: any) => {
      if (message?.msg == 'GROUP_ORDER_JOIN') {
        if (message.customer_id == this.groupCartList?.customer_id) {
          this.getGroupOrderData();
          this.toastr.success(
            this.constant.GROUP_ORDER_JOINED,
            this.constant.SUCCESS
          );
        }
      } else if (message?.msg == 'GROUP_ORDER_LEAVE') {
        if (message.customer_id == this.groupCartList?.customer_id) {
          this.getGroupOrderData();
          setTimeout(() => {
            this.messageService.sendMessage('Header');
          }, 1000);
          this.toastr.info(
            this.constant.GROUP_ORDER_LEAVED,
            this.constant.OOPS
          );
        } else {
          const storeName = this.groupCartList.store_details.name;
          const storeId = this.groupCartList.store_details._id;

          this.localStorageSwitchGroupOrderData = null;
          localStorage.removeItem('switch_group_order');
          localStorage.removeItem('cmo:group_order_status');
          this.getGroupOrderData();
          setTimeout(() => {
            this.messageService.sendMessage('Header');
            window.location.replace(
              '/restaurant/' +
              this.helperService.convertToSlug(storeName) +
              '/' +
              storeId
            );
          }, 1000);
        }
      } else if (message?.msg == 'GROUP_CART_SUBMITTED') {
        if (message.customer_id == this.groupCartList?.customer_id) {
          this.getGroupOrderData();
          this.toastr.info(
            this.constant.GROUP_ORDER_SUBMITTED,
            this.constant.HURRY
          );
        }
      } else if (message?.msg == 'GROUP_ORDER_ACTION') {
        if (message.customer_id == this.groupCartList?.customer_id) {
          this.getGroupOrderData();
          // this.toastr.info(this.constant.GROUP_ORDER_ACTIONS, this.constant.HURRY);
        }
      } else if (
        message == 'Item-Add' ||
        message == 'Restaurant' ||
        message == 'Near-By' ||
        message == 'Home' ||
        message == 'Trending' ||
        message == 'Popular' ||
        message == 'Account' ||
        message == 'Checkout' ||
        message == 'Confirm-Add' ||
        message == 'Update-Group-Order' ||
        message == 'Favorites' ||
        message == 'Offers'
      ) {
        this.headerMainFunctions();
        const user: any = localStorage.getItem('cmo:loggedInUser');
        this.loggedInUser = JSON.parse(user);
        this.profileAvatar = localStorage.getItem('cmo:account-avatar');
        this.profileName = localStorage.getItem('cmo:profile-firstname');

        if (!localStorage.getItem('switch_group_order')) {
          this.localStorageSwitchGroupOrderData = null;
        } else {
          this.localStorageSwitchGroupOrderData =
            localStorage.getItem('switch_group_order');
        }

        this.cdr.detectChanges();
      } else if (message == 'Profile-Picture') {
        this.cmoApiService.getProfile().then((res: any) => {
          localStorage.setItem('cmo:loggedInUser', JSON.stringify(res.data));

          // sent event for update profile picture in footer as well
          this.messageService.sendMessage('Footer-Profile-Pic');

          this.loggedInUser = res.data;
        });
      } else if (
        message == 'start-interval' &&
        localStorage.getItem('chat-my-order:token')
      ) {
        setInterval(() => {
          this.getRefreshToken();
        }, this.constant.REFRESH_TOKEN_INTERVAL);
      }
    });

    this.router.events.subscribe((event) => {
      if (event instanceof NavigationEnd) {
        this.handleUrlChange();
      }
    });
  }

  //#endregion

  //#region private methods

  private getStoreDetails() {
    this.cmoApiService
      .getRestaurantDetailById(this.cartOrderList.store_id)
      .then((res: any) => {
        this.storeDetails = res.data;
      });
  }

  private getRefreshToken() {
    const token: any = localStorage?.getItem('chat-my-order:token');
    const obj: object = {
      access_token: JSON.parse(token),
      device: this.constant.DEVICE,
    };
    this.cmoApiService.updateRefreshToken(obj).then(
      (res: any) => {
        localStorage.setItem(
          'chat-my-order:token',
          JSON.stringify(res.data.token)
        );
      },
      (error: any) => {
        this.toastr.info(this.constant.LOGIN_MESSAGE, this.constant.SORRY);
        this.helperService.clearLocalStorage();
      }
    );
  }

  private handleUrlChange() {
    const currentUrl = this.router.url;
    if (currentUrl.includes('checkout/normal') || currentUrl.includes('/checkout/group')) {
      this.localStorageSwitchGroupOrderData = null;
      this.isInCartPage = true;
      // Do something else if needed
    } else {
      this.isInCartPage = false;
      // Reset or do something else if needed
    }
  }

  private getCMOContactDetails() {
    this.cmoApiService.getContactDetails().then(
      (res: any) => {
        this.contactList = res.data;
      },
      (error: any) => {
        const errMsg = this.helperService.getResponseErrorMessage(error);
        if (errMsg?.length) {
          this.toastr.error(errMsg, this.constant.ERROR);
        }
      }
    );
  }

  private getGeoLocation(lat: number, lng: number) {
    if (navigator.geolocation) {
      let geocoder = new google.maps.Geocoder();
      let latlng = new google.maps.LatLng(lat, lng);
      geocoder.geocode({ location: latlng }, (results: any) => {
        if (results[0]) {
          // this.currentLocation = results[0].formatted_address;
          this.selectLocation(results[0]);
        }
      });
    }
  }

  private getFilterByLocation(location: any) {
    this.cmoApiService.getFilterByLocation(location, 1, 6).then(
      (res: any) => {
        let filterMenu: any = [];
        if (res.data?.items) {
          res.data.items.forEach((item: any) => {
            item.sku ='453455654'
              filterMenu.push(item);          
          });
        }

        if (res.data?.listings?.length > 3 && !filterMenu?.length) {
          this.filterList = res.data.listings.slice(0, 5);
        } else if (!res.data?.listings?.length && filterMenu?.length > 2) {
          this.filterList = filterMenu.slice(0, 5);
        } else {
          this.filterList = res.data.listings
            .slice(0, 3)
            .concat(filterMenu.slice(0, 2));
        }

        if (this.filterList.length >= 5) this.showMoreRestaurant = true;
        else this.showMoreRestaurant = false;
      },
      (error: any) => {
        const errMsg = this.helperService.getResponseErrorMessage(error);
        if (errMsg?.length) {
          this.toastr.error(errMsg, this.constant.ERROR);
        }
      }
    );
  }

  private getLocationFromUser() {
    if (localStorage.getItem('chat-my-order:token')) {
      if (!localStorage.getItem('chat-my-order:currentLocation')) {
        this.getUserAllAddress(); // get user all addresses
      } else if (
        localStorage.getItem('chat-my-order:city') == 'null' ||
        localStorage.getItem('chat-my-order:state') == 'null' ||
        localStorage.getItem('chat-my-order:country') == 'null'
      ) {
        // this.onClickCurrentLocation();
      } else {
        // this.displayHeaderSearchLocation = localStorage.getItem('chat-my-order:city') + ', ' +
        //   localStorage.getItem('chat-my-order:state') + ', ' +
        //   localStorage.getItem('chat-my-order:country');
        this.displayHeaderSearchLocation = localStorage.getItem(
          'chat-my-order:street'
        );
        this.selectedCurrentLocation = localStorage.getItem(
          'chat-my-order:currentLocation'
        );
        this.messageService.sendMessage('location-select');
      }
    } else {
      if (!localStorage.getItem('chat-my-order:currentLocation')) {
        // this.onClickCurrentLocation(); // get current location
      } else if (
        localStorage.getItem('chat-my-order:city') == 'null' ||
        localStorage.getItem('chat-my-order:state') === 'null' ||
        localStorage.getItem('chat-my-order:country') == 'null'
      ) {
        // this.onClickCurrentLocation();
      } else {
        // this.displayHeaderSearchLocation = localStorage.getItem('chat-my-order:city') + ', ' +
        //   localStorage.getItem('chat-my-order:state') + ', ' +
        //   localStorage.getItem('chat-my-order:country');
        this.displayHeaderSearchLocation = localStorage.getItem(
          'chat-my-order:street'
        );
        this.selectedCurrentLocation = localStorage.getItem(
          'chat-my-order:currentLocation'
        );
      }
    }
  }

  private getUserAllAddress() {
    this.cmoApiService.getUserAllAddresses(true).then(
      (res: any) => {
        if (res.data.length) {
          this.placesList = res.data;
          const filterArray = res.data.filter(
            (list: any) => list.default === true
          );

          if (filterArray.length)
            this.setLocationInLocalStorage(
              filterArray[0]
            ); // if default address in array
          else this.setLocationInLocalStorage(res.data[0]); // select current location as array's 1st location if no default address
        } else {
          // this.onClickCurrentLocation();
        }

        // check currentLocation
        if (localStorage.getItem('chat-my-order:currentLocation')) {
          this.messageService.sendMessage('location-selected');
          this.displayHeaderSearchLocation = localStorage.getItem(
            'chat-my-order:street'
          );
        }
      },
      (error: any) => {
        const errMsg = this.helperService.getResponseErrorMessage(error);
        if (errMsg?.length) {
          this.toastr.error(errMsg, this.constant.ERROR);
        }
      }
    );
  }

  private setLocationInLocalStorage(address: any) {
    localStorage.setItem('chat-my-order:lat', address.latitude);
    localStorage.setItem('chat-my-order:lng', address.longitude);
    const cAddress = address?.address_line_2
      ? `${address.street}, ${address.address_line_2}, ${address.city}, ${address.state} ${address.zipcode}`
      : `${address.street}, ${address.city}, ${address.state} ${address.zipcode}`;
    localStorage.setItem('chat-my-order:currentLocation', cAddress);
    localStorage.setItem('chat-my-order:city', address.city);
    localStorage.setItem('chat-my-order:state', address.state);
    localStorage.setItem('chat-my-order:zipcode', address.zipcode);
    localStorage.setItem('chat-my-order:country', address.country);
    localStorage.setItem('chat-my-order:street', address.street);
  }

  private getAllCartDetails() {
    this.cmoApiService.getNormalCartDetail().then(
      (res: any) => {
        this.cartOrderList = res.data;
       console.log('cartOrderList269',this.cartOrderList)
        if (res.data.items.length) {
          this.helperService.setNormalCartList(res.data); // save global cart array list
          // this.checkRestaurantCutOffTime(data.store_details);
        }
      },
      (error: any) => {
        const errMsg = this.helperService.getResponseErrorMessage(error);
        if (errMsg?.length) {
          this.toastr.error(errMsg, this.constant.ERROR);
        }
      }
    );
  }

  private getAllNotifications() {
    let data: any[] = [];
    if (localStorage.getItem('chat-my-order:token')) {
      this.cmoApiService.getAllNotifications('unread').then(
        (res: any) => {
          data = res.data;
          this.notifications = res.data.slice(0, 5);
          const urReadCount = data.filter((list: any) => {
            return list.read == false;
          });
          this.totalUnReadNotificationCount = urReadCount.length
            ? urReadCount.length
            : 0;
        },
        (error: any) => {
          const errMsg = this.helperService.getResponseErrorMessage(error);
          if (errMsg?.length) {
            this.toastr.error(errMsg, this.constant.ERROR);
          }
        }
      );
    }
  }

  private headerMainFunctions() {
    this.searchLocationValue = '';
    this.messageService.sendMessage('Footer');
    this.getLocationFromUser();

    if (localStorage.getItem('chat-my-order:token')) {
      this.isLogin = true;
      this.cmoApiService.getUserAllAddresses(true).then((res: any) => {
        this.placesList = [];
        this.allUserAddress = res.data;
        this.allUserAddress.forEach((list: any) => {
          this.placesList.push(list);
        });
        // this.placesList = data;
      });
      this.getAllNotifications();

      if (window.location.pathname.includes('/group')) this.isInGroup = true;
      else this.isInGroup = false;

      // if (!this.isInGroup) this.getAllCartDetails();
      // else this.getGroupOrderData();

      this.getAllCartDetails();
      this.getGroupOrderData();
    } else {
      this.isLogin = false;
    }
  }

  private checkRestaurantCutOffTime(restaurantData: any) {
    const data = this.helperService.checkRestaurantStatus(restaurantData);
    this.isDelay = data.delay;
    this.isClose = data.close;
  }

  private getGroupOrderData() {
    this.cmoApiService.getGroupOrder().then(
      (res: any) => {
        this.groupCartList = res.data;
        this.helperService.setGroupCartList(res.data);
        if (res.data?.group_id) {
          localStorage.setItem('cmo:group_order_status', 'active');
        } else {
          localStorage.removeItem('cmo:group_order_status');
        }
        // this.checkRestaurantCutOffTime(data.store_details);
        this.messageService.sendMessage('Get-Group-Order');
      },
      (error: any) => {
        const errMsg = this.helperService.getResponseErrorMessage(error);
        if (errMsg?.length) {
          this.toastr.error(errMsg, this.constant.ERROR);
        }
      }
    );
  }

  private getPrediction(event: any) {
    const that = this;
    this.autoCompleteService.getPlacePredictions(
      {
        types: ['(regions)'],
        input: event,
        componentRestrictions: { country: 'us' },
      },
      (predictions: any, status: any) => {
        if (
          status === google.maps.places.PlacesServiceStatus.OK &&
          predictions
        ) {
          that.placesList.shift();
          that.placesList.unshift(predictions[0]);
          if (predictions?.length) this.hasLocation = false;
        }
      }
    );
  }

  //#endregion

  //#region public methods

  public logout() {
    this.helperService.clearLocalStorage('header-logout');
  }

  public getAddress(event: any) {
    const that = this;
    if (event && event !== undefined) {
      this.autoCompleteService.getPlacePredictions(
        {
          types: ['geocode'],
          input: event,
          componentRestrictions: { country: 'us' },
        },
        (predictions: any, status: any) => {
          if (
            status == google.maps.places.PlacesServiceStatus.OK &&
            predictions
          ) {
            that.placesList = [];
            predictions.forEach((pred: any) => {
              that.placesList.push(pred);
            });
            if (!isNaN(event) && event?.length == 5) {
              this.getPrediction(event);
            } else {
              if (predictions?.length) this.hasLocation = false;
            }
          } else {
            that.placesList = [];
            that.allUserAddress.forEach((list: any) => {
              that.placesList.push(list);
            });
            that.hasLocation = true;
          }
        }
      );
      this.cdr.detectChanges();
    } else {
      that.placesList = [];
      that.allUserAddress.forEach((list: any) => {
        that.placesList.push(list);
      });
    }
  }

  public selectLocation(place: any) {
    const that = this;
    if (place !== undefined && place !== null) {
      if (place?.place_id) {
        that.placeService.getDetails(
          { placeId: place.place_id },
          (details: any) => {
            for (let i = 0; i < details.address_components.length; i++) {
              const addressType = details.address_components[i].types[0];
              if (that.placeComponents[addressType]) {
                const value =
                  details.address_components[i][
                  this.placeComponents[addressType]
                  ];
                that.addressObject[that.autoCompleteElements[addressType]] =
                  value;
              }
            }
            this.arrowkeyUserLocation = -1;

            if (
              this.addressObject.city &&
              this.addressObject.state &&
              this.addressObject.country
            ) {
              this.currentLocation = details.formatted_address;
              this.displayHeaderSearchLocation = `${this.addressObject.city}, ${this.addressObject.state}, ${this.addressObject.country}`;

              localStorage.setItem(
                'chat-my-order:lat',
                details.geometry.location.lat()
              );
              localStorage.setItem(
                'chat-my-order:lng',
                details.geometry.location.lng()
              );
              localStorage.setItem(
                'chat-my-order:currentLocation',
                this.currentLocation
              );
              localStorage.setItem('chat-my-order:street', details.name);
              localStorage.setItem(
                'chat-my-order:city',
                this.addressObject.city
              );
              localStorage.setItem(
                'chat-my-order:state',
                this.addressObject.state
              );
              localStorage.setItem(
                'chat-my-order:zipcode',
                this.addressObject.zipcode
              );
              localStorage.setItem(
                'chat-my-order:country',
                this.addressObject.country
              );
              this.cdr.detectChanges();

              window.location.reload();
            }
          }
        );
      } else {
        this.currentLocation = place?.address_line_2
          ? `${place.street}, ${place.address_line_2}, ${place.city}, ${place.state} ${place.zipcode}`
          : `${place.street}, ${place.city}, ${place.state} ${place.zipcode}`;
        this.displayHeaderSearchLocation = `${place.city}, ${place.state}, ${place.country}`;

        localStorage.setItem('chat-my-order:lat', place.latitude);
        localStorage.setItem('chat-my-order:lng', place.longitude);
        localStorage.setItem(
          'chat-my-order:currentLocation',
          this.currentLocation
        );
        localStorage.setItem('chat-my-order:street', place.street);
        localStorage.setItem('chat-my-order:city', place.city);
        localStorage.setItem('chat-my-order:state', place.state);
        localStorage.setItem('chat-my-order:zipcode', place.zipcode);
        localStorage.setItem('chat-my-order:country', place.country);
        this.cdr.detectChanges();

        window.location.reload();
      }
    }
  }

  public onClickCurrentLocation() {
    navigator.permissions.query({ name: 'geolocation' }).then((status: any) => {
      if (status.state == 'denied') {
        this.toastr.info(this.constant.LOCATION_DENIED, this.constant.OOPS);
      } else {
        if (navigator?.geolocation) {
          navigator?.geolocation?.getCurrentPosition((potition: any) => {
            this.getGeoLocation(
              potition.coords.latitude,
              potition.coords.longitude
            );
          });
        }
      }
    });
  }

  public onSearchLocation(event: any) {
    if (
      localStorage.getItem('chat-my-order:lat') &&
      localStorage.getItem('chat-my-order:lng')
    ) {
      if (event.length >= 3) {
        this.filterList = [];
        if (!this.currentLocation) {
          const model = {
            query: event,
            latitude: localStorage.getItem('chat-my-order:lat'),
            longitude: localStorage.getItem('chat-my-order:lng'),
            option: ['STORES','MENUS'],
          };
          this.getFilterByLocation(model);
        } else {
          const model = {
            query: event,
            latitude: localStorage.getItem('chat-my-order:lat'),
            longitude: localStorage.getItem('chat-my-order:lng'),
            option: ['STORES','MENUS'],
          };
          this.getFilterByLocation(model);
        }
      } else if (!event.length) {
        this.filterList = [];
      }
    }
  }

  public selectPlace(data: any) {
    this.arrowkeyLocation = -1;
    if (data?.sku) {
      this.searchLocationValue = data.name;
      this.searchOnEnter();
    } else {
      const storeName = this.helperService.convertToSlug(data.name);
      window.location.replace(`/restaurant/${storeName}/${data._id}`);
    }
  }

  public markAsReadNotification(notificationId: string): void {
    this.cmoApiService.markAsReadNotification(notificationId).then(
      (res: any) => {

        this.toastr.success(res.message, this.constant.SUCCESS);
        this.getAllNotifications();
        this.messageService.sendMessage('Header-Notification');
      },
      (error: any) => {
        const errMsg = this.helperService.getResponseErrorMessage(error);
        if (errMsg?.length) {
          this.toastr.error(errMsg, this.constant.ERROR);
        }
      }
    );
  }


  public deleteNotification(notificationId: string): void {
    this.cmoApiService.deleteNotification(notificationId).then(
      (res: any) => {
        this.toastr.success(res.message, this.constant.SUCCESS);
        this.messageService.sendMessage('Header-Notification');
        this.getAllNotifications();
      },
      (error: any) => {
        const errMsg = this.helperService.getResponseErrorMessage(error);
        if (errMsg?.length) {
          this.toastr.error(errMsg, this.constant.ERROR);
        }
      }
    );
  }

  public onClickNotificationDropdown() {
    if (this.notifications?.length) {
      this.cmoApiService.readAllNotifications().then(
        (res: any) => {
          // this.toastr.success(this.constant.NOTIFICATION_ALL_MARK ,this.constant.SUCCESS);
          setTimeout(() => {
            this.getAllNotifications();
          }, 10000);
        },
        (error: any) => {
          const errMsg = this.helperService.getResponseErrorMessage(error);
          if (errMsg?.length) {
            this.toastr.error(errMsg, this.constant.ERROR);
          }
        }
      );
    }
  }

  public getOrderVariantToppingDetail(orderData: any, name: string) {
    return this.helperService.getOrderVariantToppingDetail(orderData, name);
  }

  public confirmDeleteCartItem(id: string) {
    console.log('remove cart', this.cartItemId);
    this.singleCartItemId = id;
    // this.cmoApiService.deleteNormalCartItem(this.cartOrderList.store_id, id).then((res: any) => {
    //   this.cartOrderList =  res.data;
    //   this.helperService.setNormalCartList(res.data);
    //   this.messageService.sendMessage('Header');
    //   this.toastr.success(res.message ,this.constant.SUCCESS);

    // }, (error: any) => {
    //   const errMsg = this.helperService.getResponseErrorMessage(error);
    //   if (errMsg?.length) {
    //     this.toastr.error(errMsg, this.constant.ERROR);
    //   }
    // })
  }

  public deleteCartItem() {
    console.log('cofirm');
    this.cmoApiService
      .deleteNormalCartItem(this.cartOrderList.store_id, this.cartItemId)
      .then(
        (res: any) => {
          this.cartOrderList = res.data;
          this.helperService.setNormalCartList(res.data);
          this.messageService.sendMessage('Header');
          this.toastr.success(res.message, this.constant.SUCCESS);
        },
        (error: any) => {
          const errMsg = this.helperService.getResponseErrorMessage(error);
          if (errMsg?.length) {
            this.toastr.error(errMsg, this.constant.ERROR);
          }
        }
      );
  }


  public deleteCartItemV2() {
    console.log('cofirm V2');
    this.cmoApiService
      .deleteNormalCartItem(this.cartOrderList.store_id, this.singleCartItemId)
      .then(
        (res: any) => {
          this.cartOrderList = res.data;
          this.singleCartItemId = '';
          if (res.data.items.length == 0) {
            this.clearNormalCart();
          } else {
            this.helperService.setNormalCartList(res.data);
          }
          this.messageService.sendMessage('Header');
          this.toastr.success(res.message, this.constant.SUCCESS);
        },
        (error: any) => {
          this.singleCartItemId = '';
          const errMsg = this.helperService.getResponseErrorMessage(error);
          if (errMsg?.length) {
            this.toastr.error(errMsg, this.constant.ERROR);
          }
        }
      );
  }

  public onClickAddRemoveCartItem2(cartId: string, method: string) {
    this.cartAddRemoveLoading = true;

    if (method === 'add') {
      this.cmoApiService
        .repeatNormalCartItem(this.cartOrderList.store_id, cartId)
        .then(
          (res: any) => {
            this.cartAddRemoveLoading = false;
            this.cartOrderList = res.data;
            this.helperService.setNormalCartList(res.data);
            this.messageService.sendMessage('Header');
            this.toastr.success(res.message, this.constant.SUCCESS);
          },
          (error: any) => {
            this.cartAddRemoveLoading = false;
            const errMsg = this.helperService.getResponseErrorMessage(error);
            if (errMsg?.length) {
              this.toastr.error(errMsg, this.constant.ERROR);
            }
          }
        );
    } else {
      this.cmoApiService
        .removeNormalCartItem(this.cartOrderList.store_id, cartId)
        .then(
          (res: any) => {
            this.cartAddRemoveLoading = false;
            this.cartOrderList = res.data;
            this.helperService.setNormalCartList(res.data);
            this.messageService.sendMessage('Header');
            this.toastr.success(res.message, this.constant.SUCCESS);
            if (res.data.items.length == 0) {
              this.clearNormalCart();
            }
          },
          (error: any) => {
            this.cartAddRemoveLoading = false;
            const errMsg = this.helperService.getResponseErrorMessage(error);
            if (errMsg?.length) {
              this.toastr.error(errMsg, this.constant.ERROR);
            }
          }
        );
    }
  }

  public removeDiscountOnCartItem() {
    if (!this.isInGroup) {
      // for normal order
      this.cmoApiService.removeDiscountOnCart().then(
        (res: any) => {
          this.cartOrderList = res.data;
          this.helperService.setNormalCartList(res.data);
          this.messageService.sendMessage('Header');
          this.toastr.success(res.message, this.constant.SUCCESS);
        },
        (error: any) => {
          const errMsg = this.helperService.getResponseErrorMessage(error);
          if (errMsg?.length) {
            this.toastr.error(errMsg, this.constant.ERROR);
          }
        }
      );
    } else {
      // for group order
      this.cmoApiService
        .removeGroupOrderDiscount(this.groupCartList.group_id)
        .then(
          (res: any) => {
            this.groupCartList = res.data;
            this.helperService.setGroupCartList(res.data);
            this.messageService.sendMessage('Header');
            this.toastr.success(res.message, this.constant.SUCCESS);
          },
          (error: any) => {
            const errMsg = this.helperService.getResponseErrorMessage(error);
            if (errMsg?.length) {
              this.toastr.error(errMsg, this.constant.ERROR);
            }
          }
        );
    }
  }


  public removeDiscountOnCartItemOninit() {
    this.cmoApiService.removeDiscountOnCart().then(
      (res: any) => {
        this.cartOrderList = res.data;
        this.helperService.setNormalCartList(res.data);
        this.messageService.sendMessage('Checkout');
        //this.toastr.success(res.message, this.constant.SUCCESS);

      },
      (error: any) => {
        const errMsg = this.helperService.getResponseErrorMessage(error);
        if (errMsg?.length) {
          //   this.toastr.error(errMsg, this.constant.ERROR);
        }
      }
    );
  }


  public removeCouponOnCartOnInit() {
    this.cmoApiService.removeCouponCode().then(
      (res: any) => {
        this.cartOrderList = res.data;
        this.helperService.setNormalCartList(res.data);
        this.messageService.sendMessage('Checkout');
        //this.toastr.success(res.message, this.constant.SUCCESS);

      },
      (error: any) => {
        const errMsg = this.helperService.getResponseErrorMessage(error);
        if (errMsg?.length) {
          // this.toastr.error(errMsg, this.constant.ERROR);
        }
      }
    );
  }


  public openItemAddUpdateModal(
    menuItem: any,
    isGroup: boolean,
    guestCart?: any
  ) {
    console.log('menuItem2',menuItem)
    if (
      (!isGroup && this.cartOrderList?.store_details?.store_status == 'OPEN') ||
      (isGroup && this.groupCartList?.store_details?.store_status == 'OPEN')
    ) {
      const obj = {
        parent_link_id: menuItem?.parent_link_id,
      };
      console.log('menuitem', menuItem);
      const storeId = isGroup
        ? this.groupCartList?.store_id
        : this.cartOrderList?.store_id;
      const itemId = menuItem.deliverect_product_id
        ? menuItem.deliverect_product_id
        : menuItem.item_id;
        console.log('cartItem.sub_products2-------------------', menuItem.sub_products),
        console.log('storeId-------------------',storeId ),
      this.cmoApiService
        .getAddedNormalCartItemDetails(storeId, itemId, obj)
        .then(
          (res: any) => {
            console.log('response', res.data);
            this.simpleModalService.addModal(ItemAddUpdatePopUpComponent, {
              data: {
                cartItem: menuItem,
                menuData: res.data,
                isGroup: false,
                menuId: menuItem.parent_link_id,
                _id: menuItem._id,
                storeId : storeId,
                category_id : menuItem.category_id

              },
              mode: 'edit',
            });
          },
          (error: any) => {
            const errMsg = this.helperService.getResponseErrorMessage(error);
            if (errMsg?.length) {
              this.toastr.error(errMsg, this.constant.ERROR);
            }
          }
        );
    } else {
      this.toastr.error(this.constant.RESTAURANT_CLOSE, this.constant.ERROR);
    }
  }

  public openItemAddUpdateModalForGroup( menuItem: any,guestCart: any) {
    if (this.groupCartList?.store_details?.store_status == 'OPEN') {
      const obj = {
        parent_link_id: menuItem?.parent_link_id,
      };
      const storeId =  this.groupCartList?.store_id
      ;
      const itemId = menuItem.deliverect_product_id
      ? menuItem.deliverect_product_id
      : menuItem.item_id;
      this.cmoApiService.getAddedNormalCartItemDetails(this.groupCartList.store_id, itemId, obj).then((res: any) => {
        this.simpleModalService.addModal(ItemAddUpdatePopUpComponent,
          {
            data: {
              menuData: res.data,
              cartItem: menuItem,
              guestCart,
              isGroup: true,
              menuId: menuItem.parent_link_id,
              _id: menuItem._id,
              storeId : storeId,
              category_id : menuItem.category_id
            },
            mode: 'edit',
          },
        );

      }, (error: any) => {
        const errMsg = this.helperService.getResponseErrorMessage(error);
        if (errMsg?.length) {
          this.toastr.error(errMsg, this.constant.ERROR);
        }
      });

    } else {
      this.toastr.error(this.constant.RESTAURANT_CLOSE, this.constant.ERROR);
    }
  }

  public clearNormalCart() {
    this.cmoApiService.clearNormalCartItems().then(
      (res: any) => {
        console.log('clear cart', res.data);
        if (Object.keys(res.data).length === 0) {


          this.cartOrderList = [];
          this.helperService.setNormalCartList([]);
          this.toastr.success(res.message, this.constant.SUCCESS);
          this.messageService.sendMessage('Clear');
          this.router.navigate(['/home']);
        }
      },
      (error: any) => {
        const errMsg = this.helperService.getResponseErrorMessage(error);
        if (errMsg?.length) {
          this.toastr.error(errMsg, this.constant.ERROR);
        }
      }
    );
    setTimeout(() => {
      this.removeDiscountOnCartItemOninit()
      this.removeCouponOnCartOnInit()
    }, 100);
  }

  public goToRestuarantPage() {
    const storeName = this.helperService.convertToSlug(
      this.cartOrderList.store_details.name
    );
    this.helperService.refreshComponent(
      '/restaurant/' + storeName + '/' + this.cartOrderList.store_details._id
    );
  }

  public getGroupCartTotalItem() {
   // console.log('cartOrderList',this.cartOrderList)
    if (Object.keys(this.groupCartList).length > 0) {
      const filterCart = this.groupCartList.carts.filter(
        (list: any) => list.customer_id === this.loggedInUserId
      );
      let i = 0
     if(filterCart[0]?.customer_id == this.groupCartList?.customer_id)
        {this.groupCartList.carts.forEach((cart:any) => i = i + cart.items.length)
          return i
      }else{
         return filterCart[0]?.items?.length;
      }
    }
   
  }

  // move Group Order
  public getGroupOrderTime(time: any) {
    return moment(time).format('MMM DD, hh:mm a');
  }

  public checkFriendSubmitOrder() {
    if (this.groupCartList.carts) {
      const filterCart = this.groupCartList.carts.filter(
        (list: any) => list.customer_id === this.loggedInUserId
      );
      if (filterCart[0].submission_time === null) return false;
      else return true;
    }
  }

  public submitGroupOrder() {
    const obj = {
      type: this.groupCartList.group_order_type,
    };

    const filterCart = this.groupCartList.carts.filter(
      (list: any) => list.customer_id === this.loggedInUserId
    );
    this.cmoApiService
      .confirmGroupOrderItemByFriend(
        this.groupCartList.group_id,
        filterCart[0].id,
        obj
      )
      .then(
        (res: any) => {
          this.toastr.success(res.message, this.constant.SUCCESS);
          this.groupCartList = res.data;
          this.helperService.setGroupCartList(res.data);
        },
        (error: any) => {
          const errMsg = this.helperService.getResponseErrorMessage(error);
          if (errMsg?.length) {
            this.toastr.error(errMsg, this.constant.ERROR);
          }
        }
      );
  }

  public openViewGroupOrderModal() {
    this.simpleModalService.addModal(ViewGroupOrderComponent, {
      data: this.groupCartList,
    });
  }

  public onClickAddRemoveGroupCartItem(
    cartId: string,
    cartItemId: string,
    method: string
  ) {
    this.cartAddRemoveLoading = true;

    if (method === 'add') {
      this.cmoApiService
        .repeatGroupOrderItem(this.groupCartList.group_id, cartId, cartItemId)
        .then(
          (res: any) => {
            this.cartAddRemoveLoading = false;
            this.groupCartList = res.data;
            this.helperService.setGroupCartList(res.data);
            this.messageService.sendMessage('Header');
            this.toastr.success(res.message, this.constant.SUCCESS);
          },
          (error: any) => {
            this.cartAddRemoveLoading = false;
            const errMsg = this.helperService.getResponseErrorMessage(error);
            if (errMsg?.length) {
              this.toastr.error(errMsg, this.constant.ERROR);
            }
            if (error.status == 406) {
              setTimeout(() => {
                window.location.reload();
              }, 1500);
            }
          }
        );
    } else {
      this.cmoApiService
        .removeGroupOrderItem(this.groupCartList.group_id, cartId, cartItemId)
        .then(
          (res: any) => {
            this.cartAddRemoveLoading = false;
            this.groupCartList = res.data;
            this.helperService.setGroupCartList(res.data);
            this.messageService.sendMessage('Header');
            this.toastr.success(res.message, this.constant.SUCCESS);
          },
          (error: any) => {
            this.cartAddRemoveLoading = false;
            const errMsg = this.helperService.getResponseErrorMessage(error);
            if (errMsg?.length) {
              this.toastr.error(errMsg, this.constant.ERROR);
            }
            if (error.status == 406) {
              setTimeout(() => {
                window.location.reload();
              }, 1500);
            }
          }
        );
    }
  }

  public confirmDeleteGroupCartItem(cartId: string, cartItemid: string) {
    this.cartId = cartId;
    this.cartItemId = cartItemid;
  }

  public deleteGroupCartItem() {
    this.cmoApiService
      .deleteGroupOrderItem(
        this.groupCartList.group_id,
        this.cartId,
        this.cartItemId
      )
      .then(
        (res: any) => {
          this.groupCartList = res.data;
          this.helperService.setGroupCartList(res.data);
          this.messageService.sendMessage('Header');
          this.toastr.success(res.message, this.constant.SUCCESS);
        },
        (error: any) => {
          const errMsg = this.helperService.getResponseErrorMessage(error);
          if (errMsg?.length) {
            this.toastr.error(errMsg, this.constant.ERROR);
          }
        }
      );
  }

  public confirmDeleteGroupCartFriend(cart: any) {
    this.customerCart = cart;
  }

  public deleteGroupCartFriend() {
    this.cmoApiService
      .deleteGroupMemberByHost(
        this.groupCartList.group_id,
        this.customerCart?.customer_id
      )
      .then(
        (res: any) => {
          this.groupCartList = res.data;
          this.helperService.setGroupCartList(res.data);
          this.messageService.sendMessage('Header');

          if (this.loggedInUserId == this.groupCartList?.customer_id) {
            this.toastr.success(res.message, this.constant.SUCCESS);
          } else {
            this.toastr.success(res.message, this.constant.SUCCESS);
          }
        },
        (error: any) => {
          const errMsg = this.helperService.getResponseErrorMessage(error);
          if (errMsg?.length) {
            this.toastr.error(errMsg, this.constant.ERROR);
          }
        }
      );
  }

  public leaveGroupOrder() {
    const storeName = this.groupCartList.store_details.name;
    const storeId = this.groupCartList.store_details._id;

    this.cmoApiService.leaveGroupOrder(this.groupCartList.group_id).then(
      (res: any) => {
        this.toastr.success(res.message, this.constant.SUCCESS);
        this.helperService.setGroupCartList(res.data);
        setTimeout(() => {
          this.localStorageSwitchGroupOrderData = null;
          localStorage.removeItem('switch_group_order');
          localStorage.removeItem('cmo:group_order_status');
          this.messageService.sendMessage('Header');
          window.location.replace(
            '/restaurant/' +
            this.helperService.convertToSlug(storeName) +
            '/' +
            storeId
          );
        }, 1000);
      },
      (error: any) => {
        const errMsg = this.helperService.getResponseErrorMessage(error);
        if (errMsg?.length) {
          this.toastr.error(errMsg, this.constant.ERROR);
        }
      }
    );
  }

  public checkAllFriendsOrderSubmission() {
    this.groupCartList.carts.forEach((cart: any) => {
      if (localStorage.getItem('cmo:id') !== cart.customer_id) {
        if (cart.submission_time === null) this.isSubmission = true;
        else this.isSubmission = false;
      }
    });

    if (this.isSubmission === true || this.isSubmission === undefined)
      return true;
    else return false;
  }

  public searchOnEnter() {
    if (this.arrowkeyLocation > -1 && this.arrowkeyLocation < 5) {
      this.selectPlace(this.filterList[this.arrowkeyLocation]);
    } else {
      // this.filterList = [];
      this.arrowkeyLocation = -1;

      let options: any[] = [];
      if (this.filterList?.length) {
      //  options = this.filterList[0]?.sku ? [] : ['STORES'];
        options = this.filterList[0]?.sku ? ['MENUS'] : ['STORES']
      }

      if (this.searchLocationValue) {
        this.router.navigate(['search'], {
          queryParams: { searchText: this.searchLocationValue, options },
        });
      }
    }
  }

  public keyDown(event: KeyboardEvent) {
    switch (event.keyCode) {
      case 38: // this is the ascii of arrow up
        this.arrowkeyLocation--;
        break;
      case 40: // this is the ascii of arrow down
        this.arrowkeyLocation++;
        break;
    }
  }

  public searchOnLocationEnter() {
    if (this.arrowkeyUserLocation == 0) {
      // for current location
      this.onClickCurrentLocation();
    } else if (this.arrowkeyUserLocation > 0 && this.arrowkeyUserLocation < 6) {
      // for list location
      if (this.placesList[this.arrowkeyUserLocation]) {
        this.selectLocation(this.placesList[this.arrowkeyUserLocation - 1]);
      }
    } else {
      this.arrowkeyUserLocation = -1;
    }
  }

  public locationkeyDown(event: KeyboardEvent) {
    switch (event.keyCode) {
      case 38: // this is the ascii of arrow up
        this.arrowkeyUserLocation--;
        break;
      case 40: // this is the ascii of arrow down
        this.arrowkeyUserLocation++;
        break;
    }
  }

  public goToCheckout() {
    if (this.localStorageSwitchGroupOrderData)
      this.router.navigate(['checkout', 'group']);
    else this.router.navigate(['checkout', 'normal']);
  }

  @HostListener('window:scroll', [])
  public handleScroll(): void {
    this.isSticky = window.pageYOffset <= 300;
  }

  public switchToOtherCart(type: string) {
    const url = window.location.pathname;

    if (url.includes('checkout')) {
      if (type == 'group') window.location.replace('/checkout/group');
      else window.location.replace('/checkout/normal');
    } else if (url.includes('restaurant')) {
      const lastIndexOfUrl = url.lastIndexOf('/group');
      const urlSubStr =
        lastIndexOfUrl > -1 ? url.substr(0, lastIndexOfUrl) : url;

      if (type == 'group') {
        localStorage.setItem('switch_group_order', `${urlSubStr}/group`);
        window.location.replace(`${urlSubStr}/group`);
      } else {
        localStorage.removeItem('switch_group_order');
        window.location.replace(urlSubStr);
      }
    } else {
      if (type == 'group') {
        const storeName = this.helperService.convertToSlug(
          this.groupCartList?.store_details?.name
        );
        localStorage.setItem(
          'switch_group_order',
          `/restaurant/${storeName}/${this.groupCartList?.store_details?._id}/group`
        );
        window.location.replace(
          `/restaurant/${storeName}/${this.groupCartList?.store_details?._id}/group`
        );
      } else {
        localStorage.removeItem('switch_group_order');
        window.location.replace(url);
      }
    }
  }

  public getCurrentUserCartLength() {
    if (this.groupCartList?.carts?.length) {
      const findCart = this.groupCartList?.carts.find((list: any) => {
        return list.customer_id == localStorage.getItem('cmo:id');
      });
      if (findCart && findCart?.items?.length > 0) return true;
      else false;
    }
  }

  public getCurrentUserOrderStatus() {
    if (this.groupCartList?.carts?.length) {
      const findCart = this.groupCartList?.carts.find((list: any) => {
        return list.customer_id == localStorage.getItem('cmo:id');
      });
      if (findCart && findCart?.items?.length > 0 && findCart?.submission_time)
        return true;
      else false;
    }
  }

  public onClickFulfillmentType(type: string) {
    if (this.helperService.getFulfillmentType() != type) {
      this.helperService.setFulfillmentType(type);
      window.location.reload();
    }
  }

  public switchToCart() {
    if (!this.isInGroup) {
      const storeName = this.helperService.convertToSlug(
        this.groupCartList.store_details.name
      );
      localStorage.setItem(
        'switch_group_order',
        `/restaurant/${storeName}/${this.groupCartList.store_id}/group`
      );
      window.location.replace(
        '/restaurant/' +
        storeName +
        '/' +
        this.groupCartList.store_id +
        '/group'
      );
    } else {
      // const storeName = this.helperService.convertToSlug(this.restaurantDetails.name);
      localStorage.removeItem('switch_group_order');
      // window.location.replace('/restaurant/' + storeName + '/' + this.restaurantDetails._id);
    }
  }

  public checkCurrentPageURL() {
    const url = window.location.pathname;
    if (url.includes('/home') || url.includes('/restaurant/')) return true;
    return false;
  }

  public getRestaurantIdOnDetailsPage() {
    if (window.location.pathname.includes('/restaurant/')) {
      this.restaurantId = this.route.snapshot.params.id;
    }
  }

  public getGuestSubtotal() {
    let amount: any = null;
    if (this.groupCartList?.carts?.length > 0) {
      for (let cart of this.groupCartList?.carts) {
        if (
          cart.customer_id == this.loggedInUserId &&
          cart.customer_id != this.groupCartList?.customer_id
        ) {
          amount = cart.sub_total;
        }
      }
    }

    return amount;
  }

  public goToDetailsPage(store: any) {
    const storeName = this.helperService.convertToSlug(store.name);
    if (
      !window.location.pathname.includes(
        `/restaurant/${storeName}/${store._id}`
      )
    ) {
      this.router.navigate(['restaurant', storeName, store._id, 'group']);
    }
  }

  public navigateToNotification() {
    this.router.navigate(['account', 'notifications']);
    $('#notificationListButton').dropdown('toggle');
  }

  public onClickAddRemoveCartItem(
    deliverect_product_id: string,
    method: string
  ) {
    this.cartAddRemoveLoading = true;

    if (method == 'add') {
      this.cmoApiService
        .repeatNormalCartItem(this.storeDetails._id, deliverect_product_id)
        .then(
          (res: any) => {
            this.cartAddRemoveLoading = false;
            this.cartOrderList = res.data;
            this.helperService.setNormalCartList(res.data);
            this.messageService.sendMessage('Checkout');
            this.toastr.success(res.message, this.constant.SUCCESS);
          },
          (error: any) => {
            this.cartAddRemoveLoading = false;
            const errMsg = this.helperService.getResponseErrorMessage(error);
            if (errMsg?.length) {
              this.toastr.error(errMsg, this.constant.ERROR);
            }
            if (error.status == 406) {
              setTimeout(() => {
                window.location.reload();
              }, 1500);
            }
          }
        );
    } else {
      this.cmoApiService
        .removeNormalCartItem(
          this.cartOrderList.store_id,
          deliverect_product_id
        )
        .then(
          (res: any) => {
            this.cartAddRemoveLoading = false;
            this.cartOrderList = res.data;
            this.helperService.setNormalCartList(res.data);
            this.messageService.sendMessage('Checkout');
            this.toastr.success(res.message, this.constant.SUCCESS);
          },
          (error: any) => {
            this.cartAddRemoveLoading = false;
            const errMsg = this.helperService.getResponseErrorMessage(error);
            if (errMsg?.length) {
              this.toastr.error(errMsg, this.constant.ERROR);
            }
            if (error.status == 406) {
              setTimeout(() => {
                window.location.reload();
              }, 1500);
            }
          }
        );
    }
  }

  //#endregion
}
