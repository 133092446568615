import { Component, OnInit } from '@angular/core';
import { HelperService } from 'src/app/services/helper.service';
import { CmoapiService } from 'src/app/services/cmoWebsiteApi.service';
import { ToastrService } from 'ngx-toastr';
import { SimpleModalComponent } from 'ngx-simple-modal';
import { ItemAddUpdateModel } from 'src/app/models/general';
import { FormBuilder, FormGroup, FormArray } from '@angular/forms';
import { ConstantProviderService } from 'src/app/providers/constant-provider.service';

@Component({
  selector: 'app-invite-friend',
  templateUrl: './invite-friend.component.html',
  styleUrls: ['./invite-friend.component.css']
})

export class InviteFriendComponent extends SimpleModalComponent<ItemAddUpdateModel, null> implements ItemAddUpdateModel, OnInit {

  //#region variables

  public data: any;
  public groupOrderForm: any;
  
  public mode: string;

  public invitationList: any = [];

  //#endregion

  //#region life cycle hook

  constructor(
    private helperService: HelperService,
    private cmoApiService: CmoapiService,
    private toastr: ToastrService,
    private fb: FormBuilder,
    public constant: ConstantProviderService,
  ) {
    super();
  }
  
  ngOnInit(): void {
    this.invite();
  }

  //#endregion

  //#region private methods

  private invite() {
    this.groupOrderForm = this.fb.group({
      invites: this.fb.array([this.createInvitations()]) ,
    });
    this.invitationList = this.groupOrderForm.get('invites') as FormArray;
  }

  private createInvitations(): FormGroup {
    return this.fb.group({
      email: null,
      mobile: null,
    });
  }

  //#endregion

  //#region public methods

  public addInvitation(ind: number) {
 
    if (ind < 19 ) {
      this.invitationList.push(this.createInvitations());
    }
    else{
      this.toastr.error('You have already reached 20 guests limit !', this.constant.ERROR);
    }
  }

  public removeInvitation(i: number) {
    this.invitationList.removeAt(i);
    if (!this.invitationList?.length) {
      this.invitationList.push(this.createInvitations());
    }
  }

  get getInvitesControls() {
    return this.groupOrderForm.get('invites') as FormArray;
  }

  public skipInvitation() {
    this.close();
    this.toastr.success(this.constant.GROUP_CREATE_SUCCESS, this.constant.SUCCESS);
    setTimeout(() => {
      this.getRedirectStoreDetailsLink();
    }, 2000);
  }

  public submitInviteFriend() {
    // if (this.getInvitesControls.value?.length) {
    //   for (let i = 0; i < this.getInvitesControls.value?.length; i++) {
    //     this.getInvitesControls.value[i].email = this.getInvitesControls.value[i]?.email?.trim()?.length ? this.getInvitesControls.value[i].email : null;
    //     this.getInvitesControls.value[i].mobile = this.getInvitesControls.value[i]?.mobile?.trim()?.length ? this.getInvitesControls.value[i].mobile : null;
    //   }
    // }
    
    this.cmoApiService.inviteGroupOrder(this.data.group.group_id, this.getInvitesControls.value).then((res: any) => {
      this.close();
      this.toastr.success(res.message, this.constant.SUCCESS);
      setTimeout(() => {
        this.getRedirectStoreDetailsLink();
      }, 2000);

    }, (error: any) => {
      const errMsg = this.helperService.getResponseErrorMessage(error);
      if (errMsg?.length) {
        this.toastr.error(errMsg, this.constant.ERROR);
      }
    });
  }

  public getRedirectStoreDetailsLink() {
    const storeName = this.helperService.convertToSlug(this.data.store_name);
    this.close();
    localStorage.setItem('switch_group_order', `/restaurant/${storeName}/${this.data.group?.store_id}/group`);
    window.location.replace('/restaurant/' + storeName + '/' + this.data.group.store_id + '/group');
  }

  //#endregion
}
