import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root',
})
/**
 * Provides Validation RegEx Patterns
 */
export class ConstantProviderService {
  public EMAIL_PATTERN = `[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+[.][a-zA-Z]{2,3}$`;
  public ZIP_CODE_PATTERN = '^[0-9]{5}$';
  public FAX_PATTERN = '^[0-9]{10}$';
  public NAME_PATTERN = '^[A-Za-z]{2,}( [a-zA-Z]+)?(-[a-zA-Z]+)?$';
  public DECIMAL_PATTERN = '^[0-9]+(\.[0-9]+)?$';
  public TAX_PATTERN = '^[0-9]{1,2}[.]([0-9]{1,3})$';
  public MILES = '^[0-9]+(\.[0-9]+)?$'
  public NUMBER_PATTERN = '^[0-9]*$';
  public PASSWORD_PATTERN = '^((?=.*[0-9])(?=.*[A-Z])(?=.*[a-z]).{8,})$';

  //#region Global Message

  public ARE_YOU_SURE = 'Are you sure you want to';

  public ARE_YOU_SURE_CLEAR_CART = 'Are you sure you want to clear your cart?';
  public ARE_YOU_SURE_DELETE_ACCOUNT = 'Are you sure you want to delete this account?';
  
  public ERROR = 'Error';
  
  public FAILED = 'Failed';

  public HURRY = 'Hurry!!!';
  
  public OOPS = 'Oops!!!';
  
  public SUCCESS = 'Success';
  public SELECT_RESTAURANT_FIRST = 'Select The Restaurant First';
  public SEARCH_BY_ALL = 'Search By All';
  public SEARCH_BY_NAME = 'Search By Name';
  public SORRY = 'Sorry';
  public RESTAURANT_FAVORITE = 'Favorites';
  public RESTAURANT_NOTIFICATION = 'Notifications';
  public RESTAURANT_MY_ORDERS = 'Orders';
  public RESTAURANT_Notification = 'Notifications';

  //#endregion

  //#region Tooltips Messaga

  public APPLY_TOOLTIP = 'Apply';
  public ADD_TOOLTIP = 'Add';
  public ADD_ADDRESS = 'Add Address';
  public ADD_PAYMENT_METHOD = 'Add Payment Method';
  public ADD_CARD = 'Add Card';
  public ADD_ADDRESS_TO_PLACE_ORDER = 'Please change or add address to place order.';
  public ALREADY_SUBMITTED_ORDER = 'You are already submitted your order.';
  public ADD_TO_CART = 'Add to Cart'
  public ALL = 'All';
  public APPLY_NOW = 'Apply Now';
  
  public BACK = 'Back';

  public CLOSE_TOOLTIP = 'Close';
  public CANCEL_TOOLTIP = 'Cancel';
  public CLEAR = 'Clear';
  public CALL_ON = 'Call on';
  public CONTACT_US = 'Contact Us';
  public CLEAR_CART = 'Clear Cart';
  public CHECKOUT = 'Review Order & Checkout';
  public CREATE = 'Create';
  public CHANGE = 'Change';
  public CONNECT_WITH_FB = 'Connect with Facebook';
  public CONNECT_WITH_GOOGLE = 'Connect with Google';
  public CONNECT_WITH_APPLE = 'Connect with Apple';
  public COMPLETED = 'Completed';
  public CANCELLED = 'Canceled';
  public CHANGE_PASSWORD = 'Change Password';
  public CHAT_WITH_US = 'Chat with Restaurant';
  public CLOSE_MENU_PREVIEW = 'Close Menu Preview';
  public CHAT_MY_ORDER = 'CHAT MY ORDER';
  
  public DELETE_TOOLTIP = 'Delete';
  public DECREASE_QTY = 'Decrease Quantity';
  public DONE = 'Done';
  public DELETE_ALL = 'Delete All';
  public DISCOUNT = 'Discounts';
  public APPLY_DISCOUNT_NOTE = 'If any discount has been applied to the order, a partial refund will not be allowed.';
  
  public EDIT_TOOLTIP = 'Edit';
  public EMAIL = 'Email Address';
  public EDIT_PAYMENT_METHOD = 'Edit Payment Method';

  public INSTGARAM = 'Instagram';
  public FACEBOOK = 'Facebook';
  public FULFILLMENT_TYPES = 'Fulfillment Types';
  public FILTERS = 'Filters';

  public GO = 'Go';
  public GO_BACK = 'Go Back';
  public GROUP_ORDER_JOINED = 'Your guest was joined in the group order';
  public GROUP_ORDER_LEAVED = 'Your guest was left from the group order';
  public GROUP_ORDER_SUBMITTED = 'Your guest was submitted their the group order';
  public GROUP_ORDER_ACTIONS = 'This cart has been updated';
  
  public HOME = 'Home';
  public HELP = 'Help';

  public INCREASE_QTY = 'Increase Quantity';
  public IN_PROGRESS = 'In Progress';
  public ITEMS = 'Items';
  public INFO = 'Info';
  public INACTIVE_ITEMS = 'One or more of your items is no longer available. Please review your cart and remove the highlighted items.';
  public INACTIVE_ITEM_REMOVE = 'This item is no longer available. Please remove it to continue with your order.';
  
  public JOIN_GROUP_ORDER = 'Join Group Order';

  public LEAVE = 'Leave';
  public LEAVE_GROUP_ORDER = 'Leave Group Order';
  public LOCATION_DENIED = 'You denied location access previously';
  
  public MAIL_TO = 'Mail to';
  public MARK_AS_READ = 'Mark As Read';
  public MARK_AS_FAV = 'Mark As Favorite';
  public MENU_GALLERY = 'Menu Gallery';
  
  public NO_THANKS = 'No, Thanks';
  public NO_SERVICEABLE_ZIPCODE = 'No Serviceable Zipcode Found';

  public OFFICE_ADDRESS_MAP = 'NextGen Office Address Map';
  public ORDER_ONLINE = 'Order Online';
  public OUT_OF_STOCK = 'Unavailable';
  public UNAVAILABLE='unavailable';
  public OTHER = 'Menu/Diet Preferences';
  public ORDER_DETAILS = 'Order Details';
  
  public PAY = 'Pay';
  public PRIVACY_POLICY = 'Privacy Policy';
  public GUIDELINES = ' Guidelines For Restaurants';
  public CODE_OF_CONDUCT = 'Code Of Conduct';
  public REFUND_AND_CANCELLATION_POLICY = 'Refund & Cancellation Policy';
  public PRINT = 'Print';
  public PRINT_RECEIPT = 'Print Receipt';
  
  public RESET_TOOLTIP = 'Reset';
  public RESTAURANT_CLOSED = 'Restaurant Closed';
  public RESTAURANTS = 'Restaurants';
  public REPEAT_LAST = 'REPEAT LAST';
  public REMOVE = 'Remove';
  public REMOVE_FAV = 'Remove Favorite';
  public REFUND_REQUEST = 'Refund Request';
  public REQUEST_A_REFUND = 'Request a Refund';
  public RE_ORDER = 'REORDER';
  public READ_ALL = 'Read All';
  public RE_SEND = 'Re-Send';
  public RE_SEND_TEXT = 're-send the text';
  public RESTAURANT_GALLERY = 'Restaurant Gallery';
  public READ_MORE = 'Read More';
  public REFUNDED = 'Refunded';
  public ROUND_OFF = 'Due to tax rounding, you may find your total transaction amount is different by 1 penny.'

  public SAVE_TOOLTIP = 'Save';
  public SUBMIT_TOOLTIP = 'Submit';
  public SUBMIT_REQUEST = 'Submit Request';
  public SAVE_COMMENT = 'Save Comment';
  public SUBMIT_COMMENT = 'Submit Comment';
  public SEND = 'Send';
  public SWITCH_GROUP = 'Switch Group';
  public SWITCH_NORMAL = 'Switch Normal';
  public STOP_TO_TAKE_ORDER = 'Currently we are stop to taking new orders';
  public SELECT_ONE_PAYMENT_METHOD = 'Select one of the payment method';
  public SEARCH = 'Search';
  public SHOW_MORE = 'Show More';
  public SAVE_CHANGES = 'Save Changes';
  public SELECT_ADDRESS = 'Please Select Address';
  public SHARE_LINK = 'Share Link';
  public SEE_MENU = 'See Menu';
  public SELECT_ALL = 'Select All';
  public SHARE = 'Share';
  public START_GROUP_ORDER = 'Start New Group Order';
  public SWITCH = 'Switch';
  public SWITCH_TO_INDIVIDUAL_ORDER_MODE = ' to Individual Order';
  public SWITCH_TO_GROUP_ORDER_MODE = 'Switch to Group Order';
  public SHARE_INVITE_LINK = 'Share Invite Link';
  public STORE_STATUS = 'Restaurant Status';
  
  public TERMS_CONDITIONS = 'Terms and Conditions';
  public TWITTER = 'Twitter';
  public TRACK = 'Track My Order';
  public TAGS = 'Cuisines';
  public BROWSE_CUSINIE = 'Browse Cuisines';
  
  public UPDATE_TOOLTIP = 'Update';
  public UPDATE_CART = 'Update Cart';
  public URL_COPY_LINK = 'Copy Invite Link';
  public UNSUB_NOTIFICATION = 'Unsubscribe Notifications';
  public SUB_NOTIFICATION = 'Subscribe Notifications';
  public UPDATE_ADDRESS = 'Update Address';
  public UPDATE_PAYMENT = 'Update Payment Method';
  
  public VIEW_DETAILS = 'View Details';
  public VIEW_ORDER= 'View Order';
  public VIEW_ALL = 'View All';
  public VIEW_LESS = 'View Less';
  public VIEW_ALL_NOTIFICATION = 'View All Notifications';
  public VERIFY_NOW = 'Verify Now';
  public VIEW_GROUP_ORDER = 'View Group Order';
  public VIEW_MORE = 'View More';
  
  public WAIT_TILL_SUBMIT_ORDER = 'Wait till all guests are submitted their orders';
  public WAIT_TILL_ITEMS = 'Items yet to add to the cart'
  public WILL_CHOOSE = `I'LL CHOOSE`;
  public WHATSAPP = 'Whatsapp';
  public WEBSITE = 'Website';

  public YES_SURE = 'Yes, Sure';
  public YES_CANCEL_ORDER = 'Yes, Cancel the order';
  public YES_CHANGE_PICKUP = 'Yes, Change to Pick Up';

  //#endregion
  
  //#region Auth Message

  public APP_LINK_MSG= 'App link sent successfully';
  public ACTIVE_WO_TIMEOUT = 'It keeping your session active without a timeout.';

  public BACK_SIGN_IN = 'Back to Sign In';

  public CODE_COPY = 'Code copied successfully';
  
  public EMAIL_EXISTS = 'Email Already Exists';
  
  public FORGOT_PASSWORD_LINK_RESEND_SUCCESS = 'Forgot password reset link sent successfully';
  public FORGOT_PASSWORD_LINK_RESEND_FAILED = 'Forgot password reset link sent failed';
  public FORGOT_PASSWORD = 'Forgot Password';
  public FUTURE_LOGIN = 'Your email will be saved on this browser for easier future logins.';

  public GET_APP_EMAIL = 'Please enter email address to receive a link';
  public GET_APP_MOBILE = 'Please enter phone number to receive a link';
  
  public INVALID = 'Invalid credential';

  public LINK_COPY = 'Link copied successfully';

  public RESET_PASSWORD = 'Password reset successfully';
  public RESEND_LINK = 'Resend Link'

  public SIGN_IN = 'Sign In';
  public SIGN_IN_SUCCESS = 'Sign In successful ';
  public SIGN_UP = 'Register';
  public SIGN_OUT = 'Sign Out';
  public SIGN_UP_FAILED = 'Account created failed';
  public SIGN_UP_SUCCESS = 'Account created successfully';
  public SIGN_OUT_SUCCESS = 'Sign Out successfully';
 
  //#endregion

  //#region Profile

  public EMAIL_VERIFICATION = 'Email verification link sent successfully';

  public OTP_VERIFIED = 'Code verified successfully';
  public OTP_RESEND = 'OTP resend successfully';
  public OTP_DIGIT = 'OTP digit is missing';

  public PROFILE_NOT_FETCH = 'User profile can not fetch';
  public PROFILE_UPDATE = 'Profile updated successfully';
  public PASSWORD_CHANGE = 'Password changed successfully';
  public PROFILE_PIC_UPLOADED = 'Profile picture uploaded successfully';
  public IMAGE_VALIDATION = 'Image type must be PNG, JPG or JPEG';

  //#endregion

  //#region Validation Message

  public AMOUNT_REQUIRED = 'Discount Amount is required';
  public ALL_MANDATORY_FIELD = 'Please enter all mandatory fields before saving changes';
  public ADDRESS_LABEL = 'Address Label is required';
  
  public BASED_ON_DISTANCE = 'Based on distance, an additional delivery fee will apply';

  public CONFIRM_PASSWORD = 'Confirm Password is required';
  public CONFIRM_PASSWORD_NOT_MATCH = 'Confirm Password do not match';
  public CITY_NAME = 'City is required';
  public COUNTRY_NAME = 'Country is required';
  public CURRENT_PASSWORD = 'Current Password is required';
  public CAPTCHA_MESSAGE = `Your computer or network may be sending automated queries. To protect our users we can't process your request right now.`;
  
  public DATE_OF_BIRTH = 'Date of Birth is required';
  public DATE_OF_BIRTH_LENGTH = 'Not allowed future date or long past dates';
  
  public EMAIL_REQUIRED = 'Email Address is required';
  public EMAIL_EMPTY = 'Email Address is not allowed to be empty';
  public EMAIL_REQUIRED_PATTERN = 'Email Address is not valid';
  public END_DATE = 'End Date is required';
  public EXPIRE_MONTH = 'Expire Month is required';
  public EXPIRE_YEAR = 'Expire Year is required';

  public FULFILLMENT_REQUIRED = 'Fulfillment Types are required';
  public FIRST_NAME = 'First Name is required';
  public FIRST_NAME_PATTERN = 'First Name is not valid';
  public FUTURE_DATE = 'Future or long past dates are not allowed';
  
  public INVALID_COUPON_CODE = 'Invalid coupon code';

  public LAST_NAME = 'Last Name is required';
  public LAST_NAME_PATTERN = 'Last Name is not valid';
  
  public MESSAGE_REQUIRED = 'Message is required';
  public MESSAGE_EMPTY = 'Message is not allowed to be empty';

  public NEW_PASSWORD = 'New Password is required';
  public NEW_PASSWORD_LENGTH = 'New Password length must be at least 6 characters long';
  public NEW_PASSWORD_LENGTH_CHECK = 'New password can not be same as current password';
  public NEW_PASSWORD_REQUIRED_PATTERN = 'New Password must be at least 8 characters and contain at least 1 lowercase letter, 1 uppercase letter, 1 number';
  public NAME = 'Name is required';
  public NAME_EMPTY = 'Name is not allowed to be empty';
  public NAME_STRING_PATTERN = 'must be 2-30 characters, Only 1 space or 1 hyphen allowed, Cannot start/end with a space or hyphen';
  
  public PASSWORD_REQUIRED = 'Password is required';
  public PASSWORD_NOT_VALID = 'Password is not valid';
  public PASSWORD_REQUIRED_LENGTH = 'Password should contains from 6 to 15 characters';
  public PASSWORD_REQUIRED_PATTERN = 'Password must be at least 8 characters and contain at least 1 lowercase letter, 1 uppercase letter, 1 number';
  public PHONE_REQUIRED = 'Phone Number is required';
  public PHONE_REQUIRED_LENGTH = 'Please enter a valid 10 digit phone number';
  public PAYMENT_METHOD_INCOMPLETE = 'Your payment method is incomplete';
  
  public QTY_MAX_LIMIT = 'The maximum item quantity for this particular item is 25 per item';

  public RESTAURANT_CLOSE = 'Restaurant is currently closed';
  public REFUND_NOT_ELIGIBLE = 'Refund is not eligible for this order';
  public REPLAY_BLANK = 'Reply content cannot be left blank';
  public RESPONSE_REQUIRED = 'Your Response is required';
  public REFUND_REQ_REASON = 'Refund Request Reason is Required';
  
  public STREET_NAME = 'Address Line 1 is required';
  public STATE_NAME = 'State is required';
  public START_DATE = 'Start Date is required';
  public SUBJECT_REQUIRED = 'Subject is required';
  public STORE_CLOSED_INFO = 'Sorry, the restaurant is currently closed. You may still view the menu to plan for a future order.';
  public STORE_CUT_OFF_TIME_INFO = 'Sorry, the restaurant is currently stop to taking new orders. You may still view the menu to plan for a future order.';
  public SUBJECT_EMPTY = 'Subject is not allowed to be empty';
  
  public TITLE_REQUIRED = 'Title is required';
  public TOPPING_REQUIRED = 'Topping is compulsory';

  public YOU_HAVE_NOT_NOTIFICATION = `You haven't any notification`;

  public ZIPCODE_NO = 'Zipcode is required';
  public ZIPCODE_NO_PATTER = 'Zipcode is not valid';

  //#endregion

  //#region Favorites Page
  
  public FAVORITES_ADD = 'Favorite restaurant added successfully';
  public FAVORITES_REMOVE = 'Favorite restaurant removed successfully';

  //#endregion

  //#region Item List Message

  public ITEM_FIRST = 'Please add item to cart to apply discount';
  public ITEM_ADD = 'Item added successfully';
  public ITEM_REMOVE = 'Item removed successfully';
  public ITEM_UPDATE = 'Item updated successfully';
  public ITEM_HEALTHY = 'This restaurant has healthy menu items.'; 

  
  public LOGIN_MESSAGE = 'Please sign in to keep continue your further activities';
  
  public MULTIPLE_ITEM_ADD = 'This item has multiple customizations added. Remove the correct item from the cart.';
  
  public TABLE_ORDER_ACTIVE = 'Currently your table order is activated';
  
  public VARIANT_SELECT = 'Please select variant from the above';

  //#endregion

  //#region Discount

  public DISCOUNT_APPLID = 'Discount applied successfully';
  public DISCOUNT_REMOVE = 'Discount removed successfully';

  public GROUP_ADMIN = 'Only group admin can perform this action';
  public GROUP_FRIEND_REMOVE = 'Group guest removed successfully';
  public GROUP_LEFT = 'Group left successfully';

  //#endregion

  //#region cart

  public CART_ITEM_REMOVE = 'Cart item removed successfully';
  public CART_CLEAR = 'Cart cleared successfully';

  //#endregion

  //#region Coupon

  public COUPON_APPLIED = 'Coupon applied successfully';
  public COUPON_REMOVE = 'Coupon removed successfully';
  public COUPON_ORDER_MOBILE = 'Please verify your phone number before placing an order';

  //#endregion

  //#region Order

  public ORDER_PLACED = 'Order placed successfully';
  public GROUP_ORDER_PLACED = 'The group owner has placed the order successfully';
  public GROUP_ORDER_CANCELED_MESSAGE = 'The group owner has canceled the order';
  public GROUP_ORDER_CANCELED = 'Group Order Canceled';
  public ORDER_SUBMIT = 'Order submitted successfully';
  public ORDER_UPDATE = 'Order updated successfully';
  public ORDER_REPEAT = 'Order repeated successfully';
  public ORDER_CENCELL = 'Order canceled successfully'
  public ORDER_REFUND = 'Refund Request submitted successfully';
  public ORDER_RATING = 'Ratings submitted successfully';
  public ORDER_UPDATE_RATING = 'Ratings updated successfully';
  public COMMENT_REQUIRED = 'Comment is Required';
  public CONFIRMED_ORDER = 'Accept new delivery time successfully';
  public ORDER_TYPE_UPDATED = 'Order Type Updated successfully';

  //#endregion

  //#region Checkout

  public ADD_DELIVERY_TIP = 'Add delivery tip amount';
  public APPLY_DELIVERY_TIP = 'Thank you for supporting our drivers';

  public CHECKOUT_EMPTY = 'Your cart is empty add items for checkout';
  public CHECKOUT_DELIVERING = 'We are delivering to your location';
  public CHECKOUT_DELIVERING_CURRENTLY = 'Delivery is not available for the selected address. Please use another address.';
  public CHECKOUT_SELECT_ADDRESS = 'Please select address.'
  public CARD_ADDED = 'Card created successfully';
  public CARD_ADD = 'Add Card';
  public CARD_DELETED = 'Card deleted successfully';

  public DELIVERY_TIP_AMOUNT_EXCEED = `Delivery tip can't exceed 200`;
  public NO_RIDER_AVAILABLE = 'No nearby riders are available';

  public REMOVE_DELIVERY_TIP = 'Delivery tip removed successfully';

  //#endregion

  //#region Notifiaction

  public NOTIFICATION_MARK = 'Notification mark as read successfully';
  public NOTIFICATION_DELETE = 'Notification deleted successfully';
  public NOTIFICATION_ERROR = 'Error is update notification';
  public NOTIFICATION_ERROR_DELETE = 'Error in deleteing notification'; 
  public NOTIFICATION_ALL_MARK = 'All notifications mark as read successfully';
  public NOTIFICATION_ALL_READ = 'All notifications are already marked as read';
  public NOTIFICATION_PUSH = 'Push notifications unsubscribed successfully';
  public NOTIFICATION_PUSH_SUBS = 'Push notifications subscribed successfully';
  public NOTIFICATION_ALL_DELETE = 'All notifications deleted successfully';
 
  //#endregion

  //#region Address

  public ADDRESS_DELETE = 'Address deleted successfully';
  public ADDRESS_ADD = 'Address added successfully';
  public ADDRESS_UPDATED = ' Address updated successfully';

  public ADDRESS_SPECIAL_INSTRUCTION_TEXT = 'Include instructions how to access the building, the PIN number to a gate, or any other instructions to help your delivery driver get to you.'
  public EDIT_ADDRESS = 'Edit Address';

  //#endregion

  //#region offers Message

  public COPY_CODE = 'COPY CODE';

  public PAYMENT_OFFERS = 'Special Offers';

  public RESTAURANT_OFFERS = 'Local Discounts';

  //#endregion

  //#region Home page
  
  public RESTAURANT_NEARBY = 'Nearby Restaurants';
  public RESTAURANT_NEARBY_VIEW = 'View All Nearby'
  public RESTAURANT_MOST_POPULAR = 'Most Popular Restaurants';
  public RESTAURANT_TRENDING_WEEK = 'Trending This Week';

  //endregin

  //#region contact us

  public NEW_TICKET = 'New Support Request';
  public TICKET_NOT_CREATED = 'Unable to create ticket';
  public TICKET_CREATED_SUCCESS = 'Ticket created successfully'
  public TICKET_RETRIVED_SUCCESS = 'Ticket retrived successfully';

  //#endregion


  //#region tips

  public SUPPORT_TIP_TITLE = 'SUPPORT YOUR DELIVERY PARTNER';
  public SUPPORT_TIP_DES = 'Please Select a Tip Amount';

  //#endregion

  //#region footer Links

  public CUSTOMER_LOGIN = "Customer Login";
  public CUSTOMER_SIGN_UP ="Customer Sign Up";
  public HELP_CENTER = "Help Center";
  public OUR_BLOG = "Our Blog";
  public CONTACTUS =" Contact Us";
  public RIDER_LOGIN ="Rider Login";
  public BECOME_A_DELIVERY_RIDER = "Become A Delivery Rider";
  public RIDER_RESIURCES ="Rider Resources";
  public MERCHANT_LOGIN ="Merchant Login";
  public BECOME_A_NEW_MERCHANT ="Become A New Merchant";
  public MERCHANT_RESOURCES ="Merchant Resources";

  //#endregion


  //#region Configs

  public GALLERY_CONFIG = {
    slidesToShow: 3,
    slidesToScroll: 3,
    arrows: true,
    autoplay: true,
    autoplaySpeed: 2000,
    responsive: [
      {
        breakpoint: 768,
        settings: {
          arrows: true,
          centerMode: true,
          centerPadding: '40px',
          slidesToShow: 3
        },
      },
      {
        breakpoint: 480,
        settings: {
          arrows: true,
          centerMode: true,
          centerPadding: '40px',
          slidesToShow: 1
        },
      },
    ],
  };

  public TAGS_CONFIG = {
    slidesToShow: 8,
    slidesToScroll: 8,
    arrows: true,
    infinite: true,  
    responsive: [
      {
        breakpoint: 768,
        settings: {
          arrows: false,
          centerMode: true,
          centerPadding: '40px',
          slidesToShow: 3,
          slidesToScroll: 3,
          swipe: true,
          autoplay: true,
        },
      },
      {
        breakpoint: 480,
        settings: {
          arrows: true,
          centerMode: true,
          centerPadding: '40px',
          slidesToShow: 2,
          slidesToScroll: 2,
          swipe: true,
          autoplay: true,
        },
      },
    ],
  };

  public OTP_CONFIG = {
    allowNumbersOnly: true,
    length: 4,
    isPasswordInput: false,
    disableAutoFocus: true,
    placeholder: '',
    inputStyles: {
      'text-align': 'center',   
      'margin-top': '10px',
    },
  };

  //#endregion

  //#region shared

  public ADDRESS_POP_UP_ADDED = 'Address added successfully';
  public ADDRESS_POP_UP_UPDATED = 'Address updated successfully';

  public CANCEL_GROUP_ORDER = 'Cancel Group Order';

  public CARD_POP_UP_ADDED = 'Payment Method added successfully';
  public CARD_POP_UP_UPDATED = 'Payment Method updated successfully';
  public CARD_POP_UP_DELETED = 'Payment Method deleted successfully';

  public DO_NOT_CANCEL = `No, Don't Cancel`;

  public INVITE_FRIEND = 'Enter your guests information to share or Skip by clicking on submit';
  public INVITE_SUCCESS = 'Invitation sent successfully';
  public INVITE_REACHED_GROUP_LIMIT = 'You have already reached 20 guests limit';

  public NO_MORE_DECREASE = 'No More Decrease or Delete Item';

  public ORDER_UPDATED = 'Order updated successfully';

  public GROUP_ORDER_UPDATED = 'Group order details updated successfully';
  public GROUP_CREATE_SUCCESS = 'Group order created successfully';

  public YES_CANCEL = 'Yes, Cancel';
  public YES_START_REFRESH = 'Clear Cart - Start New Order';

  //#endregion

  //#region group order

  public GROUP_ORDER = {
    SAVED_ITEM: 'Your group order items have been saved',
    REVIEW_ORDER: 'Review Order',
    START_NEW_ORDER: 'Start New Group Order',
    OWNER_NOTE: 'i.e. when you plan to submit the order, where everyone is meeting and what time, how to contact you, etc.',
    NOTE_VALIDATION: 'Guest Note must be a valid note',
    FINALIZE_AND_SEND_ORDER_TO_HOST: 'Finalize and Send Order to Host',
    SUBMIT_ORDER_TEXT: 'You can still modify your order before the order is placed.',
    NO_ITEMS: 'You are not added any item yet.',
    ADD_MORE_ITEM: 'Add More Items',
    MODIFY_MY_ORDER: 'Modify My Order',
    MODIFY_ORDER_TEXT: 'If you modify your order, you must re-save It before the Host can checkout.',
    ORDER_SUBMIT: 'Items submitted and send to Host successfully',
    GUEST_ITEM_SAVED: 'Your group order items have been saved',
    GUEST_ITEM_SAVED_SUB_TEXT: 'Waiting for the Host to complete the order.',
    GUEST_LEFT_SIDE_TEXT_1: `You've been invited to a group order.`,
    GUEST_LEFT_SIDE_TEXT_2: 'Any items you add into this order will be sent to the Host and paid for by the Host.',
    GUEST_LEFT_SIDE_TEXT_3: 'All reimbursements to the Host (if necessary) can be handled directly.',
    GUEST_LEFT_SIDE_TEXT_4: 'Please coordinate with the Host regarding the order.',
    CHANGE_TO_PICKUP: 'Change Address/Pickup',
    CHANGE_TO_DELIVERY: 'Switch to Delivery',
    SEND_INVITATION: 'Send Invitations',
    LEFT_GROUP_ORDER: 'You have left the group order',
    SKIP: 'Skip'
  }

  //#endregion

  //#region Images Link

  public PLACE_HOLDER_IMAGE = 'https://chatmyorder.blob.core.windows.net/cmo/store/logo/place-placeholder.jpg';

  //#endregion

  //#region array 

  public ORDER_MODE_LIST = [ 'cart_order', 'chat_order', 'pos_order', 'table_order','deliverect_order' ];

  public STORE_STATUS_LIST = [
    {
      value: 'OPEN',
      label: 'Show Open Only',
    },
  ];

  public SORT_LIST = [
    {
      value: 'default',
      label: 'Default (Nearest)',
    },
    {
      value: 'RATING',
      label: 'Rating',
    },
    {
      value: 'PRICE',
      label: 'Price Level',
    },
  ];

  public REFUND_REASON = [
    `I haven't recevied this order`,
    'Items are missing from my order',
    'Items are different from what I ordered',
    'I have packaging or spillage issue with this order',
    'I have recevied bad quality food',
    'The quantity of food is not adequate',
  ];

  public TICKET_SUBJECT_LIST = [
    'Serious Allergic Reaction',
    'Serious Food Safety Issue',
    'Violence and/or other Criminal Activity',
    'Other',
  ];

  public SIDEBAR_OPTIONS = [
  
    {
      link: '/account/orders',
      icon: 'feather-box mr-2',
      text: 'My Orders',
      in_desktop: true,
      in_responsive: true
    },
    {
      link: '/account/address',
      icon: 'feather-book mr-2',
      text: 'My Addresses',
      in_desktop: true,
      in_responsive: true
    },
    {
      link: '/account/payment-methods',
      icon: 'feather-credit-card mr-2',
      text: 'My Payment Methods',
      in_desktop: true,
      in_responsive: true
    },
    {
      link: '/account/chat-history',
      icon: 'feather-message-circle mr-2',
      text: 'My Restaurant Chats',
      in_desktop: true,
      in_responsive: true
    },   
    {
      link: '/account/ticket-list',
      icon: 'feather-help-circle mr-2',
      text: 'My Support Tickets',
      in_desktop: true,
      in_responsive: true
    },
    {
      link: '/account/delete-user',
      icon: 'fa fa-trash mr-2 text-danger ',
      text: 'Delete Account',
      in_desktop: true,
      in_responsive: true

    },
    {
      link: '/favorites',
      icon: 'feather-heart mr-2',
      text: 'My Favorite Restaurants',
      in_desktop: false,
      in_responsive: true
    },
    // {
    //   link: '/account/notifications',
    //   icon: 'feather-bell mr-2',
    //   text: 'My Notifications',
    //   in_desktop: false,
    //   in_responsive: true
    // }
  ];

  public PRICE_LEVEL = [
    {
      label: 'Items mostly under $10',
      value: 1,
    },
    {
      label: 'Items mostly between $10-$20',
      value: 2,
    },
    {
      label: 'Items mostly between $20-$30',
      value: 3,
    },
    {
      label: 'Items mostly above $30',
      value: 4,
    },
  ];

  public ORDER_TRACKING_MESSAGES = [
    {
      type: 'Delivery',
      statuses: [
        {
          label: 'We are waiting for the restaurant to accept the order.<br><br>We will notify you once the order has been accepted.',
          value: 'Received',
        },
        {
          label: 'Your order is accepted by restaurant.',        
          value: 'Accepted',
        },
        {
          label: 'Your order is accepted by restaurant.',        
          value: 'Action Required',
        },
        {
          label: 'Your order is being prepared.',
          value: 'Being Prepared',
        },
        {
          label: 'Your order has been prepared and is waiting to be picked up by your delivery driver.',
          value: 'Ready For Pickup',
        },
        {
          label: 'Your order has been picked up and your driver is on the way to you.<br><br>Please have your delivery code ready from below so you can receive the order..',
          value: 'On The Way',
        },
        {
          label: '',
          value: 'Delivered'
        },
        {
          label: '',
          value: 'Canceled'
        },
        {
          label: '',
          value: 'Refund Requested'
        },
        {
          label: '',
          value: 'Refunded'
        },
        {
          label: '',
          value: 'Refund Rejected'
        },
        {
          label: 'Your order was discarded, as it was not clamied within 1 hour of it being ready for pickup.<br><br>This is done for safety reasons.<br><br>Thank you for your understanding.',
          value: 'Unclaimed'
        }
      ]
    },
    {
      type: 'Pickup',
      statuses: [
        {
          label: 'We are waiting for the restaurant to accept the order.<br><br>We will notify you once the order has been accepted.',
          value: 'Received',
        },
        {
          label: 'Your order is accepted by restaurant.',        
          value: 'Accepted',
        },
        {
          label: 'Your order is accepted by restaurant.',        
          value: 'Action Required',
        },
        {
          label: 'Your order is being prepared.',
          value: 'Being Prepared',
        },
        {
          label: 'Your order has been prepared and is waiting to be picked up.',
          value: 'Ready For Pickup',
        },
        {
          label: '',
          value: 'Picked Up'
        },
        {
          label: '',
          value: 'Canceled'
        },
        {
          label: '',
          value: 'Refund Requested'
        },
        {
          label: '',
          value: 'Refunded'
        },
        {
          label: '',
          value: 'Refund Rejected'
        },
        {
          label: 'Your order was discarded, as it was not clamied within 1 hour of it being ready for pickup.<br><br>This is done for safety reasons.<br><br>Thank you for your understanding.',
          value: 'Unclaimed'
        }
      ]
    }
  ];

  public ORDER_STATUS_TITLE_MESSAGES = [
    {
      type: 'Delivery',
      statuses: [
        {
          label: 'Waiting for restaurant to accept.',
          value: 'Received',
        },
        {
          label: 'Your order is accepted by restaurant.',        
          value: 'Accepted',
        },
        {
          label: 'Your order is being prepared.',
          value: 'Being Prepared',
        },
        {
          label: 'Food ready. Driver picking up.',
          value: 'Ready For Pickup',
        },
        {
          label: 'Your order is on the way.',
          value: 'On The Way',
        }
      ],
    },
    {
      type: 'Pickup',
      statuses: [
        {
          label: 'Waiting for restaurant to accept.',
          value: 'Received',
        },
        {
          label: 'Your order is accepted by restaurant.',        
          value: 'Accepted',
        },
        {
          label: 'Your order is being prepared.',
          value: 'Being Prepared',
        },
        {
          label: 'Your order is ready for pickup.',
          value: 'Ready For Pickup',
        },
      ],
    }
  ];

  public ORDER_STATUS_LIST = [
    {
      type: 'Pickup',
      is_eta_overDue: false,
      statuses: [ 'Received', 'Accepted', 'Being Prepared', 'Ready For Pickup', 'Delivered' ]
    },
    {
      type: 'Pickup',
      is_eta_overDue: true,
      statuses: [ 'Received', 'Accepted', 'Confirmed', 'Being Prepared', 'Ready For Pickup', 'Delivered' ]
    },
    {
      type: 'Delivery',
      is_eta_overDue: false,
      statuses: [ 'Received', 'Accepted', 'Being Prepared', 'Ready For Pickup', 'On The Way', 'Delivered' ]
    },
    {
      type: 'Delivery',
      is_eta_overDue: true,
      statuses: [ 'Received', 'Accepted', 'Confirmed', 'Being Prepared', 'Ready For Pickup', 'On The Way', 'Delivered' ]
    },
  ];

  public TIPS = [15, 20, 25, 30];

  public ADMIN_TIMEZONE_ID = 'America/New_York';

  public DEVICE = 'web';
  public ROLE_TYPE = 'customer';
  public REFRESH_TOKEN_INTERVAL = 2700 * 1000; // 45 min

  public ORDER_TYPES = {
    DELIVERY: 'Delivery',
    PICKUP: 'Pickup'
  }

  public ORDER_STATUSES =  {
    UNCLAIMED: 'Unclaimed',
    READY_FOR_PICKUP: 'Ready For Pickup',
    DELIVERED: 'Delivered',
    PICKEDUP: 'Picked Up'
}

  //#endregion

  constructor() { }

}