import { Injectable, Injector } from '@angular/core';
import {
  HttpRequest,
  HttpHandler,
  HttpEvent,
  HttpInterceptor,
} from '@angular/common/http';
import { Observable } from 'rxjs';
import { catchError, finalize, map } from 'rxjs/operators';
import { throwError } from 'rxjs';

import { ToastrService } from 'ngx-toastr';
import { HelperService } from '../services/helper.service';
import { ConstantProviderService } from '../providers/constant-provider.service';

@Injectable()
export class InterceptedHttp implements HttpInterceptor {
    constructor(
        private inject: Injector,
        private helper: HelperService,
        private toastr: ToastrService,
        private constant: ConstantProviderService,
    ) {

    }

  intercept(request: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
    const auth = this.inject.get(HelperService);
    let cloned: any;
    
    if (auth.getToken()) {
      //TODO: Support ticket must be created if User is not logged in.
      if (request.url.includes('support.chatmyorder.com')) {
        cloned = request.clone({
          setHeaders: {
            'Authorization': `Basic Q61ENLRMDN2GTO4AS86HVS9QJYW7NUJEWNXGURGPUTPKBOSNBNADZLP300AXZMBH`
          }
        });
      } else {
        cloned = request.clone({
          setHeaders: {
            Authorization: `Bearer ${auth.getToken()}`
          }
        });
      }
      return next.handle(cloned).pipe(
        map(event => {
          return event;
        }),
        catchError(err => {
          if (err.status == 401) {
            this.toastr.info(this.constant.LOGIN_MESSAGE, this.constant.SORRY);
            this.helper.clearLocalStorage();
          }
          return throwError(err);
        }),
        finalize(() => { })
      );
    } else {
      return next.handle(request).pipe(
        map(event => {
          return event;
        }),
        catchError(err => {
          if (err.status === 401) {
            this.toastr.info(this.constant.LOGIN_MESSAGE, this.constant.SORRY);
            this.helper.clearLocalStorage();
          }
          return throwError(err);
        }),
        finalize(() => { })
      );
    }
  }
}
