<div class="py-5 p-4 osahan-coming-soon d-flex justify-content-center align-items-center">
    <div class="col-md-6">
        <div class="text-center pb-3">
            <h1 class="font-weight-bold">Sorry, Your order has been failed.</h1>
            <p>This order failed our store.please  <a href="my_order.html" class="font-weight-bold text-decoration-none text-primary"></a> contact us more details .</p>
        </div>
        <!-- continue -->
        <div class="bg-white rounded text-center p-4 shadow-sm">
            <h1 class="display-1 mb-4"><img src="assets/img/extra/fail.png" style="height: 15vh;"></h1>
            <h6 class="font-weight-bold mb-2">Thank You</h6>
            <!-- <p class="small text-muted">Your order will be prepared and will come soon</p> -->
            <a href="status_onprocess.html" class="btn rounded btn-primary ">Try Again </a>
            <a href="status_onprocess.html" class="btn btn-outline-primary  px-3">Home Page </a>
        </div>
    </div>
</div>
