import { Component, OnInit } from '@angular/core';
import { HelperService } from 'src/app/services/helper.service';
import { CmoapiService } from 'src/app/services/cmoWebsiteApi.service';
import { ToastrService } from 'ngx-toastr';
import { MessageService } from 'src/app/services/message.service';
import { SimpleModalComponent } from 'ngx-simple-modal';
import { ItemAddUpdateModel } from 'src/app/models/general';
import { Router } from '@angular/router';
import { ConstantProviderService } from 'src/app/providers/constant-provider.service';

@Component({
  selector: 'app-item-add-update-pop-up',
  templateUrl: './item-add-update-pop-up.component.html',
  styleUrls: ['./item-add-update-pop-up.component.css'],
})
export class ItemAddUpdatePopUpComponent
  extends SimpleModalComponent<ItemAddUpdateModel, null>
  implements ItemAddUpdateModel, OnInit
{
  //#region variables
  public isResturantActive:boolean
  public data: any;
  public selectedVariantTotal: any;
  public selectedToppingTotal: any;
  public cartTotalPrice: any = 0;
  public toppingMinSelection: any;
  public preSelected = true
  public mode: string;
  public soldOutAction: string =
  this.helperService.SOLD_OUT_OPTION_LIST[0].title;
  public menuId: string;
  public categoryId: string;
  public storeName: string;
  public storeId: string;
  public isButtonDisabled:boolean = true
  public itemList: any = [];
  public selectedVariant: any = [];
  public selectedTopping: any = [];
  public filterCartArray: any = [];
  public cartList: any = [];
  public shouldSubDisabled: boolean = false 
  public soldOutList = this.helperService.SOLD_OUT_OPTION_LIST;
 
  public itemQuantity = 1;

  public isItemAdd = false;
  public deliverect_category_id:any
  public _id:any
  public selectMainOption: any = [];
  public selectedMainOptionIds: any = [];

  public selectedMainVarientIds: any = [];

  public selectedSubOption: any = [];
  public selectedSubOptionIds: any = [];

  public isAddToCart: boolean = true;
  public updateItemId: any;
  public newCart: any;
  public itemQuantities: [] = [];
  public fullOrderRepeteQuantity: number = 1;
  public loading = false;
  public cartItem: any;
  //#endregion

  //#region life cycle hook

  constructor(
    private helperService: HelperService,
    private cmoApiService: CmoapiService,
    private toastr: ToastrService,
    private messageService: MessageService,
    private router: Router,
    public constant: ConstantProviderService
  ) {
    super();
  }

  ngOnInit(): void {
    this.storeId = this.data.storeId;
    this.itemList = this.data.menuData;
    this.isResturantActive = this.data.isResturantActive
    // console.log('itemList', this.itemList);
    this.cartTotalPrice = this.itemList.price;
    this.cartItem = this.data.cartItem;
    // console.log('categoryyyyyyyyyyyyyyyyyyyy',  this.data)
    this.deliverect_category_id = this.data.deliverect_category_id
   this._id = this.data._id
   if(this.deliverect_category_id && this.deliverect_category_id != null && this.deliverect_category_id != ''){
     this.categoryId = this.deliverect_category_id
   }else{
     this.categoryId = this._id
   }
  //  console.log('categoryId', this.categoryId);
    this.storeName = this.data.storeName;
   
    this.updateItemId = this.data._id;
    // console.log('storeId', this.storeId);
    if(!this.itemList?.sub_products_nested){
      this.itemList.sub_products_nested = []
    }
    if(this.itemList?.sub_products_nested?.length == 0){
      this.isButtonDisabled  = false
    }

    this.cartTotal();
    this.CheckIfMinSatisfied()
   
   
    if (this.mode == 'edit') {
      this.checkShouldSubDisabled(true)
      // this.itemList.sub_products_nested = this.itemList.sub_products
      this.itemList.quantity = this.cartItem.quantity;

      for (let productTitle of this.itemList.sub_products_nested) {
        for (let product of productTitle?.sub_products_nested) {
          product.preSelected = true;

          //if store is deliverect
          if(product.deliverect_product_id){
            const selectedProduct = this.cartItem.sub_products.filter(
              (sp: any) =>
                sp.deliverect_product_id == product.deliverect_product_id
            );

            if (selectedProduct?.length > 0) {
              product.is_default = true;
              product.quantity = selectedProduct[0]?.quantity;
              this.onChangeOption2(true, product,productTitle)
              for (let subProductTitle of product?.sub_products_nested) {
                for (let subProduct of subProductTitle?.sub_products_nested) {
                  const selectedSubProduct =
                    selectedProduct[0]?.sub_products?.filter(
                      (sp: any) =>
                        sp.deliverect_product_id ==
                        subProduct.deliverect_product_id
                    );
  
                  if (selectedSubProduct?.length > 0) {
                    subProduct.is_default = true;
                    subProduct.quantity = selectedSubProduct[0]?.quantity;
                    this.onChangeSubOption2(true, subProduct,product._id)
                  } else {
                    subProduct.is_default = false;
                  }
                }
              }
            } else{
              product.is_default = false;
            }
          }
          
          //if store is non deliverect
          else{
            const selectedProduct = this.cartItem.sub_products.filter(
              (sp: any) =>
                sp._id == product._id
            );
            if (selectedProduct?.length > 0) {
              product.is_default = true;
              product.quantity = selectedProduct[0]?.quantity;
              this.onChangeOption2(true, product,productTitle)
              for (let subProductTitle of product?.sub_products_nested) {
                for (let subProduct of subProductTitle?.sub_products_nested) {
                  const selectedSubProduct =
                    selectedProduct[0]?.sub_products?.filter(
                      (sp: any) =>
                        sp._id ==
                        subProduct._id
                    );
                  if (selectedSubProduct?.length > 0) {
                    subProduct.is_default = true;
                    subProduct.quantity = selectedSubProduct[0]?.quantity;
                    this.onChangeSubOption2(true, subProduct,product._id)
                  } else {
                    subProduct.is_default = false;
                  }
                }
              }
            } else{
              product.is_default = false;
            }
          }
        }
      }
     this.categoryId = this.data.category_id
      // console.log('issue?-------------------', this.selectMainOption);
      this.fullOrderRepeteQuantity = this.itemList.quantity;
      this.cartTotal()
    }

  

    // if (this.mode == 'add') {
    //   if (this.itemList.hasOwnProperty('variants') && this.itemList.hasOwnProperty('toppings')) { // for default variants
    //     this.toppingMinSelection = this.itemList.toppings?.min_selections;
    //     this.itemList.variants.forEach((variant: any) => {
    //       variant.options.forEach((vOptions: any) => {
    //         if (vOptions.is_default && vOptions.is_active) this.onChangeVariant(vOptions, variant);
    //       });
    //     });
    //     this.cartTotal();

    //   } else if (this.itemList.hasOwnProperty('variants') && !this.itemList.hasOwnProperty('toppings')) {
    //     this.itemList.variants.forEach((variant: any) => {
    //       variant.options.forEach((vOptions: any) => {
    //         if (vOptions.is_default && vOptions.is_active) this.onChangeVariant(vOptions, variant);
    //       });
    //     });
    //     this.cartTotal();

    //   } else if (!this.itemList.hasOwnProperty('variants') && this.itemList.hasOwnProperty('toppings')) {
    //     this.toppingMinSelection = this.itemList.toppings.min_selections;
    //     this.cartTotal();

    //   } else if (!this.itemList.hasOwnProperty('variants') && !this.itemList.hasOwnProperty('toppings')) {
    //     this.cartTotal();
    //   }

    // } else {

    //   // for group order
    //   if (this.data.isGroup) {
    //     const cartArray = this.data.guestCart;
    //     const filterArray = cartArray.items.filter((list: any) => { return list.item_id == this.itemList._id });

    //     // check for multiple items are added with same cart id
    //     if (filterArray.length > 1) this.filterCartArray = filterArray.filter((list: any) => { return list._id == this.data.menuId });
    //     else this.filterCartArray = filterArray;

    //     this.soldOutAction = this.filterCartArray[0].sold_out_action || this.helperService.SOLD_OUT_OPTION_LIST[0].title;
    //     this.menuId = this.filterCartArray[0].menu_id;
    //     this.categoryId = this.filterCartArray[0].category_id;
    //     this.itemQuantity = this.filterCartArray[0].quantity;
    //     this.toppingMinSelection = 0;

    //     if (this.filterCartArray[0].hasOwnProperty('variants') &&
    //        this.filterCartArray[0].hasOwnProperty('toppings')) { // for default variants
    //       this.mapFilterVariantArray(this.filterCartArray[0]);
    //       this.mapFilterToppingArray(this.filterCartArray[0]);

    //     } else if (this.filterCartArray[0].hasOwnProperty('variants') &&
    //               !this.filterCartArray[0].hasOwnProperty('toppings')) {
    //       this.mapFilterVariantArray(this.filterCartArray[0]);

    //     } else if (!this.filterCartArray[0].hasOwnProperty('variants') &&
    //                this.filterCartArray[0].hasOwnProperty('toppings')) {
    //       this.mapFilterToppingArray(this.filterCartArray[0]);

    //     } else if (!this.filterCartArray[0].hasOwnProperty('variants') &&
    //                !this.filterCartArray[0].hasOwnProperty('toppings')) {
    //       this.cartTotal();
    //     }

    //   } else {
    //     const cartArray = this.helperService.getNormalCartList();
    //     const filterArray = cartArray.items.filter((list: any) => { return list.item_id == this.itemList._id });

    //     // check for multiple items are added with same cart id
    //     if (filterArray.length > 1) this.filterCartArray = filterArray.filter((list: any) => { return list._id == this.data.menuId });
    //     else this.filterCartArray = filterArray;

    //     this.soldOutAction = this.filterCartArray[0].sold_out_action || this.helperService.SOLD_OUT_OPTION_LIST[0].title;
    //     this.menuId = this.filterCartArray[0].menu_id;
    //     this.categoryId = this.filterCartArray[0].category_id;
    //     this.itemQuantity = this.filterCartArray[0].quantity;
    //     this.toppingMinSelection = 0;

    //     if (this.filterCartArray[0].hasOwnProperty('variants') &&
    //        this.filterCartArray[0].hasOwnProperty('toppings')) { // for default variants
    //       this.mapFilterVariantArray(this.filterCartArray[0]);
    //       this.mapFilterToppingArray(this.filterCartArray[0]);

    //     } else if (this.filterCartArray[0].hasOwnProperty('variants') &&
    //               !this.filterCartArray[0].hasOwnProperty('toppings')) {
    //       this.mapFilterVariantArray(this.filterCartArray[0]);

    //     } else if (!this.filterCartArray[0].hasOwnProperty('variants') &&
    //                this.filterCartArray[0].hasOwnProperty('toppings')) {
    //       this.mapFilterToppingArray(this.filterCartArray[0]);

    //     } else if (!this.filterCartArray[0].hasOwnProperty('variants') &&
    //                !this.filterCartArray[0].hasOwnProperty('toppings')) {
    //       this.cartTotal();
    //     }
    //   }
    // }
  }
  ngOnDestroy(): void {
     delete this.selectMainOption 
     delete this.itemList
  }
  //#endregion

  //#region private methods

  private cartTotal() {
    let salePrice: any = this.itemList.price;
    this.cartTotalPrice = salePrice;
    let allProductTotal = 0;
    this.selectMainOption.forEach((product: any) => {
      let subtotal = 0;
      let tempTotal = 0;
      let productTotal = 0;
      tempTotal = +product.price * product.quantity;
      product.sub_products.forEach((subProduct: any) => {
        subtotal += subProduct.price * subProduct.quantity;
      });
      productTotal = tempTotal + product.quantity * subtotal;
      allProductTotal += productTotal;
    });
    // console.log(this.cartTotalPrice);
    this.cartTotalPrice = this.cartTotalPrice + allProductTotal;
    // console.log(this.cartTotalPrice);
  }

  private addToGroupCart(obj: any) {
    this.isItemAdd = true;
    const groupCart = this.returnLoggedInUserGroupCartList();
    this.cmoApiService
      .addGroupOrderItem(groupCart.group_id, groupCart.id, obj)
      .then(
        (res: any) => {
          this.helperService.setGroupCartList(res.data);
          this.messageService.sendMessage('Item-Add');
          this.isItemAdd = false;
          this.close();
          this.toastr.success(res.message, this.constant.SUCCESS);
        },
        (error: any) => {
          this.isItemAdd = false;
          const errMsg = this.helperService.getResponseErrorMessage(error);
          if (errMsg?.length) {
            this.toastr.error(errMsg, this.constant.ERROR);
          }
        }
      );
  }

  private addItemIntoGroupCart() {
    let obj;
    this.itemList.quantity =this.fullOrderRepeteQuantity;
      this.itemList.sub_products = this.selectMainOption;
      this.itemList.category_id = this.categoryId;
      this.itemList.store_id = this.storeId
      this.itemList.deliverect_product_id ? this.itemList.item_id = this.itemList.deliverect_product_id : this.itemList.item_id = this.itemList._id
      // this.itemList.item_id = this.itemList.deliverect_product_id 
      // console.log('store', this.storeId)
      if(this.itemList.description == null){this.itemList.description = ""}
      // console.log('ItemListtttttttttttt', this.itemList);
      // delete this.itemList.sub_products_nested
      // delete this.itemList.product_tags
      // delete this.itemList.product_type
      // delete this.itemList.max

      delete this.itemList.product_type


      obj = this.itemList
      if (this.mode !== 'edit') this.addToGroupCart(obj);
      
     else  this.updateToGroupCart(obj);
     
  
  }

  private updateToGroupCart(obj: any) {
    this.isItemAdd = true;
    const cart = this.returnLoggedInUserGroupCartList();
    if (cart.items.length || this.itemList.store_id == cart.store_id) {

      // add customer id because it not sure who is updatein host or guest
      obj.customer_id = this.data.guestCart.customer_id;
      // console.log('obj--------------------->', obj)

      // console.log('cart.items===================', cart.items)
      const selectedItem = cart?.items?.filter((ci: any) => { return (ci?.plu ? ci?.plu : Number(ci?.sku)) == (obj?.plu ? obj?.plu: Number(obj?.sku)) });

      // console.log('selectedItem-------------------->', selectedItem)
      this.cmoApiService.updateGroupOrderItem(cart.group_id, cart?.id, selectedItem[0]?._id,obj)
        .then((res: any) => {
          this.helperService.setGroupCartList(res.data);
          this.messageService.sendMessage('Item-Add');
          this.isItemAdd = false;
          this.close();
          this.toastr.success(res.message, this.constant.SUCCESS);
          setTimeout(() => {
            // this.helperService.refreshComponent(window.location.pathname);
          }, 1000);

        }, (error: any) => {
          this.isItemAdd = false;
          const errMsg = this.helperService.getResponseErrorMessage(error);
          if (errMsg?.length) {
            this.toastr.error(errMsg, this.constant.ERROR);
          }
          if (error.status == 406) {
            setTimeout(() => {
              window.location.reload();
            }, 1500);
          }
        });
    }
  }

  private addToNormalCart(obj: any) {
    this.cartList = this.helperService.getNormalCartList();
    this.isItemAdd = true;
    if (localStorage.getItem('chat-my-order:token')) {
      if (
        !this.cartList?.items?.length ||
        this.itemList?.store_id === this.cartList?.store_id
      ) {
        this.cmoApiService
          .addNormalCartItem(this.itemList.store_id, this.itemList._id, obj)
          .then(
            (res: any) => {
              this.helperService.setNormalCartList(res.data);
              this.messageService.sendMessage('Item-Add');
              this.isItemAdd = false;
              this.close();
              this.toastr.success(res.message, this.constant.SUCCESS);
            },
            (error: any) => {
              this.isItemAdd = false;
              const errMsg = this.helperService.getResponseErrorMessage(error);
              if (errMsg?.length) {
                this.toastr.error(errMsg, this.constant.ERROR);
              }
            }
          );
      } else if (this.itemList?.store_id !== this.cartList?.store_id) {
        const that = this;

        // open modal
        const mainNav: any = $('#replaceOrderModelInItems');
        mainNav.modal('show');

        // catch close event
        mainNav.on('click', '#replaceFlag', function () {
          // add item into cart
          that.cmoApiService
            .addNormalCartItem(that.itemList.store_id, that.itemList._id, obj)
            .then(
              (res: any) => {
                that.helperService.setNormalCartList(res.data);
                that.messageService.sendMessage('Item-Add');
                that.isItemAdd = false;
                that.close();
                that.toastr.success(res.message, that.constant.SUCCESS);
                that.close();
              },
              (error: any) => {
                that.isItemAdd = false;
                const errMsg =
                  that.helperService.getResponseErrorMessage(error);
                if (errMsg?.length) {
                  that.toastr.error(errMsg, that.constant.ERROR);
                }
              }
            );
        });
      }
    } else {
      this.close();
      this.isItemAdd = false;
      this.toastr.info(this.constant.LOGIN_MESSAGE, this.constant.SORRY);
      this.helperService.clearLocalStorage();
      this.router.navigate(['login'], {
        queryParams: { redirectTo: window.location.pathname },
      });
    }
  }

  private mapFilterToppingArray(array: any) {
    const toppingArray = array.toppings.map((list: any) => {
      return {
        additional_price: list.additional_price,
        image_url: list.image_url,
        is_active: list.is_active,
        is_veg: list.is_veg,
        name: list.name,
        _id: list._id,
        check: true,
      };
    });

    this.toppingMinSelection = this.itemList.toppings.min_selections;

    toppingArray.forEach((topping: any) => {
      const ind = this.itemList.toppings.options.findIndex(
        (list: any) => list.name === topping.name
      );
      if (ind > -1) {
        this.itemList.toppings.options[ind] = topping;
        this.onChangeTopping({ target: { checked: true } }, topping);
      }
    });
  }

  // private mapFilterVariantArray(array: any) {
  //   array.variants.forEach((vArray: any) => {
  //     vArray.option.is_default = true;
  //   });
  //   const variantArray = array;

  //   this.itemList.variants.forEach((itemVariant: any) => {
  //     itemVariant.options.map((list: any) => list.is_default = false);
  //     variantArray.variants.forEach((variant: any) => {
  //       const ind = itemVariant.options.findIndex((list: any) => list.name === variant.option.name);
  //       if (ind > -1) {
  //         itemVariant.options[ind] = variant.option;
  //         this.onChangeVariant(variant.option, variant);
  //       }
  //     });
  //   });
  // }

  private updateVariantObject() {
    let variantObj: any = [];
    this.selectedVariant.forEach((variant: any) => {
      variantObj.push({
        _id: variant._id,
        option: { _id: variant.option._id },
      });
    });

    return variantObj;
  }

  private updateToppingObj() {
    return this.selectedTopping.map((list: any) => {
      return { _id: list._id };
    });
  }

  //#endregion

  //#region public methods

  public returnLoggedInUserGroupCartList() {
    const groupCart = this.helperService.getGroupCartList()
      ? this.helperService.getGroupCartList()
      : {};
    const filterGroupCart = groupCart?.carts?.filter(
      (list: any) => list.customer_id == localStorage.getItem('cmo:id')
    );
    return filterGroupCart[0];
  }

  public checkShouldSubDisabled(shouldSubDisabled:boolean){
if(shouldSubDisabled == true){
 return true
}else{
 return false
  }
} 

  public onClose(){
    setTimeout(() => {
      delete  this.selectMainOption ,
      delete this.itemList;
    }, 1000);
  }


 //for options disable on max eg - white rice and add to cart button disable until min is acheived
 public selectedVarientLength(variant: any) {
  let selectedVarient: any = [];
  let selectedQuantity = 0;
  if (this.selectedMainVarientIds.length) {
    selectedVarient = this.selectedMainVarientIds.filter((item: any) => item.id == variant._id );
    for (let i = 0; i < selectedVarient.length; i++) {
      selectedQuantity = parseInt(selectedQuantity + selectedVarient[i].quantity,10)
  }
  }
   

  return selectedQuantity
}

 //for options which are selected and should not be disabled when max is attained eg - white rice
 public selectedOptionLength(option: any) {

  let selectedOption: any = []
  selectedOption = this.selectMainOption?.filter((item: any) => item._id == option._id);
  return selectedOption.length
}

  

  

  // let index = this.selectMainOption?.findIndex((item :any) => item._id == options._id)
  // console.log(index)
  // if(index == -1) {
  //   this.selectMainOption.push(options);
  // }

  // if (this.selectedVariant.length) {

  //   const ind = this.selectedVariant.findIndex((list: any) => list._id === variant._id);
  //   if (ind > -1) this.selectedVariant[ind].option = options;
  //   else this.selectedVariant.push({ option: options, _id: variant._id});

  // } else {
  //   this.selectedVariant.push({ option: options, _id: variant._id});
  // }

  // if (this.selectedVariant.length) {
  //   this.selectedVariantTotal =
  //     this.selectedVariant.map((a: any) => a.option.price).reduce((a: any,b: any) => { return parseFloat(a) + parseFloat(b) });
  //   this.cartTotal();
  // }




  public onChangeTopping(event: any, options: any) {
    if (event.target.checked) {
      this.selectedTopping.push(options);
      if (this.toppingMinSelection > 0) --this.toppingMinSelection;
    } else {
      const ind = this.selectedTopping.findIndex((list: any) => {
        return list._id == options._id;
      });
      if (ind > -1) this.selectedTopping.splice(ind, 1);

      if (
        this.selectedTopping?.length < this.itemList?.toppings?.min_selections
      )
        ++this.toppingMinSelection;
    }

    if (this.selectedTopping.length) {
      // add all topping additional Price
      this.selectedToppingTotal = this.selectedTopping
        .map((a: any) => a.additional_price)
        .reduce((a: any, b: any) => {
          return parseFloat(a) + parseFloat(b);
        });
      this.cartTotal();
    } else {
      this.selectedToppingTotal = 0;
      this.cartTotal();
    }
  }

 
  //for increasing quantity of all selected item
  public increseOrDecreaseFullOrder(option: any, multimax: any) {
    if (option == 'add') {
      if(this.fullOrderRepeteQuantity == multimax){
        this.toastr.error("The maximum item quantity for this particular item is " + multimax + " per item");
      }else{
      
      if(this.fullOrderRepeteQuantity >= 25){
        this.toastr.error("The maximum item quantity for this particular item is " + 25 + " per item")
        return
      }
    
      this.fullOrderRepeteQuantity++;
      this.cartTotal();
    }

    } else {
      if (this.fullOrderRepeteQuantity > 1) {
        this.fullOrderRepeteQuantity--;
        this.cartTotal();
      }
    }
  }

  //for managing the quantity of main item like white rice
  public addRemoveQuantity(option: any, index: number, arr: any[],variant:any) {
    // console.log('option', arr);
    if (option == 'add') {
      if( arr[index].quantity == 25){  
        this.toastr.error(this.constant.QTY_MAX_LIMIT, this.constant.ERROR);
        return
      }
      if( variant?.multimax && arr[index].quantity == variant?.multimax){
        this.toastr.error("The maximum item quantity for this particular item is " + variant?.multimax + " per item",this.constant.ERROR)
        return
      }
      arr[index].quantity++;
    } else {
      if (arr[index].quantity > 1) {
        arr[index].quantity--;
      }
    } 
    if (option == 'add') {
      this.selectedMainVarientIds[index].quantity++;
     this.CheckIfMinSatisfied()
    } else {
      if (this.selectedMainVarientIds[index].quantity > 1) {
        this.selectedMainVarientIds[index].quantity--;
       this.CheckIfMinSatisfied()
      }
    }
    this.cartTotal();
  }

  // public updateItemQty(event: any) {
  //   if (event) {
  //     if (Number(event) < 26) {
  //       this.cartTotal();
  //       this.CheckIfMinSatisfied()
  //     } else {
  //         this.toastr.error(this.constant.QTY_MAX_LIMIT, this.constant.ERROR);
  //       this.itemQuantity = 25;
  //     }
  //   }
  // }
  public updateItemQty(event: any,variant:any,type:any,index :number) {

    if (variant.multimax > 25 ||  variant.multimax == undefined) {
      if (Number(event) < 26) {
        variant.quantity = Number(event)
        if(type == 'modifier'){
          this.selectedMainVarientIds[index].quantity = Number(event)
        }else if(type == 'nested-modifier'){
          this.selectedMainOptionIds[index].quantity = Number(event)
        }else{
         this.fullOrderRepeteQuantity = Number(event)
        } 
    } else {
        this.toastr.error("The maximum item quantity for this particular item is 25 per item", this.constant.ERROR);
        variant.quantity = 25    
        if(type == 'modifier'){
          this.selectedMainVarientIds[index].quantity = 25
        }else if(type == 'nested-modifier'){
          this.selectedMainOptionIds[index].quantity = 25
        }else{
          this.fullOrderRepeteQuantity = 25
        }
       
      }
    }else{
      if (Number(event) <= variant.multimax ) {
        variant.quantity = Number(event)
        if(type == 'modifier'){
          this.selectedMainVarientIds[index].quantity = Number(event)
        }else if(type == 'nested-modifier'){
          this.selectedMainOptionIds[index + 1].quantity = Number(event)
        }else{
          this.fullOrderRepeteQuantity = Number(event)
        }
       
      } else {
         this.cartTotal();
         this.CheckIfMinSatisfied()
        this.toastr.error("The maximum item quantity for this particular item is " + variant.multimax + " per item", this.constant.ERROR);
        variant.quantity = variant.multimax
        if(type == 'modifier'){
          this.selectedMainVarientIds[index].quantity = variant.multimax
        }else if(type == 'nested-modifier'){
          this.selectedMainOptionIds[index].quantity = variant.multimax
        }else{
          this.fullOrderRepeteQuantity = variant.multimax
        }
      }
    }
    this.cartTotal();
    this.CheckIfMinSatisfied()
    // this.fullOrderRepeteQuantity > 25 ? this.isButtonDisabled = true : undefined
  }
  public numberOnly(event: any): boolean {
    return this.helperService.numberOnly(event);
  }

  public addToCart() {
    this.loading = true;
    this.isItemAdd = true;
    let obj: any;
    // if (this.itemQuantity && this.itemQuantity >=1 ) {
    //   if (this.data.isGroup == false) {
    //     if (this.selectedVariant?.length && this.selectedTopping?.length) {
    //       obj = {
    //         variants: this.updateVariantObject(),
    //         toppings: this.updateToppingObj(),
    //         quantity: this.itemQuantity,
    //         sold_out_action: (this.soldOutAction && this.soldOutAction != 'option') ? this.soldOutAction : null,
    //         menu_id: this.menuId ? this.menuId : this.data.menu_id,
    //         category_id: this.categoryId ? this.categoryId : this.data.category_id
    //       };

    //     } else if (this.selectedVariant?.length) {
    //         obj = {
    //           variants: this.updateVariantObject(),
    //           quantity: this.itemQuantity,
    //           sold_out_action: (this.soldOutAction && this.soldOutAction != 'option') ? this.soldOutAction : null,
    //           menu_id: this.menuId ? this.menuId : this.data.menu_id,
    //           category_id: this.categoryId ? this.categoryId : this.data.category_id
    //         };

    //     } else if (this.selectedTopping?.length) {
    //       obj = {
    //         toppings: this.updateToppingObj(),
    //         quantity: this.itemQuantity,
    //         sold_out_action: (this.soldOutAction && this.soldOutAction != 'option') ? this.soldOutAction : null,
    //         menu_id: this.menuId ? this.menuId : this.data.menu_id,
    //         category_id: this.categoryId ? this.categoryId : this.data.category_id
    //       };

    //     } else if (!this.selectedVariant?.length && !this.selectedTopping?.length) {
    //       obj = {
    //         quantity: this.itemQuantity,
    //         sold_out_action: (this.soldOutAction && this.soldOutAction != 'option') ? this.soldOutAction : null,
    //         menu_id: this.menuId ? this.menuId : this.data.menu_id,
    //         category_id: this.categoryId ? this.categoryId : this.data.category_id
    //       };
    //     }

    //     if (this.itemList.hasOwnProperty('variants') && this.itemList?.variants?.length) {
    //       if (!this.selectedVariant?.length) {
    //         this.toastr.error(this.constant.VARIANT_SELECT, this.constant.ERROR);

    //       } else if (this.itemList?.variants?.length !== this.selectedVariant?.length) {
    //         const notSelectedVariantNameArray: any = [];
    //         this.itemList.variants.forEach((variant: any) => {
    //           this.selectedVariant.forEach((selectedVariant: any) => {
    //             if (variant._id !== selectedVariant._id) notSelectedVariantNameArray.push(variant.name);
    //           });
    //         });
    //         this.toastr.error('Please select ' + notSelectedVariantNameArray.join(', ') + ' variant from above',
    //                           this.constant.ERROR);
    //       } else {
    //         if (this.mode == 'add') this.addToNormalCart(obj);
    //         else this.updateToNormalCart(obj);
    //       }

    //     } else {
    //       if (this.mode == 'add') this.addToNormalCart(obj);
    //       else this.updateToNormalCart(obj);
    //     }

    //   } else {
    //     this.addItemIntoGroupCart();
    //   }

    // } else {
    //   this.toastr.error('At least 1 quantity is required', this.constant.ERROR);
    // }
    if (this.data.isGroup == false) {
    if (this.mode == 'add') {
      this.itemList.quantity =this.fullOrderRepeteQuantity;
      this.itemList.sub_products = this.selectMainOption;
      this.itemList.category_id = this.categoryId
      // console.log('store', this.storeId)
      this.itemList.deliverect_product_id ? this.itemList.item_id = this.itemList.deliverect_product_id : this.itemList.item_id = this.itemList._id
      // console.log('ItemListtttttttttttt', this.itemList);
      const itemId =  this.itemList._id
      if(this.itemList.description == null){this.itemList.description = ""}
      this.cmoApiService
        .addNormalCartItem(
          this.storeId,
          this.itemList.deliverect_product_id ? this.itemList.deliverect_product_id : itemId,
          this.itemList
        )
        .then(
          (res: any) => {
            this.loading = false;
            this.helperService.setNormalCartList(res.data);
            this.messageService.sendMessage('Item-Add');
            this.close();
            this.toastr.success(res.message, this.constant.SUCCESS);
            
          },
          (error: any) => {
            this.isItemAdd = false;
            this.loading = false;
            const errMsg = this.helperService.getResponseErrorMessage(error);
            if (errMsg?.length) {
              this.toastr.error(errMsg, this.constant.ERROR);
            }
            if (error.status == 406) {
              setTimeout(() => {
                window.location.reload();
              }, 1500);
            }
          }
        );
    } else {
      this.itemList.quantity = this.fullOrderRepeteQuantity;
      this.itemList.category_id = this.categoryId
      const filteredArray = this.selectMainOption.filter(
        (item: any) => typeof item !== 'string'
      );
      this.itemList.sub_products = filteredArray;
      this.updateToNormalCart(this.itemList);
    }
    
  }else{
    this.addItemIntoGroupCart();
  }
  }

  private updateToNormalCart(obj: any) {
    this.isItemAdd = true;
    const cart = this.helperService.getNormalCartList();
    if(obj.description==null || obj.description==undefined){obj.description=""}

    this.cmoApiService
      .updateNormalCartItem(this.storeId, this.updateItemId, obj)
      .then(
        (res: any) => {
          this.helperService.setNormalCartList(res.data);
          this.messageService.sendMessage('Item-Add');
          this.isItemAdd = false;
          this.close();
          this.toastr.success(res.message, this.constant.SUCCESS);
          setTimeout(() => {
            this.helperService.refreshComponent(window.location.pathname);
          }, 1000);
        },
        (error: any) => {
          this.isItemAdd = false;
          const errMsg = this.helperService.getResponseErrorMessage(error);
          if (errMsg?.length) {
            this.toastr.error(errMsg, this.constant.ERROR);
          }
          if (error.status == 406) {
            setTimeout(() => {
              window.location.reload();
            }, 1500);
          }
        }
      );
  }

  // public CheckIfMinSatisfied(){
  //   console.log('selectMainOption',this.selectMainOption)
  //   console.log('itemList',this.itemList)
  //   //FOR FIRST NESTED LOOP
  //   for (let productTitle of this.itemList.sub_products_nested) {
  //     let totalProductQuantity = 0
  //     for(let i=0;i <= productTitle?.sub_products_nested.length - 1; i++){
  //       const selectedProduct:any = this.selectMainOption.filter((sp: any) => sp.name == productTitle?.sub_products_nested[i].name);
  //       if(selectedProduct && selectedProduct.length != 0){totalProductQuantity = totalProductQuantity + selectedProduct[0].quantity}
  //       if(selectedProduct && selectedProduct.length !=0  && selectedProduct[0].sub_products_nested.length != 0){
  //         //FOR SECOND NESTED LOOP
  //         for(let subProductTitle  of selectedProduct[0].sub_products_nested){
  //           let totalSubProductQuantity = 0
  //           for(let j = 0; j <= subProductTitle?.sub_products_nested.length - 1; j++){
  //             const selectedSubProduct:any = selectedProduct[0].sub_products[j]
              
  //             if(selectedSubProduct && selectedSubProduct.quantity != 0 && selectedSubProduct.quantity != undefined && selectedSubProduct.checked !=  false){
  //               totalSubProductQuantity = totalSubProductQuantity + selectedSubProduct.quantity
  //             }
              
  //             }if(totalSubProductQuantity < subProductTitle.min){
  //               this.isButtonDisabled = true
  //               return
  //           }else{
  //             this.isButtonDisabled =false
  //           }
  //         }   
  //         //   
  //       }   
  //    }
  //   if(totalProductQuantity < productTitle.min){
  //       this.isButtonDisabled = true
  //       return
  //   }else{
  //       this.isButtonDisabled = false
  //   }
  // } 
  // }
  public CheckIfMinSatisfied() {
    this.isButtonDisabled = false;
    this.itemList.sub_products_nested.map((productTitle:any) => {
         const totalProductQuantity = this.calculateTotalProductQuantity(productTitle);
         if ((totalProductQuantity < productTitle.min || (totalProductQuantity > productTitle.max && productTitle.max !== 0 )) && !(productTitle.min == 0 && productTitle.max == 0)){
           this.isButtonDisabled = true;
          return
         }
       }); 
   }
   
   
   private calculateTotalProductQuantity(productTitle:any) {
     let totalProductQuantity = 0;
     try{
     productTitle.sub_products_nested.forEach((selectedProduct:any) => {
       if(selectedProduct.quantity > 25 || selectedProduct.quantity > productTitle.multimax){this.isButtonDisabled = true;throw new Error("min not acheived")}
         const selectedProductInMain = this.selectMainOption.find((sp:any) => sp.name === selectedProduct.name);
         if (selectedProductInMain && selectedProductInMain.length != 0 ) {totalProductQuantity += parseInt(selectedProductInMain.quantity,10);}
           if (selectedProductInMain && selectedProductInMain.length !=0  && selectedProductInMain.sub_products_nested.length != 0) {
             selectedProductInMain.sub_products_nested.forEach((subProductTitle:any) => {
               const totalSubProductQuantity = this.calculateTotalSubProductQuantity(selectedProductInMain,subProductTitle);
               if((totalSubProductQuantity < subProductTitle.min || (totalSubProductQuantity > subProductTitle.max && subProductTitle.max !== 0))  && !(subProductTitle.min == 0 && subProductTitle.max == 0)){
                 this.isButtonDisabled = true;
                 throw new Error("min not acheived")
               }
             });
           }   
       });
     }catch{}
     return totalProductQuantity > 0 ? totalProductQuantity : 0;
   }
   
   
   private calculateTotalSubProductQuantity(selectedProductInMain:any,subProductTitle:any) {
   
     let totalSubProductQuantity = 0;
     try{
     selectedProductInMain.sub_products.forEach((selectedSubProduct:any) => {
     if(selectedSubProduct.quantity > 25  || selectedSubProduct.quantity > subProductTitle.multimax ){this.isButtonDisabled = true;throw new Error("min not acheived")}
     if(selectedSubProduct.quantity) {totalSubProductQuantity += parseInt(selectedSubProduct.quantity,10)};
     })
   }catch{}
     return totalSubProductQuantity
   }

  

  public onChangeOption(event: any, option: any, variant: any) {
    option.preSelected = false
    option.quantity = 1;
    option.deliverect_product_id ? option.item_id = option.deliverect_product_id : option.item_id = option._id
    option.sub_products = []
    let index = this.selectMainOption?.findIndex((item: any) => item._id == option._id);
    if (event.target.checked) {
     // this.checkShouldSubDisabled(true)
      this.selectMainOption.push(option);
      this.selectedMainOptionIds.push(option._id)
      this.selectedMainVarientIds.push({ id: variant._id, quantity: option.quantity })
      
    } else {
     // this.checkShouldSubDisabled(false)
      this.selectMainOption.splice(index, 1);
      this.selectedMainOptionIds = this.selectedMainOptionIds.filter((item: any) => item !== option._id);
      this.selectedMainVarientIds.splice(index, 1);
    }
    this.cartTotal();
    this.CheckIfMinSatisfied()
    
  }

  public onChangeOption2(event: boolean, option: any, variant: any) {
  
    option.deliverect_product_id ? option.item_id = option.deliverect_product_id : option.item_id = option._id
    option.sub_products = []
    let index = this.selectMainOption?.findIndex((item: any) => item._id == option._id);
    if (event) {
      this.selectMainOption.push(option);
      this.selectedMainOptionIds.push(option._id)
      this.selectedMainVarientIds.push({ id: variant._id, quantity: option.quantity })
      
    } else {
      this.selectMainOption.splice(index, 1);
     // this.selectedMainOptionIds = this.selectedMainOptionIds.filter((item: any) => item !== option._id);
      this.selectedMainVarientIds.splice(index, 1);
    }
    this.cartTotal();
    this.CheckIfMinSatisfied()
    
  }

  public addRemoveQuantitySub(option: any, index: number, arr: any[],nestedOption2Title:any) {
    // console.log('option', arr);
    if (option == 'add') {
      if( arr[index].quantity == 25){  
        this.toastr.error(this.constant.QTY_MAX_LIMIT, this.constant.ERROR);
        return
      }
      if(nestedOption2Title?.multimax && arr[index].quantity == nestedOption2Title?.multimax){
        this.toastr.error("The maximum item quantity for this particular item is " + nestedOption2Title?.multimax + " per item",this.constant.ERROR)
        return
      }
      arr[index].quantity++;
    } else {
      if (arr[index].quantity > 1) {
        arr[index].quantity--;
      }
    }
    if (option == 'add') {
      if( this.selectedMainOptionIds && this.selectedMainOptionIds[index].quantity ){
        this.selectedMainOptionIds[index].quantity++;
      }
        
        this.CheckIfMinSatisfied()
    } else {
      if (this.selectedMainOptionIds[index].quantity > 1) {
        this.selectedMainOptionIds[index].quantity--;
        this.CheckIfMinSatisfied()
      }
    }
    this.cartTotal();
  }

  public onChangeSubOption(event: any, option: any, id: number) {
   
    option.deliverect_product_id ? option.item_id = option.deliverect_product_id : option.item_id = option._id
    let index = this.selectMainOption.findIndex((modifier: any) => modifier._id == id);
    let subIndex = this.selectMainOption[index].sub_products.findIndex((subProduct: any) => subProduct._id == option._id);
    
    if (event.target.checked) {
      option.quantity = 1;
      option.checked = true
      this.selectMainOption[index].sub_products.push(option);
      this.selectedMainOptionIds.push({ id: id, quantity: option.quantity })
    } else {
      // console.log('optio false---------------', option)
      option.checked = false
      this.selectedMainOptionIds = this.selectedMainOptionIds.filter((item: any) => item.id !== id)
      this.selectMainOption[index].sub_products.splice(subIndex, 1);  
    }
    this.CheckIfMinSatisfied()
    this.cartTotal(); 
  }

  public onChangeSubOption2(event: boolean, option: any, id: number) {
   
    option.deliverect_product_id ? option.item_id = option.deliverect_product_id : option.item_id = option._id
    let index = this.selectMainOption.findIndex((modifier: any) => modifier._id == id);
    let subIndex = this.selectMainOption[index].sub_products.findIndex((subProduct: any) => subProduct._id == option._id);
    
    if (event) {
      option.checked = true
      this.selectMainOption[index].sub_products.push(option);
      this.selectedMainOptionIds.push({ id: id, quantity: option.quantity })
    } else {
      // console.log('optio false---------------', option)
      option.checked = false
      this.selectMainOption[index].sub_products.splice(subIndex, 1);  
    }
    this.CheckIfMinSatisfied()
    this.cartTotal(); 
  }

  public getRedirectStoreDetailsLink() {
    const mainNav: any = $('#replaceOrderModelInItems');
    mainNav.modal('hide');
    const storeName = this.helperService.convertToSlug(
      this.cartList.store_details.name
    );
    this.close();
    window.location.replace(
      '/restaurant/' + storeName + '/' + this.cartList.store_details._id
    );
  }

  public selectedSubOptionLength(i: any,j: any,m:any,l:any) {
    let selectedOptionOfSub: any = []
    selectedOptionOfSub = this.itemList.sub_products_nested[i].sub_products_nested[j].sub_products_nested[m].sub_products_nested[l]
   // console.log('selectedOptionOfSub', selectedOptionOfSub);
   
     if(selectedOptionOfSub && selectedOptionOfSub.checked == true) 
     {
       return true
      }
     else{
      return false
     } 
  }

 



   //for suboptions disable on max eg - sate sauce and add to cart button disable until min is acheived
   public selectedSubVarientLength(option: any, i: any,j: any,m:any) {
    //  console.log(this.selectMainOption)
    
     //let currentVarirant = this.selectMainOption[variantIndex];
     let currentVarirant = this.selectMainOption.filter((obj: any) => obj._id == option._id)
     //console.log('currentVarirant',currentVarirant)
     let selectedQuantityOfSub = 0;
     for (let i = 0; i <= currentVarirant[0].sub_products.length - 1; i++) {
       selectedQuantityOfSub = selectedQuantityOfSub + parseInt(currentVarirant[0].sub_products[i].quantity,10);
     }
     
    
     return selectedQuantityOfSub; 
 
     // return selectedQuantity
   }

  public cancelResetOrder() {
    this.isItemAdd = false;
  }

  public onChangerSoldOutOption(event: any) {
    if (event.target.value == 'option') {
      this.soldOutAction = '';
    } else {
      this.soldOutAction = event.target.value;
    }
  }

  

  //#endregion
}
