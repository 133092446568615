<div class="modal-dialog modal-dialog-centered">
    <div class="modal-content">
        <div class="modal-header">
            <h5 class="modal-title">{{itemList?.name | titlecase}}</h5>
            <button type="button" class="close" (click)="onClose()" (click)="close()"  [title]="constant.CLOSE_TOOLTIP">
                <span aria-hidden="true">&times;</span>
            </button>
        </div>
        <div class="modal-body modal-height">
            <h5 class="model-prise">
                <span>{{ itemList?.price | currency:'USD' }}</span>
            </h5>
            <p>{{itemList?.description}}</p>
            <p *ngIf="itemList?.calories?.serving && itemList?.calories?.amount && itemList.variants.length < 1">
                Calories: {{ itemList?.calories?.amount }}<br>
                Serving Size: {{ itemList?.calories?.serving }}
            </p>
            <div *ngIf="itemList?.hasOwnProperty('sub_products_nested')">

                <!-- For variants -->
                <!-- main loop  Rice Selection*-->
                <ng-container *ngFor="let variant of itemList?.sub_products_nested; let i = index">
                    <div class="model-rq" *ngIf="variant.sub_products_nested?.length">
                        <div class="item-variant">
                            <label for=" {{variant.name}}">
                                <h6 class="pt-3"><b> {{variant.name | titlecase}} </b><span *ngIf="variant.min && variant.min != 0" class="text-danger">*</span>
                                    <small> Min:{{variant.min}}</small> <small>Max:{{variant.max}}</small>
                                </h6>
                            </label>
                            <ng-container *ngIf="(((variant.min == 0 && variant.max > 0) || !(variant.min > 0 && variant.max == 0)) && !(variant.max == 0 && variant.min == 0) && selectedVarientLength(variant)>=variant.max)">  
                                <div class="bg_ex">    
                                <h6 class="mb-0 text-white max-message fs-10">{{ "You have already selected max quantity for " + variant.name + " category" }}</h6>                  
                                </div>
                            </ng-container>
                            <div class="row w-100">
                                <!-- options of Rice Selections -->
                                <ng-container *ngFor="let option of variant.sub_products_nested; let j = index">
                                    <div class="col-12" *ngIf="option">
                                        <div class="labelline custom-control custom-checkbox">

                                            <input type="checkbox" id="custom-topping-checkbox{{i}}{{j}}"
                                                class="custom-control-input" [name]="option.name" [checked]="option.is_default"
                                                (change)="onChangeOption($event, option,variant)"
                                                [disabled]="(((variant.min == 0 && variant.max > 0) || !(variant.min > 0 && variant.max == 0)) && !(variant.max == 0 && variant.min == 0) && !selectedOptionLength(option) && selectedVarientLength(variant)>=variant.max) || option.snoozed">
                                      
                                            <!-- <input type="checkbox" id="custom-topping-checkbox{{i}}{{j}}"
                                                class="custom-control-input" [name]="option.name"b
                                                (change)="onChangeVariant($event, option,variant)"
                                                [disabled]="checkMainOptionValidation(option,variant)"> -->
                                            <label for="custom-topping-checkbox{{i}}{{j}}"
                                                class="pl-2 custom-control-label newlabel">
                                                <div class="label1">{{option.name | titlecase}}</div>
                                                <a class="unavailable" *ngIf="option.snoozed ">Unavailable</a>
                                                <div class="lw" *ngIf="variant.multimax &&  variant.multimax != 0">
                                                    <div *ngFor="let product of selectMainOption;let p = index">
                                                        <span class="count-number" *ngIf="option._id == product._id">
                                                            <button type="button"
                                                                class="btn-sm left dec btn btn-outline-secondary"
                                                                (click)="addRemoveQuantity('remove', p, selectMainOption,'')"
                                                                [title]="itemQuantity == 1 ? '' : constant.DECREASE_QTY">
                                                                <i class="feather-minus"></i>
                                                                <!-- [disabled]="product.quantity == 1" -->
                                                            </button>
                                                            <input class="count-number-input" type="text"
                                                                [(ngModel)]="option.quantity" minlength="1"
                                                                maxlength="2" (ngModelChange)="updateItemQty($event,variant,'modifier',p)"
                                                                (keypress)="numberOnly($event)" inputmode="numeric">
                                                            <button type="button"
                                                                class="btn-sm right inc btn btn-outline-secondary"
                                                                (click)="addRemoveQuantity('add', p, selectMainOption,variant)"
                                                                [title]="itemQuantity >= 25 ? constant.QTY_MAX_LIMIT : constant.INCREASE_QTY"
                                                                [disabled]="selectedVarientLength(variant)>=variant.max">
                                                                <i class="feather-plus"></i>
                                                            </button>
                                                        </span>
                                                    </div>
                                                </div>
                                                <div class="label2">
                                                    <span class="text-muted">+{{option.price | currency:
                                                        'USD'}}</span>
                                                </div>
                                            </label>
                                        </div>
                                    </div>
                                    <div class="row w-100"
                                        *ngIf="option.sub_products_nested.length > 0 && (this.selectedMainOptionIds.includes(option._id)) ">
                                        <!-- Choose A Sauce -->
                                        <ng-container
                                            *ngFor="let nestedOption2Title of option.sub_products_nested; let m = index">
                                            <div class="col-12 ml-3 mt-1" style="padding-left: 0px; padding-right: 0px;" *ngIf="nestedOption2Title.is_active">
                                                <!-- <div class="labelnew">{{nestedOption2Title.name | titlecase}} <span class="text-danger">*</span>
                                                    Min:{{nestedOption2Title.min}} Max:{{nestedOption2Title.max}}
                                            
                                                </div> -->
                                                <label for=" {{nestedOption2Title.name}}">
                                                    <h6 class="pt-3 labelnew"><b> {{nestedOption2Title.name | titlecase}} </b><span *ngIf="nestedOption2Title.min && nestedOption2Title.min != 0" class="text-danger">*</span>
                                                        <small> Min:{{nestedOption2Title.min}}</small> <small>Max:{{nestedOption2Title.max}}</small>
                                                    </h6>
                                                </label>
                                                <ng-container *ngIf="(((((nestedOption2Title.min == 0 && nestedOption2Title.max > 0) || !(nestedOption2Title.min > 0 && nestedOption2Title.max == 0)) && (nestedOption2Title.max != 0 && nestedOption2Title.min != 0) && selectedSubVarientLength(option,i,j,m) >= nestedOption2Title.max)))" >  
                                                    <div class="bg_ex">   
                                                        <div> 
                                                    <h6 class="mb-0 text-white max-message fs-10">{{ "You have already selected max quantity for " + nestedOption2Title.name + " category" }}</h6>                  
                                                    </div>
                                                </div>
                                                </ng-container>
                                            </div>

                                            <div class="row w-100 ml-2">
                                                <!-- Sause Options -->
                                                <ng-container
                                                    *ngFor="let nestedOption2 of nestedOption2Title.sub_products_nested; let l = index">
                                                    <div class="col-12">
                                                        <div class="labelline custom-control custom-checkbox">
                                                            
                                                            <input type="checkbox" id="sub{{i}}{{j}}{{m}}{{l}}"
                                                                class="custom-control-input"
                                                                [checked]="nestedOption2.is_default && option.preSelected"
                                                                [name]="nestedOption2.name" [value]="nestedOption2.name"
                                                                (change)="onChangeSubOption($event, nestedOption2, option._id)"
                                                                [disabled]="(((((nestedOption2Title.min == 0 && nestedOption2Title.max > 0) || !(nestedOption2Title.min > 0 && nestedOption2Title.max == 0)) && (nestedOption2Title.max != 0 && nestedOption2Title.min != 0)  && !selectedSubOptionLength(i,j,m,l)  && selectedSubVarientLength(option,i,j,m) >= nestedOption2Title.max)|| nestedOption2.snoozed))">
                                                                <!-- (variant.max != 0 && variant.min != 0) -->
                                                            <label for="sub{{i}}{{j}}{{m}}{{l}}"
                                                                class="pl-1 custom-control-label newlabel">
                                                                <div class="label1">{{nestedOption2.name | titlecase}}
                                                                </div>
                                                                <a class="unavailable"
                                                                    *ngIf="nestedOption2.snoozed ">Unavailable</a>
                                                                <div class="lw"  *ngIf="nestedOption2Title.multimax &&  nestedOption2Title.multimax != 0">
                                                                    <div
                                                                        *ngFor="let mainProduct of selectMainOption;let mp = index">
                                                                        <div *ngIf="mainProduct._id == option._id">
                                                                            <div
                                                                                *ngFor="let subProduct of selectMainOption[mp].sub_products;let z = index">
                                                                                <span class="count-number"
                                                                                    *ngIf="nestedOption2._id == subProduct._id">
                                                                                    <button type="button"
                                                                                        class="btn-sm left dec btn btn-outline-secondary"
                                                                                        (click)="addRemoveQuantitySub('remove', z, selectMainOption[mp].sub_products,'')"
                                                                                        [disabled]="subProduct.quantity == 1"
                                                                                        [title]="itemQuantity == 1 ? '' : constant.DECREASE_QTY">
                                                                                        <!-- [disabled]="subProduct.quantity == 1" -->
                                                                                        <i class="feather-minus"></i>
                                                                                    </button>
                                                                                    <input class="count-number-input"
                                                                                        type="text"
                                                                                        [(ngModel)]="nestedOption2.quantity"
                                                                                        minlength="1" maxlength="2"
                                                                                        (ngModelChange)="updateItemQty($event,nestedOption2Title,'nested-modifier',z)"
                                                                                        (keypress)="numberOnly($event)"
                                                                                        inputmode="numeric">
                                                                                    <button type="button"
                                                                                        class="btn-sm right inc btn btn-outline-secondary tt"
                                                                                        (click)="addRemoveQuantitySub('add', z, selectMainOption[mp].sub_products,nestedOption2Title)"
                                                                                        [title]="itemQuantity >= 25 ? constant.QTY_MAX_LIMIT : constant.INCREASE_QTY"
                                                                                        [disabled]="selectedSubVarientLength(option,i,j,m ) >= nestedOption2Title.max ">
                                                                                        <i class="feather-plus"></i>
                                                                                    </button>
                                                                                </span>
                                                                            </div>
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                               
                                                                <div class="label2">
                                                                    <span class="text-muted label2">+{{nestedOption2.price |
                                                                        currency: 'USD'}}</span>
                                                                </div>
                                                            </label>
                                                        </div>
                                                        <!-- <div class="custom-control custom-radio labelline"
                                                            *ngIf="nestedOption2Title.max == 1">
                                                            <input type="radio" id="customRadio{{l}}"
                                                                name="variantName{{j}}{{m}}"
                                                                [checked]="nestedOption2.is_default"
                                                                [value]="nestedOption2" class="custom-control-input"
                                                                (change)="onnestedOption2Change($event, nestedOption2, nestedOption2Title)">
                                                            <label class="custom-control-label newlabel"
                                                                for="customRadio{{l}}">
                                                                <div class="label1">{{nestedOption2.name | titlecase}}
                                                                </div>
                                                                <div class="labe12" *ngIf="nestedOption2Title.multimax">
                                                                    <div
                                                                        *ngFor="let mainProduct of selectMainOption;let mp = index">
                                                                        <div *ngIf="mainProduct._id == option._id">
                                                                            <div
                                                                                *ngFor="let subProduct of selectMainOption[mp].sub_products;let z = index">
                                                                                <span class="count-number"
                                                                                    *ngIf="nestedOption2._id == subProduct._id">
                                                                                    <button type="button"
                                                                                        class="btn-sm left dec btn btn-outline-secondary"
                                                                                        (click)="addRemoveQuantity('remove', z, selectMainOption[i].sub_products)"
                                                                                        [disabled]="subProduct.quantity == 1"
                                                                                        [title]="itemQuantity == 1 ? '' : constant.DECREASE_QTY">
                                                                                        <i class="feather-minus"></i>
                                                                                    </button>
                                                                                    <input class="count-number-input"
                                                                                        type="text"
                                                                                        [(ngModel)]="subProduct.quantity"
                                                                                        minlength="1" maxlength="2"
                                                                                        (ngModelChange)="updateItemQty($event)"
                                                                                        (keypress)="numberOnly($event)"
                                                                                        inputmode="numeric">
                                                                                    <button type="button"
                                                                                        class="btn-sm right inc btn btn-outline-secondary"
                                                                                        (click)="addRemoveQuantity('add', z, selectMainOption[i].sub_products)"
                                                                                        [title]="itemQuantity >= 25 ? constant.QTY_MAX_LIMIT : constant.INCREASE_QTY"
                                                                                        [disabled]="itemQuantity == 25">
                                                                                        <i class="feather-plus"></i>
                                                                                    </button>
                                                                                </span>
                                                                            </div>
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                                <span
                                                                    class="text-muted label2">+{{nestedOption2.price | currency: 'USD'}}</span>
                                                            </label>
                                                        </div> -->
                                                    </div>
                                                </ng-container>
                                            </div>
                                        </ng-container>

                                    </div>
                                </ng-container>

                            </div>

                        </div>
                    </div>
                </ng-container>

                <!-- For toppings  -->
                <div class="model-rq" *ngIf="itemList?.toppings?.options?.length">
                    <div class="item-toppings">
                        <label>
                            <h6 class="pt-3"><b>Toppings</b></h6>
                        </label>
                        <span>
                            <ng-container *ngIf="toppingMinSelection > 0">
                                <p><span class="text-danger">*</span>Please select {{ toppingMinSelection }} more
                                    {{ toppingMinSelection == 1 ? 'topping' : 'toppings' }}</p>
                            </ng-container>
                        </span>
                        <div class="row">
                            <ng-container *ngFor="let topping of itemList?.toppings?.options; let i = index">
                                <div class="col-12" *ngIf="topping.is_active">
                                    <div class="labelline custom-control custom-checkbox">
                                        <input type="checkbox" id="custom-topping-checkbox{{i}}"
                                            class="custom-control-input" [checked]="topping.check" [name]="topping.name"
                                            (change)="onChangeTopping($event, topping)">
                                        <label for="custom-topping-checkbox{{i}}"
                                            class="pl-2 custom-control-label newlabel">
                                            <div class="label1">{{topping.name | titlecase}}</div>
                                            <span class="text-muted label2">+{{topping.additional_price | currency:
                                                'USD'}}</span>
                                        </label>
                                    </div>
                                </div>
                            </ng-container>
                        </div>
                    </div>
                </div>
            </div>

            <div *ngIf="itemList?.hasOwnProperty('variants') && !itemList?.hasOwnProperty('toppings')">

                <!-- For variants -->
                <ng-container *ngFor="let variant of itemList?.variants; let j = index">
                    <div class="model-rq" *ngIf="variant.options?.length">
                        <div class="item-variant">
                            <label for=" {{variant.name}}">
                                <h6 class="pt-3"><b> {{variant.name | titlecase}}</b><span class="text-danger">*</span>
                                </h6>
                            </label>
                            <div class="row">
                                <ng-container *ngFor="let option of variant.options; let i = index">
                                    <div class="col-12" *ngIf="option.is_active">
                                        <div class="labelline custom-control custom-radio">

                                            <input type="radio" id="customRadio{{j}}{{i}}" name="variantName{{j}}"
                                                [checked]="option.is_default" [value]="option.name"
                                                class="custom-control-input"
                                                (change)="onChangeOption($event,option, variant)"
                                                [disabled]="(!selectedOptionLength(option) &&  selectedVarientLength(variant) >= variant.max)  ||  option.snoozed">
                                            <label class="custom-control-label newlabel" for="customRadio{{j}}{{i}}">
                                                <div class="label1">{{option.name | titlecase}} </div>
                                                <span class="text-muted label2">+{{option.additional_price | currency:
                                                    'USD'}}</span>
                                            </label>
                                        </div>
                                    </div>
                                </ng-container>
                            </div>
                        </div>
                    </div>
                </ng-container>

            </div>

            <div *ngIf="!itemList?.hasOwnProperty('variants') && itemList?.hasOwnProperty('toppings')">

                <!-- For toppings  -->
                <div class="model-rq" *ngIf="itemList?.toppings?.options?.length">
                    <div class="item-toppings">
                        <label>
                            <h6 class="pt-3"><b>Toppings</b></h6>
                        </label>
                        <span>
                            <ng-container *ngIf="toppingMinSelection > 0">
                                <p><span class="text-danger">*</span> Please select {{ toppingMinSelection }} more
                                    {{ toppingMinSelection == 1 ? 'topping' : 'toppings' }}</p>
                            </ng-container>
                        </span>
                        <div class="row margin-bottom-20">
                            <ng-container *ngFor="let topping of itemList?.toppings?.options; let i = index">
                                <div class="col-12" *ngIf="topping.is_active">
                                    <div class="labelline custom-control custom-checkbox">
                                        <input type="checkbox" id="custom-topping-checkbox{{i}}"
                                            [checked]="topping.check" [name]="topping.name"
                                            (change)="onChangeTopping($event, topping)">
                                        <label for="custom-topping-checkbox{{i}}"
                                            class="pl-2 custom-control-label newlabel">
                                            <div class="label1">{{topping.name | titlecase}} {{topping.check}}</div>
                                            <span class="text-muted label2">+{{topping.additional_price | currency:
                                                'USD'}}</span>
                                        </label>
                                    </div>
                                </div>
                            </ng-container>
                        </div>
                    </div>
                </div>

            </div>

            <!-- <ng-container *ngIf="(data.isGroup && returnLoggedInUserGroupCartList().group_admin) || !data.isGroup">
                <label><h6 class="pt-3"><b>If sold out</b></h6></label>  
                <select class="form-control" style="margin-top: -5px;" [(ngModel)]="soldOutAction" (change)="onChangerSoldOutOption($event)">
                    <option value="option">-- Select Option --</option>
                    <option *ngFor="let soldOut of soldOutList" [value]="soldOut.title">
                        {{soldOut.title}}
                    </option>
                </select>
            </ng-container> -->
        </div>
        <div class="modal-footer p-0 border-0">
            <div class="col-6 m-0 p-0  ">
                <h6 class="font-weight-bold pl-3">Quantity</h6>
                <div class="d-flex align-items-center pl-3 ">
                    <span class="count-number">
                        <button type="button" class="btn-sm left dec btn btn-outline-secondary"
                            (click)="increseOrDecreaseFullOrder('remove',itemList.multimax)"
                            [disabled]="fullOrderRepeteQuantity == 1"
                            [title]="fullOrderRepeteQuantity == 1 ? '' : constant.DECREASE_QTY">
                            <i class="feather-minus"></i>
                        </button>
                        <input class="count-number-input" type="text" [(ngModel)]="fullOrderRepeteQuantity"
                        (ngModelChange)="updateItemQty($event,itemList,'fullItem',0)" (keypress)="numberOnly($event)" inputmode="numeric">
                        <button type="button" class="btn-sm right inc btn btn-outline-secondary"
                            (click)="increseOrDecreaseFullOrder('add',itemList.multimax)"
                            [title]="fullOrderRepeteQuantity >= 25 ? constant.QTY_MAX_LIMIT : constant.INCREASE_QTY">
                            <i class="feather-plus"></i>
                        </button>
                    </span>
                </div>
            </div>
            <div class="col-6 m-0 p-0">
                <button type="button" class="btn btn-primary btn-lg btn-block" 
                    [disabled]="fullOrderRepeteQuantity == 0|| fullOrderRepeteQuantity>=26 || (toppingMinSelection > 0) || isItemAdd ||  isButtonDisabled  || !isResturantActive " [title]="toppingMinSelection > 0 ? constant.TOPPING_REQUIRED :
                             itemQuantity > 25 ? constant.QTY_MAX_LIMIT :
                             mode == 'add' ? constant.ADD_TO_CART : constant.UPDATE_CART" (click)="addToCart()"
                    *ngIf="itemList?.is_active && (itemList?.in_stock == true || itemList.snoozed == false)">     
                    {{mode == 'add' ? 'Add to Cart' : 'Update Cart'}}
                    {{(cartTotalPrice) *this.fullOrderRepeteQuantity| currency: 'USD'}}
                    <i class="fa fa-spinner fa-spin fa-lg" *ngIf="isItemAdd"></i>
                </button>
                <button type="button" class="btn btn-primary btn-lg btn-block" [disabled]="!itemList?.is_active || (itemList?.in_stock == false || itemList.snoozed == true)"
                    [title]="constant.OUT_OF_STOCK" *ngIf="!itemList?.is_active || (itemList?.in_stock == false || itemList?.snoozed == true)">
                    {{constant.OUT_OF_STOCK}}
                </button>
            </div>
        </div>
    </div>
</div>

<!-- Replace cart modal -->
<div class="modal fade" id="replaceOrderModelInItems" tabindex="-1" role="dialog">
    <div class="modal-dialog modal-dialog-centered">
        <div class="modal-content">
            <div class="modal-header">
                <h5 class="modal-title">Items Already in Cart</h5>
                <button type="button" class="close" data-dismiss="modal" aria-label="Close" (click)="cancelResetOrder()"
                    [title]="constant.CLOSE_TOOLTIP">
                    <span aria-hidden="true">&times;</span>
                </button>
            </div>
            <div class="modal-body text-left">
                <h5 class="m-0 text-muted">Your order cart currently contains items from
                    <a class="storename-hover text-primary" (click)="getRedirectStoreDetailsLink()"
                        title="Go to {{cartList?.store_details?.name}}">{{ cartList?.store_details?.name }}</a>.
                    <br><br>
                    To begin an order from <span class="text-primary">{{ storeName }}</span> you will need to delete the
                    items in your cart, and start a new order.
                    <br><br>
                    Do you want to delete the items in your order cart and begin a new order?
                </h5>
            </div>
            <div class="modal-footer p-0 border-0">
                <div class="col-6 m-0 p-0 text-left">
                    <button type="button" class="btn border btn-lg btn-block text-primary" data-dismiss="modal"
                        (click)="cancelResetOrder()" [title]="constant.CANCEL_TOOLTIP">
                        {{constant.CANCEL_TOOLTIP}}
                    </button>
                </div>
                <div class="col-6 m-0 p-0">
                    <button type="button" id="replaceFlag" class="btn border-top btn-primary btn-lg btn-block"
                        data-dismiss="modal" [title]="constant.YES_START_REFRESH">
                        {{constant.YES_START_REFRESH}}
                    </button>
                </div>
            </div>
        </div>
    </div>
</div>