
<div class="modal-dialog modal-dialog-centered">
    <div class="modal-content">
        <div class="modal-header">
            <h5 class="modal-title">Invite Your Guests</h5>
            <button type="button" class="close" (click)="getRedirectStoreDetailsLink()">
            <span aria-hidden="true">&times;</span>
            </button>
        </div>
        <div class="modal-body modal-height ">
            <h6>Invite up to 20 people. You can use a phone number, email address, or both.</h6>
            <form [formGroup]="groupOrderForm">
                <div formArrayName="invites">
                    <div *ngFor="let order of groupOrderForm.get('invites')['controls']; let i=index; let last = last;" [formGroupName]="i">
                        <div class="row col-md-12 col-sm-12 col-lg-12  margin-top-20 align-items-center">
                            <div class="col-md-1 col-sm-1 my-auto">
                                {{i + 1}}.
                            </div>
                            <div class="col-md-4 col-sm-4 my-auto">
                                <input type="text" class="form-control" placeholder="Phone Number" mask="(000) 000-0000" inputmode="numeric" formControlName="mobile" minlength="10">
                                <div class="invalid-feedback" class="error-message" *ngIf="order.controls.mobile?.errors?.minlength">
                                    * {{constant.PHONE_REQUIRED_LENGTH}}
                                </div>
                            </div>
                            <div class="col-md-5 col-sm-5 my-auto">
                                <input type="text" class="form-control" placeholder="Email Address" formControlName="email">
                            </div>
                            <div class="col-md-2 col-sm-2 my-auto">
                                <button (click)="removeInvitation(i)" *ngIf="!last" class="btn btn-sm invite-bt text-danger" [title]="constant.REMOVE">
                                    <i class="feather-trash-2"></i>
                                </button>
                                <button (click)="addInvitation(i)" class="btn btn-sm invite-bt" *ngIf="last" [title]="order.controls.mobile?.errors?.minlength ? constant.PHONE_REQUIRED_LENGTH : ((i == 19) ? constant.INVITE_REACHED_GROUP_LIMIT : constant.ADD_TOOLTIP)" [disabled]="order.controls.mobile?.errors?.minlength">
                                    <i class="feather-plus"></i>
                                </button>
                            </div>
                        </div>
                    </div>
                </div>
            </form>
        </div>
        <div class="modal-footer p-0 border-0">
            <div class="col-6 m-0 p-0">
                <button type="button" class="btn border btn-lg btn-block" (click)="skipInvitation()" [title]="constant.GROUP_ORDER.SKIP">
                    {{ constant.GROUP_ORDER.SKIP }}
                </button>
            </div>
            <div class="col-6 m-0 p-0">
                <button type="button" class="btn btn-primary btn-lg btn-block" (click)="submitInviteFriend()" [title]="constant.GROUP_ORDER.SEND_INVITATION">
                   {{ constant.GROUP_ORDER.SEND_INVITATION }}
                </button>
            </div>
        </div>
    </div>
</div>
