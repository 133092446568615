<!-- Footer -->
<!-- <div class="osahan-menu-fotter fixed-bottom bg-white px-3 py-2 text-center d-none">
    <div class="row">
        <div class="col">
            <a [routerLink]="['/home']" class="text-dark small font-weight-bold text-decoration-none ">
                <p class="h4 m-0"><i class="feather-home text-dark"></i></p>
                Home
            </a>
        </div>

        <div class="col bg-white rounded-circle mt-n4 px-0 py-1">
            <div class="bg-new rounded-circle mt-n0 shadow">
                <a  (click)="goToCheckout()" class="text-white small font-weight-bold text-decoration-none">
                    <i class="feather-shopping-cart"></i>
                </a>
            </div>
        </div>
        <div class="col">
            <a  [routerLink]="['/near-by']" class="text-dark small font-weight-bold text-decoration-none">
                <p class="h4 m-0"><i class="feather-search"></i></p>
                {{constant.RESTAURANT_NEARBY_VIEW}}
            </a>
        </div>
     
    </div>
</div> -->
<!-- footer -->

<footer class="section-footer border-top bg-dark">
    <div class="container">
        <section class="footer-top padding-y py-5">
            <div class="row">
                <aside class="col-md-5 footer-about">
                    <article class="d-flex pb-3">
                        <div>
                            <a routerLink="/home" target="" >
                                <img alt="#" src="assets/img/logo_1.svg" class="logo-footer mr-3">
                            </a>
                        </div>
                        <div>
                            <h6 class="title text-white">About Us</h6>
                            <p class="text-muted">
                                Chat My Order is an online food delivery and takeout platform which helps you discover
                                and support amazing local restaurants.
                                We made it easy, fast, and simple to use. You're just one click away from enjoying the
                                most awesome food spots in town!
                            </p>
                            <div class="d-flex align-items-center">
                                <a class="btn btn-icon btn-outline-light mr-1 btn-sm" title="Facebook" target="_blank"
                                    [href]="contactList?.social_links?.facebook"><i class="feather-facebook"></i>
                                </a>
                                <a class="btn btn-icon btn-outline-light mr-1 btn-sm" title="Twitter" target="_blank"
                                    [href]="contactList?.social_links?.twitter"><svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" class="bi bi-twitter-x" viewBox="0 0 16 16">
                                        <path d="M12.6.75h2.454l-5.36 6.142L16 15.25h-4.937l-3.867-5.07-4.425 5.07H.316l5.733-6.57L0 .75h5.063l3.495 4.633L12.601.75Zm-.86 13.028h1.36L4.323 2.145H2.865z"/>
                                      </svg>
                                </a>
                                <a class="btn btn-icon btn-outline-light mr-1 btn-sm" title="Instagram" target="_blank"
                                    [href]="contactList?.social_links?.instagram"><i class="feather-instagram"></i>
                                </a>
                                <a class="btn btn-icon btn-outline-light mr-1 btn-sm" title="Pinterest" target="_blank"
                                    [href]="contactList?.social_links?.pinterest"><i class="fab fa-pinterest"></i>
                                </a>
                                <a class="btn btn-icon btn-outline-light mr-1 btn-sm" title="TikTok" target="_blank"
                                    [href]="contactList?.social_links?.tiktok"><i class="fab fa-tiktok"></i>
                                </a>
                                <a class="btn btn-icon btn-outline-light mr-1 btn-sm" title="Snapchat" target="_blank"
                                    [href]="contactList?.social_links?.snapchat"><i class="fab fa-snapchat"></i>
                                </a>
                            </div>
                        </div>
                    </article>
                </aside>

                <aside class="col-sm-3 col-md-2 text-white">

                    <div class="f-h1">
                        <h6 class="title">Customers</h6>
                        <ul class="list-unstyled hov_footer">
                            <li *ngIf="!isLogin">
                                <a routerLink="/login" class="text-muted" [title]="constant.CUSTOMER_LOGIN">
                                    {{constant.CUSTOMER_LOGIN}}
                                </a>
                            </li>
                            <li *ngIf="!isLogin">
                                <a routerLink="/signup" class="text-muted" [title]="constant.CUSTOMER_SIGN_UP">
                                    {{constant.CUSTOMER_SIGN_UP}}
                                </a>
                            </li>
                            <li>
                                <a [href]="cmoFAQLink" target="_blank" class="text-muted" [title]="constant.HELP_CENTER">
                                    {{constant.HELP_CENTER}}
                                </a>
                            </li>
                            <li>
                                <a [href]="cmoBlogLink" target="_blank" class="text-muted" [title]="constant.OUR_BLOG">
                                    {{constant.OUR_BLOG}}
                                </a>
                            </li>
                            <li>
                                <a routerLink="/contact-us" class="text-muted" [title]="constant.CONTACTUS">
                                    {{constant.CONTACTUS}}
                                </a>
                            </li>
                        </ul>
                    </div>
                </aside>

                <aside class="col-sm-3  col-md-2 text-white">
                    <div class="f-h1">
                        <h6 class="title">Delivery Riders</h6>
                        <ul class="list-unstyled hov_footer">
                            <li>
                                <a [href]="cmoRiderLogin" class="text-muted" target="_blank" [title]="constant.RIDER_LOGIN">
                                    {{constant.RIDER_LOGIN}}
                                </a>
                            </li>
                            <li>
                                <a [href]="cmoBecomeRider" class="text-muted" target="_blank" [title]="constant.BECOME_A_DELIVERY_RIDER">
                                    {{constant.BECOME_A_DELIVERY_RIDER}}
                                </a>
                            </li>
                            <li>
                                <a [href]="cmoRiderResource" class="text-muted" target="_blank" [title]="constant.RIDER_RESIURCES">
                                    {{constant.RIDER_RESIURCES}}
                                </a>
                            </li>
                        </ul>
                    </div>

                </aside>

                <aside class="col-sm-3  col-md-3 text-white">
                    <div class="f-h1">
                        <h6 class="title">Merchants</h6>
                        <ul class="list-unstyled hov_footer">
                            <li>
                                <a [href]="businessSignInLink" class="text-muted" target="_blank" [title]="constant.MERCHANT_LOGIN">
                                    {{constant.MERCHANT_LOGIN}}
                                </a>
                            </li>
                            <li>
                                <a [href]="businessSignUpLink" class="text-muted" target="_blank" [title]="constant.BECOME_A_NEW_MERCHANT">
                                    {{constant.BECOME_A_NEW_MERCHANT}}
                                </a>
                            </li>
                            <li>
                                <a [href]="cmoMerchantResource" class="text-muted" target="_blank" [title]="constant.MERCHANT_RESOURCES">
                                    {{constant.MERCHANT_RESOURCES}}
                                </a>
                            </li>
                        </ul>
                    </div>

                </aside>

            </div>
            <!---- moblie view 992 sceen-->
            <div class="f-h">
                <div class="row">
                    <div class="col-sm-6 text-white pb-3">
                        <div class="dropdown new-dropdown text-center">
                            <button class="btn btn-outline-light dropdown-toggle btn-sm border-white-btn btw-120" type="button" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
                                <span class="text-theme">Customers</span>
                            </button>
                            <div class="dropdown-menu dropdown-menu-center1 shadow-sm border-0 ">
                                <a class="dropdown-item" routerLink="/login" *ngIf="!isLogin">{{constant.CUSTOMER_LOGIN}} </a>
                                <a class="dropdown-item" routerLink="/signup" *ngIf="!isLogin">{{constant.CUSTOMER_SIGN_UP}} </a>
                                <a class="dropdown-item" [href]="cmoFAQLink" target="_blank">{{constant.HELP_CENTER}} </a>
                                <a class="dropdown-item" [href]="cmoBlogLink" target="_blank">{{constant.OUR_BLOG}} </a>
                                <a class="dropdown-item" routerLink="/contact-us">{{constant.CONTACTUS}} </a>
                            </div>
                        </div>
                    </div>
                    <div class="col-sm-6 text-white pb-3">
                        <div class="dropdown new-dropdown text-center">
                            <button class="btn btn-outline-light dropdown-toggle btn-sm border-white-btn btw-120" type="button" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
                                <span class="text-theme">Delivery Riders</span>
                            </button>
                            <div class="dropdown-menu dropdown-menu-center1 shadow-sm border-0 ">
                                <a class="dropdown-item" [href]="cmoRiderLogin" target="_blank">{{constant.RIDER_LOGIN}}</a>
                                <a class="dropdown-item" [href]="cmoBecomeRider">{{constant.BECOME_A_DELIVERY_RIDER}}</a>
                                <a class="dropdown-item" [href]="cmoRiderResource">{{constant.RIDER_RESIURCES}}</a>
                            </div>
                        </div>
                    </div>
                    <div class="col-sm-6 text-white pb-3">
                        <div class="dropdown new-dropdown text-center">
                            <button class="btn btn-outline-light dropdown-toggle btn-sm border-white-btn btw-120" type="button" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
                                <span class="text-theme">Merchants</span>
                            </button>
                            <div class="dropdown-menu dropdown-menu-center1 shadow-sm border-0 ">
                                <a class="dropdown-item" [href]="businessSignInLink" target="_blank">{{constant.MERCHANT_LOGIN}}</a>
                                <a class="dropdown-item" [href]="businessSignUpLink" target="_blank">{{constant.BECOME_A_NEW_MERCHANT}}</a>
                                <a class="dropdown-item" [href]="cmoMerchantResource" target="_blank">{{constant.MERCHANT_RESOURCES}}</a>
                            </div>
                        </div>
                    </div>
                    <div class="col-sm-6  text-white pb-3">
                        <div class="text-center">
                            <div class="dropdown new-dropdown text-center">
                                <button class="btn btn-outline-light dropdown-toggle btn-sm border-white-btn btw-120" type="button" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
                                    <span class="text-theme">Legal</span>
                                </button>
                                <div class="dropdown-menu dropdown-menu-center1" aria-labelledby="dropdownMenuButton">
                                    <a _ngcontent-agd-c189="" class="dropdown-item" [href]="cmoTermsLink" target="_blank">Terms of Use</a>
                                    <a _ngcontent-agd-c189="" class="dropdown-item" [href]="cmoPolicyLink" target="_blank">Privacy Policy</a>
                                </div>
                            </div>
                        </div>

                    </div>
                </div>
            </div>
            <!-- end view -->
        </section>
    </div>

    <section class="footer-copyright border-top py-3 bg-light">
        <div class="container">
            <div class="row">
                <div class="col-sm-6">
                    <p class="mb-0 tx-bt align-items-center">
                        <a _ngcontent-agd-c189="" target="blank" class="text-dark" href="http://nextgenuscorp.com/">© {{currentYear}} NextGen US Corp</a>
                        <a _ngcontent-agd-c189="" target="blank" class="text-dark pl-4 tm-h" [href]="cmoTermsLink">Terms of Use</a>
                        <a _ngcontent-agd-c189="" target="blank" class="text-dark pl-4 tm-h" [href]="cmoPolicyLink">Privacy Policy</a>
                    </p>
                </div>
                <div class="col-sm-6 order-first order-sm-last">
                    <p class="text-muted mb-0 ml-auto   img-bt align-items-center">
                        <a [href]="contactList?.mobile_app_url?.customer?.ios" class="d-block">
                            <img alt="#" src="assets/img/appstore.png" height="40">
                        </a>
                        <a [href]="contactList?.mobile_app_url?.customer?.android" class="d-block ml-3">
                            <img alt="#" src="assets/img/playmarket.png" height="40">
                        </a>
                    </p>
                    <div class="so-bt align-items-center mt-2">
                        <a class="btn btn-icon btn-outline-dark mr-1 btn-sm" title="Facebook" target="_blank"
                            [href]="contactList?.social_links?.facebook"><i class="feather-facebook"></i>
                        </a>
                        <a class="btn btn-icon btn-outline-dark mr-1 btn-sm" title="Twitter" target="_blank"
                            [href]="contactList?.social_links?.twitter"><i class="feather-twitter"></i>
                        </a>
                        <a class="btn btn-icon btn-outline-dark mr-1 btn-sm" title="Instagram" target="_blank"
                            [href]="contactList?.social_links?.instagram"><i class="feather-instagram"></i>
                        </a>
                        <a class="btn btn-icon btn-outline-dark mr-1 btn-sm" title="Pinterest" target="_blank"
                            [href]="contactList?.social_links?.pinterest"><i class="fab fa-pinterest"></i>
                        </a>
                        <a class="btn btn-icon btn-outline-dark mr-1 btn-sm" title="TikTok" target="_blank"
                            [href]="contactList?.social_links?.tiktok"><i class="fab fa-tiktok"></i>
                        </a>
                        <a class="btn btn-icon btn-outline-dark mr-1 btn-sm" title="Snapchat" target="_blank"
                            [href]="contactList?.social_links?.snapchat"><i class="fab fa-snapchat"></i>
                        </a>
                    </div>
                </div>
            </div>
        </div>
    </section>
</footer>
<!-- <div id="backtotop" *ngIf="!isScroll">
    <a href="javascript:void(0);" (click)="scrollHomeTo()">
        <i class="fas fa-arrow-up"></i>
    </a>
</div> -->

<!-- Sidebar -->
<nav id="main-nav">
    <ul class="top-nav">
        <li *ngIf="!isLogin">
            <a class="sidebar-link" data-router-link="/login"><i class="feather-user mr-2"></i>{{constant.SIGN_IN}}</a>
        </li>
        <li *ngIf="!isLogin">
            <a class="sidebar-link" data-router-link="/signup"><i class="feather-edit mr-2"></i>{{constant.SIGN_UP}}</a>
        </li>
    </ul>
    <ul class="bottom-nav">
        <li class="email" *ngIf="!isLogin">
            <a class="sidebar-link" data-router-link="/get-app-link">
                <p class="h5 m-0"><i class="feather-smartphone"></i></p>
                Get the App
            </a>
        </li>
        <li class="github">
            <a [href]="cmoFAQLink" target="_blank">
                <p class="h5 m-0"><i class="feather-life-buoy"></i></p>
                {{constant.HELP_CENTER}}
            </a>
        </li>
    </ul>
</nav>

<!-- map modal -->
<div class="modal fade" id="exampleModal" tabindex="-1" role="dialog" aria-labelledby="exampleModalLabel"
    aria-hidden="true">
    <div class="modal-dialog modal-dialog-centered">
        <div class="modal-content">
            <div class="modal-header">
                <h5 class="modal-title">NextGen Office Address</h5>
                <button type="button" class="close" data-dismiss="modal" aria-label="Close"
                    [title]="constant.CLOSE_TOOLTIP">
                    <span aria-hidden="true">&times;</span>
                </button>
            </div>
            <div class="modal-body">
                <iframe
                    src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3019.577018172864!2d-73.94145248509209!3d40.81528783922794!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x89c2f676fee21c5d%3A0x2dadc47e59c1d237!2s69%20W%20137th%20St%2C%20New%20York%2C%20NY%2010037%2C%20USA!5e0!3m2!1sen!2sin!4v1614828351755!5m2!1sen!2sin"
                    width="470" height="450" frameborder="0" style="border:0;" allowfullscreen="" aria-hidden="false"
                    tabindex="0" loading="lazy"></iframe>
            </div>
            <!-- <div class="modal-footer p-0 border-0">
                <div class="col-6 m-0 p-0">
                    <button type="button" class="btn border-top btn-lg btn-block" data-dismiss="modal">Close</button>
                </div>
                <div class="col-6 m-0 p-0">
                    <button type="button" class="btn btn-primary btn-lg btn-block">Save changes</button>
                </div>
            </div> -->
        </div>
    </div>
</div>