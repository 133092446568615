import { Injectable } from '@angular/core';
import { GlobalProviderService } from '../providers/global-provider.service';
import { environment } from '../../environments/environment';
import { HelperService } from './helper.service';
import { AngularFirestore } from '@angular/fire/firestore';
import { HttpClient } from '@angular/common/http';

@Injectable({
    providedIn: 'root',
})
export class CmoapiService {

    public cmoUrl = environment.apiUrl;
    public cmoTicketUrl = environment.CMO_TICKET_API;
    public cmoRiderUrl = environment.CMO_RIDER_API;
    public cmoFbUrl = environment.CMO_FB_API;
    public loggedInUser: any;

    constructor(
        private globalProvider: GlobalProviderService,
        private firestore: AngularFirestore,
        public helper: HelperService,
        private http: HttpClient,
    ) { }

    //#region login API

    public verifyCaptcha(token: string) {
        const url = this.cmoUrl + 'verify_captcha';
        return new Promise((resolve, reject) => {
          this.globalProvider.httpPost(url, { token })
            .then(data => {
              resolve(data);
            }, err => {
              reject(err);
            });
        });
    }

    public signIn(loginRequest: any) {
        const url = this.cmoUrl + 'login';
        return new Promise((resolve, reject) => {
            this.globalProvider.httpPost(url, loginRequest)
                .then(data => {
                    resolve(data);
                }, err => {
                    reject(err);
                });
        });
    }

    public getUserByProfileId(obj: object) {
        const url = this.cmoUrl + 'get_user_by_profile_id';
        return new Promise((resolve, reject) => {
            this.globalProvider.httpPost(url, obj)
                .then(data => {
                    resolve(data);
                }, err => {
                    reject(err);
                });
        });
    }

    public signInGoogle(data: object) {
        const url = this.cmoUrl + 'login/google';
        return new Promise((resolve, reject) => {
            this.globalProvider.httpPost(url, data)
                .then(data => {
                    resolve(data);
                }, err => {
                    reject(err);
                });
        });
    }

    public signInFacebook(data: object) {
        const url = this.cmoUrl + 'login/facebook';
        return new Promise((resolve, reject) => {
            this.globalProvider.httpPost(url, data)
                .then(data => {
                    resolve(data);
                }, err => {
                    reject(err);
                });
        });
    }

    public signInApple(data: object) {
        const url = this.cmoUrl + 'login/apple';
        return new Promise((resolve, reject) => {
            this.globalProvider.httpPost(url, data)
                .then(data => {
                    resolve(data);
                }, err => {
                    reject(err);
                });
        });
    }

    //#endregion

    //#region FCM API

    public saveFCMToken(token: any) {
        const url = this.cmoUrl + 'user/firebase_token';
        return new Promise((resolve, reject) => {
            this.globalProvider.httpPost(url, token)
                .then(data => {
                    resolve(data);
                }, err => {
                    reject(err);
                });
        });
    }

    //#endregion

    //#region Profie API

    public getProfile() {
        const url = this.cmoUrl + 'user/profile';
        return new Promise((resolve, reject) => {
            this.globalProvider.httpGet(url)
                .then(data => {
                    resolve(data);
                }, err => {
                    reject(err);
                });
        });
    }

    public updateProfile(data: object) {
        const url = this.cmoUrl + 'user/update_profile';
        return new Promise((resolve, reject) => {
            this.globalProvider.httpPost(url, data)
                .then(data => {
                    resolve(data);
                }, err => {
                    reject(err);
                });
        });
    }

    public getOtp() {
        const url = this.cmoUrl + 'user/send-delete-account-otp';
        return new Promise((resolve, reject) => {
            this.globalProvider.httpPost(url,{})
                .then(data => {
                    resolve(data);
                }, err => {
                    reject(err);
                });
        });
    }

    public updateProfilePicture(data: object) {
        const url = this.cmoUrl + 'user/update_profile_picture';
        return new Promise((resolve, reject) => {
            this.globalProvider.httpPost(url, data)
                .then(data => {
                    resolve(data);
                }, err => {
                    reject(err);
                });
        });
    }

    public deleteProfilePicture() {
        const url = this.cmoUrl + 'user/delete_profile_picture';
        return new Promise((resolve, reject) => {
            this.globalProvider.httpPost(url, {})
                .then(data => {
                    resolve(data);
                }, err => {
                    reject(err);
                });
        });
    }

    public changePassword(data: object) {
        const url = this.cmoUrl + 'user/change_password';
        return new Promise((resolve, reject) => {
            this.globalProvider.httpPost(url, data)
                .then(data => {
                    resolve(data);
                }, err => {
                    reject(err);
                });
        });
    }

    //#endregion

    //#region Signup API

    public signUp(data: object) {
        const url = this.cmoUrl + 'register';
        return new Promise((resolve, reject) => {
            this.globalProvider.httpPost(url, data)
                .then(data => {
                    resolve(data);
                }, err => {
                    reject(err);
                });
        });
    }

    //#endregion

    //#region Forgot API

    public forgotPassword(data: object) {
        const url = this.cmoUrl + 'forgot_password';
        return new Promise((resolve, reject) => {
            this.globalProvider.httpPost(url, data)
                .then(data => {
                    resolve(data);
                }, err => {
                    reject(err);
                });
        });
    }

    //#endregion

    //#region Checkout API

    public placeOrderFromCheckout(data: object) {
        const url = this.cmoUrl + 'cart/v2/place_order';
        return new Promise((resolve, reject) => {
            this.globalProvider.httpPost(url, data)
                .then(data => {
                    resolve(data);
                }, err => {
                    reject(err);
                });
        });
    }

    public placeTableOrderFromCheckout(data: object) {
        const url = this.cmoUrl + 'cart/place_table_order';
        return new Promise((resolve, reject) => {
            this.globalProvider.httpPost(url, data)
                .then(data => {
                    resolve(data);
                }, err => {
                    reject(err);
                });
        });
    }

    public updateOrderType(data: object) {
        const url = this.cmoUrl + 'cart/v2/update_order_type';
        return new Promise((resolve, reject) => {
            this.globalProvider.httpPost(url, data)
                .then(data => {
                    resolve(data);
                }, err => {
                    reject(err);
                });
        });
    }

    //#endregion

    //#region coupon API

    public getAllCoupons() {
        const url = this.cmoUrl + 'coupons/all';
        return new Promise((resolve, reject) => {
            this.globalProvider.httpGet(url)
                .then(data => {
                    resolve(data);
                }, err => {
                    reject(err);
                });
        });
    }

    public getAllOffersRestaurants(obj: object) {
        const url = this.cmoUrl + 'listings/discounts';
        return new Promise((resolve, reject) => {
            this.globalProvider.httpPost(url, obj)
                .then(data => {
                    resolve(data);
                }, err => {
                    reject(err);
                });
        });
    }

    public applyCouponCode(code: any) {
        const url = this.cmoUrl + 'cart/v2/apply_coupon/' + code;
        return new Promise((resolve, reject) => {
            this.globalProvider.httpGet(url)
                .then(data => {
                    resolve(data);
                }, err => {
                    reject(err);
                });
        });
    }

    public removeCouponCode() {
        const url = this.cmoUrl + 'cart/v2/remove_coupon';
        return new Promise((resolve, reject) => {
            this.globalProvider.httpGet(url)
                .then(data => {
                    resolve(data);
                }, err => {
                    reject(err);
                });
        });
    }

    //#endregion

    //#region Resend OTP, Verify OTP, Resend Email Verification, Reset Password

    public verifyOTP(data: object) {
        const url = this.cmoUrl + 'verify_otp';
        return new Promise((resolve, reject) => {
            this.globalProvider.httpPost(url, data)
                .then(data => {
                    resolve(data);
                }, err => {
                    reject(err);
                });
        });
    }

    public resendOTP(data: object) {
        const url = this.cmoUrl + 'resend_otp';
        return new Promise((resolve, reject) => {
            this.globalProvider.httpPost(url, data)
                .then(data => {
                    resolve(data);
                }, err => {
                    reject(err);
                });
        });
    }

    public resendEmailVerification(data: object) {
        const url = this.cmoUrl + 'resend_verification_email';
        return new Promise((resolve, reject) => {
            this.globalProvider.httpPost(url, data)
                .then(data => {
                    resolve(data);
                }, err => {
                    reject(err);
                });
        });
    }

    public resetPassword(token: any, data: object) {
        const url = this.cmoUrl + 'reset_password?token=' + token;
        return new Promise((resolve, reject) => {
            this.globalProvider.httpPost(url, data)
                .then(data => {
                    resolve(data);
                }, err => {
                    reject(err);
                });
        });
    }

    //#endregion

    //#region Address API

    public getUserAllAddresses(flag?: boolean) {
        const url = this.cmoUrl + `user/addresses/${flag}`;
        return new Promise((resolve, reject) => {
            this.globalProvider.httpGet(url)
                .then(data => {
                    resolve(data);
                }, err => {
                    reject(err);
                });
        });
    }

    public getUserSingleAddress(id: any) {
        const url = this.cmoUrl + 'user/addresses/' + id;
        return new Promise((resolve, reject) => {
            this.globalProvider.httpGet(url)
                .then(data => {
                    resolve(data);
                }, err => {
                    reject(err);
                });
        });
    }

    public addNewAddress(data: object) {
        const url = this.cmoUrl + 'user/addresses';
        return new Promise((resolve, reject) => {
            this.globalProvider.httpPost(url, data)
                .then(data => {
                    resolve(data);
                }, err => {
                    reject(err);
                });
        });
    }

    public updateAddress(id: string, data: object) {
        const url = this.cmoUrl + 'user/addresses/update/' + id;
        return new Promise((resolve, reject) => {
            this.globalProvider.httpPost(url, data)
                .then(data => {
                    resolve(data);
                }, err => {
                    reject(err);
                });
        });
    }

    public deleteAddress(id: string) {
        const url = this.cmoUrl + 'user/addresses/delete/' + id;
        return new Promise((resolve, reject) => {
            this.globalProvider.httpPost(url, '')
                .then(data => {
                    resolve(data);
                }, err => {
                    reject(err);
                });
        });
    }

    //#endregion

    //#region Notification API

    public getAllNotifications(type?: string) {
        const url = this.cmoUrl + `notifications?type=${type || null}`;
        return new Promise((resolve, reject) => {
            this.globalProvider.httpGet(url)
                .then(data => {
                    resolve(data);
                }, err => {
                    reject(err);
                });
        });
    }

    public markAsReadNotification(notificationId: string) {
        const url = this.cmoUrl + 'notifications/read/' + notificationId;
        return new Promise((resolve, reject) => {
            this.globalProvider.httpGet(url)
                .then(data => {
                    resolve(data);
                }, err => {
                    reject(err);
                });
        });
    }

    public deleteNotification(notificationId: string) {
        const url = this.cmoUrl + 'notifications/delete/' + notificationId;
        return new Promise((resolve, reject) => {
            this.globalProvider.httpGet(url)
                .then(data => {
                    resolve(data);
                }, err => {
                    reject(err);
                });
        });
    }

    public readAllNotifications() {
        const url = this.cmoUrl + 'notifications/read_all';
        return new Promise((resolve, reject) => {
            this.globalProvider.httpGet(url)
                .then(data => {
                    resolve(data);
                }, err => {
                    reject(err);
                });
        });
    }

    public deleteAllNotifications() {
        const url = this.cmoUrl + 'notifications/delete_all';
        return new Promise((resolve, reject) => {
            this.globalProvider.httpGet(url)
                .then(data => {
                    resolve(data);
                }, err => {
                    reject(err);
                });
        });
    }

    //#endregion

    //#region Contact Us API

    public getContactDetails() {
        const url = this.cmoUrl + 'contact';
        return new Promise((resolve, reject) => {
            this.globalProvider.httpGet(url)
                .then(data => {
                    resolve(data);
                }, err => {
                    reject(err);
                });
        });
    }

    //#endregion

    //#region Search Location API

    public getFilterByLocation(data: object, pageNo: number, pageSize: number) {
        const url = this.cmoUrl + 'listings/filter?page=' + pageNo + '&per_page=' + pageSize;
        return new Promise((resolve, reject) => {
            this.globalProvider.httpPost(url, data)
                .then(data => {
                    resolve(data);
                }, err => {
                    reject(err);
                });
        });
    }

    //#endregion

    //#region Filter Option API

    public getAllFulfillmentTypes() {
        const url = this.cmoUrl + 'listings/fulfillment_types';
        return new Promise((resolve, reject) => {
            this.globalProvider.httpGet(url)
                .then(data => {
                    resolve(data);
                }, err => {
                    reject(err);
                });
        });
    }

    public getAllTags() {
        const url = this.cmoUrl + 'listings/tags';
        return new Promise((resolve, reject) => {
            this.globalProvider.httpGet(url)
                .then(data => {
                    resolve(data);
                }, err => {
                    reject(err);
                });
        });
    }

    public getAllCategories() {
        const url = this.cmoUrl + 'listings/categories';
        return new Promise((resolve, reject) => {
            this.globalProvider.httpGet(url)
                .then(data => {
                    resolve(data);
                }, err => {
                    reject(err);
                });
        });
    }

    //#endregion

    //#region Favourite Store API

    public getFavouriteStores(obj?: object) {
        const url = this.cmoUrl + 'user/favorites';
        return new Promise((resolve, reject) => {
            this.globalProvider.httpPost(url, {})
                .then(data => {
                    resolve(data);
                }, err => {
                    reject(err);
                });
        });
    }

    public addFavouriteStore(storeId: string) {
        const url = this.cmoUrl + 'user/favorites/' + storeId;
        return new Promise((resolve, reject) => {
            this.globalProvider.httpPost(url, '')
                .then(data => {
                    resolve(data);
                }, err => {
                    reject(err);
                });
        });
    }

    public removeFavouriteStore(storeId: string) {
        const url = this.cmoUrl + 'user/favorites/remove/' + storeId;
        return new Promise((resolve, reject) => {
            this.globalProvider.httpPost(url, '')
                .then(data => {
                    resolve(data);
                }, err => {
                    reject(err);
                });
        });
    }

    //#endregion

    //#region Restaurant API

    public getRestaurantDetailById(id: string) {
        const url = this.cmoUrl + 'listings/listing/' + id;
        return new Promise((resolve, reject) => {
            this.globalProvider.httpGet(url)
                .then(data => {
                    resolve(data);
                }, err => {
                    reject(err);
                });
        });
    }

    public getRestaurantMenuDetails(id: string) {
        const url = this.cmoUrl + 'listings/v2/listing/' + id + '/menu';
        return new Promise((resolve, reject) => {
            this.globalProvider.httpGet(url)
                .then(data => {
                    console.log('data--------------', data)
                    resolve(data);
                }, err => {
                    reject(err);
                });
        });
    }

    //#endregion

    //#region Order API

    public getOrders(pageNo: number, pageSize: number) {
        const url = this.cmoUrl + 'user/order?page=' + pageNo + '&per_page=' + pageSize;
        return new Promise((resolve, reject) => {
            this.globalProvider.httpGet(url)
                .then(data => {
                    resolve(data);
                }, err => {
                    reject(err);
                });
        });
    }

    public getAllFilterOrder(obj: object, pageNo: number, pageSize: number) {
        const url = this.cmoUrl + 'user/filter_orders?page=' + pageNo + '&per_page=' + pageSize;
        return new Promise((resolve, reject) => {
            this.globalProvider.httpPost(url, obj)
                .then(data => {
                    resolve(data);
                }, err => {
                    reject(err);
                });
        });
    }

    public getSingleOrder(id: string) {
        const url = this.cmoUrl + 'user/order/' + id;
        return new Promise((resolve, reject) => {
            this.globalProvider.httpGet(url)
                .then(data => {
                    resolve(data);
                }, err => {
                    reject(err);
                });
        });
    }

    public reOrderSingleOrder(id: string) {
        const url = this.cmoUrl + 'user/repeat_order/' + id;
        return new Promise((resolve, reject) => {
            this.globalProvider.httpGet(url)
                .then(data => {
                    resolve(data);
                }, err => {
                    reject(err);
                });
        });
    }

    public refundRequestSingleOrder(id: string, obj: object) {
        const url = this.cmoUrl + 'orders/request_refund/' + id;
        return new Promise((resolve, reject) => {
            this.globalProvider.httpPost(url, obj)
                .then(data => {
                    resolve(data);
                }, err => {
                    reject(err);
                });
        });
    }

    //#endregion

    //#region Cart API

    public getNormalCartDetail() {
        const url = this.cmoUrl + 'cart/v2';
        return new Promise((resolve, reject) => {
            this.globalProvider.httpGet(url)
                .then(data => {
                    resolve(data);
                }, err => {
                    reject(err);
                });
        });
    }

    public deleteNormalCartItem(storeId: string, cartItemId: any) {
        const url = this.cmoUrl + 'cart/v2/delete_item/' + storeId + '/' + cartItemId;
        return new Promise((resolve, reject) => {
            this.globalProvider.httpPost(url, '')
                .then(data => {
                    resolve(data);
                }, err => {
                    reject(err);
                });
        });
    }

    public repeatNormalCartItem(storeId: string, cartItemId: string) {
        const url = this.cmoUrl + 'cart/v2/repeat_item/' + storeId + '/' + cartItemId;
        return new Promise((resolve, reject) => {
            this.globalProvider.httpPost(url, '')
                .then(data => {
                    resolve(data);
                }, err => {
                    reject(err);
                });
        });
    }

    public removeNormalCartItem(storeId: string, cartItemId: string) {
        const url = this.cmoUrl + 'cart/v2/remove_item/' + storeId + '/' + cartItemId;
        return new Promise((resolve, reject) => {
            this.globalProvider.httpPost(url, {})
                .then(data => {
                    resolve(data);
                }, err => {
                    reject(err);
                });
        });
    }

    public addNormalCartItem(storeId: string, cartItemId: string, data: object) {
        // const url = this.cmoUrl + 'cart/add_item/' + storeId + '/' + cartItemId;
        const url = this.cmoUrl + 'cart/v2/add_item/' + storeId + '/' + cartItemId;
        return new Promise((resolve, reject) => {
            this.globalProvider.httpPost(url, data)
                .then(data => {
                    resolve(data);
                }, err => {
                    reject(err);
                });
        });
    }

    public updateNormalCartItem(storeId: string, cartItemId: string, data: object) {
        const url = this.cmoUrl + 'cart/v2/update_item/' + storeId + '/' + cartItemId;
        return new Promise((resolve, reject) => {
            this.globalProvider.httpPost(url, data)
                .then(data => {
                    resolve(data);
                }, err => {
                    reject(err);
                });
        });
    }

    public getAddedNormalCartItemDetails(storeId: string, itemId: string, obj: object) {
        const url = this.cmoUrl + `listings/v2/listing/${storeId}/menu_items/${itemId}`;
        return new Promise((resolve, reject) => {
            this.globalProvider.httpPost(url, obj)
                .then(data => {
                    resolve(data);
                }, err => {
                    reject(err);
                });
        });
    }

    public reorderCartItems(itemId: string) {
        const url = this.cmoUrl + 'user/repeat_order/' + itemId;
        return new Promise((resolve, reject) => {
            this.globalProvider.httpGet(url)
                .then(data => {
                    resolve(data);
                }, err => {
                    reject(err);
                });
        });
    }

    public clearNormalCartItems() {
        const url = this.cmoUrl + 'cart/v2/clear/';
        return new Promise((resolve, reject) => {
            this.globalProvider.httpPost(url, '')
                .then(data => {
                    resolve(data);
                }, err => {
                    reject(err);
                });
        });
    }

    //#endregion

    //#region discount API

    public applyDiscountOnCart(id: string) {
        const url = this.cmoUrl + 'cart/v2/apply_discount/' + id;
        return new Promise((resolve, reject) => {
            this.globalProvider.httpGet(url)
                .then(data => {
                    resolve(data);
                }, err => {
                    reject(err);
                });
        });
    }

    public removeDiscountOnCart() {
        const url = this.cmoUrl + 'cart/v2/remove_discount/';
        return new Promise((resolve, reject) => {
            this.globalProvider.httpGet(url)
                .then(data => {
                    resolve(data);
                }, err => {
                    reject(err);
                });
        });
    }

    //#endregion

    //#region Checkout API

    public checkoutPlaceOrder(data: object) {
        const url = this.cmoUrl + 'cart/v2/place_order';
        return new Promise((resolve, reject) => {
            this.globalProvider.httpPost(url, data)
                .then(data => {
                    resolve(data);
                }, err => {
                    reject(err);
                });
        });
    }

    public createCheckoutAndPlaceOrder(data: object) {
        const url = this.cmoUrl + 'cart/v2/checkout_pay';
        return new Promise((resolve, reject) => {
            this.globalProvider.httpPost(url, data)
                .then(data => {
                    resolve(data);
                }, err => {
                    reject(err);
                });
        });
    }

    public checkoutPlaceTableOrder(data: object) {
        const url = this.cmoUrl + 'cart/place_table_order';
        return new Promise((resolve, reject) => {
            this.globalProvider.httpPost(url, data)
                .then(data => {
                    resolve(data);
                }, err => {
                    reject(err);
                });
        });
    }

    public checkoutUpdateOrderType(data: object) {
        const url = this.cmoUrl + 'cart/update_order_type';
        return new Promise((resolve, reject) => {
            this.globalProvider.httpPost(url, data)
                .then(data => {
                    resolve(data);
                }, err => {
                    reject(err);
                });
        });
    }

    public checkoutCreatePaymentIntent() {
        const url = this.cmoUrl + 'cart/v2/checkout';
        return new Promise((resolve, reject) => {
            this.globalProvider.httpPost(url, '')
                .then(data => {
                    resolve(data);
                }, err => {
                    reject(err);
                });
        });
    }

    public getAllBanners() {
        const url = this.cmoUrl + 'banners';
        return new Promise((resolve, reject) => {
            this.globalProvider.httpGet(url)
                .then(data => {
                    resolve(data);
                }, err => {
                    reject(err);
                });
        });
    }

    public getHomePageTrandingRestaurantsList(filterObj: any) {
        const url = this.cmoUrl + 'listings/filter';
        return new Promise((resolve, reject) => {
            this.globalProvider.httpPost(url, filterObj)
                .then(data => {
                    resolve(data);
                }, err => {
                    reject(err);
                });
        });
    }

    getHomePagePopularRestaurantsList(filterObj: any) {
        const url = this.cmoUrl + 'listings/filter';
        return new Promise((resolve, reject) => {
            this.globalProvider.httpPost(url, filterObj)
                .then(data => {
                    resolve(data);
                }, err => {
                    reject(err);
                });
        });
    }

    //#endregion

    //#region Group Order API

    public getGroupOrder() {
        const url = this.cmoUrl + 'group_order/carts';
        return new Promise((resolve, reject) => {
            this.globalProvider.httpGet(url)
                .then(data => {
                    resolve(data);
                }, err => {
                    reject(err);
                });
        });
    }

    public getGroupOrderId(groupId: string) {
        const url = this.cmoUrl + 'group_order/' + groupId + '/carts';
        return new Promise((resolve, reject) => {
            this.globalProvider.httpGet(url)
                .then(data => {
                    resolve(data);
                }, err => {
                    reject(err);
                });
        });
    }

    public createGroupOrder(storeId: string, data: object) {
        const url = this.cmoUrl + 'group_order/start/' + storeId;
        return new Promise((resolve, reject) => {
            this.globalProvider.httpPost(url, data)
                .then(data => {
                    resolve(data);
                }, err => {
                    reject(err);
                });
        });
    }

    public updateGroupOrder(groupId: string, data: object) {
        const url = this.cmoUrl + 'group_order/' + groupId + '/update_order_type';
        return new Promise((resolve, reject) => {
            this.globalProvider.httpPost(url, data)
                .then(data => {
                    resolve(data);
                }, err => {
                    reject(err);
                });
        });
    }

    public inviteGroupOrder(groupId: string, data: object) {
        const url = this.cmoUrl + 'group_order/' + groupId + '/invite/';
        return new Promise((resolve, reject) => {
            this.globalProvider.httpPost(url, data)
                .then(data => {
                    resolve(data);
                }, err => {
                    reject(err);
                });
        });
    }

    public cancelGroupOrder(groupId: string) {
        const url = this.cmoUrl + 'group_order/' + groupId + '/cancel';
        return new Promise((resolve, reject) => {
            this.globalProvider.httpGet(url)
                .then(data => {
                    resolve(data);
                }, err => {
                    reject(err);
                });
        });
    }

    public joinGroupOrder(groupId: string) {
        const url = this.cmoUrl + 'group_order/' + groupId + '/join';
        return new Promise((resolve, reject) => {
            this.globalProvider.httpGet(url)
                .then(data => {
                    resolve(data);
                }, err => {
                    reject(err);
                });
        });
    }

    public leaveGroupOrder(groupId: string) {
        const url = this.cmoUrl + 'group_order/' + groupId + '/leave';
        return new Promise((resolve, reject) => {
            this.globalProvider.httpGet(url)
                .then(data => {
                    resolve(data);
                }, err => {
                    reject(err);
                });
        });
    }

    public addGroupOrderItem(groupId: string, cartId: string, obj: object) {
        const url = this.cmoUrl + 'group_order/' + groupId + '/add_item/' + cartId;
        return new Promise((resolve, reject) => {
            this.globalProvider.httpPost(url, obj)
                .then(data => {
                    resolve(data);
                }, err => {
                    reject(err);
                });
        });
    }

    public updateGroupOrderItem(groupId: string, cartId: string, cartItemId: string, obj: object) {
        const url = this.cmoUrl + `group_order/${groupId}/update_item/${cartId}/${cartItemId}`;
        return new Promise((resolve, reject) => {
            this.globalProvider.httpPost(url, obj)
                .then(data => {
                    resolve(data);
                }, err => {
                    reject(err);
                });
        });
    }

    public deleteGroupOrderItem(groupId: string, cartId: string, cartItemId: string) {
        const url = this.cmoUrl + 'group_order/' + groupId + '/delete_item/' + cartId + '/' + cartItemId;
        return new Promise((resolve, reject) => {
            this.globalProvider.httpPost(url, '')
                .then(data => {
                    resolve(data);
                }, err => {
                    reject(err);
                });
        });
    }

    public removeGroupOrderItem(groupId: string, cartId: string, cartItemId: string) {
        const url = this.cmoUrl + 'group_order/' + groupId + '/remove_item/' + cartId + '/' + cartItemId;
        return new Promise((resolve, reject) => {
            this.globalProvider.httpPost(url, '')
                .then(data => {
                    resolve(data);
                }, err => {
                    reject(err);
                });
        });
    }

    public repeatGroupOrderItem(groupId: string, cartId: string, cartItemId: string) {
        const url = this.cmoUrl + 'group_order/' + groupId + '/repeat_item/' + cartId + '/' + cartItemId;
        return new Promise((resolve, reject) => {
            this.globalProvider.httpPost(url, '')
                .then(data => {
                    resolve(data);
                }, err => {
                    reject(err);
                });
        });
    }

    public confirmGroupOrderItemByFriend(groupId: string, cartId: string, obj: object) {
        const url = this.cmoUrl + 'group_order/' + groupId + '/submit_cart/' + cartId;
        return new Promise((resolve, reject) => {
            this.globalProvider.httpPost(url, obj)
                .then(data => {
                    resolve(data);
                }, err => {
                    reject(err);
                });
        });
    }

    public modifyGroupOrderItemByFriend(groupId: string, cartId: string) {
        const url = this.cmoUrl + 'group_order/' + groupId + '/modify_submit_cart/' + cartId;
        return new Promise((resolve, reject) => {
            this.globalProvider.httpGet(url)
                .then(data => {
                    resolve(data);
                }, err => {
                    reject(err);
                });
        });
    }

    public deleteGroupMemberByHost(groupId: string, cartCustomerId: string) {
        const url = this.cmoUrl + 'group_order/' + groupId + '/remove_cart/' + cartCustomerId;
        return new Promise((resolve, reject) => {
            this.globalProvider.httpPost(url, {})
                .then(data => {
                    resolve(data);
                }, err => {
                    reject(err);
                });
        });
    }

    public applyGroupOrderDiscount(groupId: string, discountId: string) {
        const url = this.cmoUrl + 'group_order/' + groupId + '/apply_discount/' + discountId;
        return new Promise((resolve, reject) => {
            this.globalProvider.httpGet(url)
                .then(data => {
                    resolve(data);
                }, err => {
                    reject(err);
                });
        });
    }

    public removeGroupOrderDiscount(groupId: string) {
        const url = this.cmoUrl + 'group_order/' + groupId + '/remove_discount';
        return new Promise((resolve, reject) => {
            this.globalProvider.httpGet(url)
                .then(data => {
                    resolve(data);
                }, err => {
                    reject(err);
                });
        });
    }

    public applyGroupOrderCoupon(groupId: string, couponId: string) {
        const url = this.cmoUrl + 'group_order/' + groupId + '/apply_coupon/' + couponId;
        return new Promise((resolve, reject) => {
            this.globalProvider.httpGet(url)
                .then(data => {
                    resolve(data);
                }, err => {
                    reject(err);
                });
        });
    }

    public removeGroupOrderCoupon(groupId: string) {
        const url = this.cmoUrl + 'group_order/' + groupId + '/remove_coupon';
        return new Promise((resolve, reject) => {
            this.globalProvider.httpGet(url)
                .then(data => {
                    resolve(data);
                }, err => {
                    reject(err);
                });
        });
    }

    public groupOrderPaymentIntent(groupId: string) {
        const url = this.cmoUrl + 'group_order/' + groupId + '/checkout';
        return new Promise((resolve, reject) => {
            this.globalProvider.httpPost(url, '')
                .then(data => {
                    resolve(data);
                }, err => {
                    reject(err);
                });
        });
    }

    public groupOrderPlaceOrder(groupId: string, obj: object) {
        const url = this.cmoUrl + 'group_order/' + groupId + '/place_order';
        return new Promise((resolve, reject) => {
            this.globalProvider.httpPost(url, obj)
                .then(data => {
                    resolve(data);
                }, err => {
                    reject(err);
                });
        });
    }

    public createCheckoutAndPlaceGroupOrder(groupId: string, obj: object) {
        const url = this.cmoUrl + 'group_order/' + groupId + '/checkout_pay';
        return new Promise((resolve, reject) => {
            this.globalProvider.httpPost(url, obj)
                .then(data => {
                    resolve(data);
                }, err => {
                    reject(err);
                });
        });
    }

    public getUpdatedDeliveryStatus(orderId: string) {
        const url = this.cmoUrl + 'store/order/update_delivery_status/' + orderId;
        return new Promise((resolve, reject) => {
            this.globalProvider.httpGet(url)
                .then(data => {
                    resolve(data);
                }, err => {
                    reject(err);
                });
        });
    }

    //#endregion

    //#region rating and review API

    public giveOrderRatingAndReview(storeId: string, obj: object) {
        const url = this.cmoUrl + 'listings/listing/' + storeId + '/reviews';
        return new Promise((resolve, reject) => {
            this.globalProvider.httpPost(url, obj)
                .then(data => {
                    resolve(data);
                }, err => {
                    reject(err);
                });
        });
    }

    public giveDeliveryBoyRatingAndReview(storeId: string, obj: object) {
        const url = this.cmoUrl + 'listings/listing/' + storeId + '/delivery_reviews';
        return new Promise((resolve, reject) => {
            this.globalProvider.httpPost(url, obj)
                .then(data => {
                    resolve(data);
                }, err => {
                    reject(err);
                });
        });
    }

    //#endregion

    //#region Get App Link

    public getAppLink(obj: object) {
        const url = this.cmoUrl + 'get_app_link';
        return new Promise((resolve, reject) => {
            this.globalProvider.httpPost(url, obj)
                .then(data => {
                    resolve(data);
                }, err => {
                    reject(err);
                });
        });
    }

    //#endregion

    //#region FCM API

    public deleteAllFCMToken() {
        const url = this.cmoUrl + 'user/remove_all_firebase_token';
        return new Promise((resolve, reject) => {
            this.globalProvider.httpGet(url)
                .then(data => {
                    resolve(data);
                }, err => {
                    reject(err);
                });
        });
    }

    public unsubscribePushNotification() {
        const url = this.cmoUrl + 'user/unsubscribe_push_notifications';
        return new Promise((resolve, reject) => {
            this.globalProvider.httpGet(url)
                .then(data => {
                    resolve(data);
                }, err => {
                    reject(err);
                });
        });
    }

    public subscribePushNotification() {
        const url = this.cmoUrl + 'user/subscribe_push_notifications';
        return new Promise((resolve, reject) => {
            this.globalProvider.httpGet(url)
                .then(data => {
                    resolve(data);
                }, err => {
                    reject(err);
                });
        });
    }

    //#endregion

    //#region CMO Rider Live Location API

    public getCMORiderCurrentLocation() {
        let usersCollection = this.firestore.collection('orderTracking');
        return usersCollection.valueChanges();
    }

    public getMaintenanceMode() {
        let maintenanceCollection = this.firestore.collection('/globalSettings');
        return maintenanceCollection.valueChanges();
    }

    //#endregion

    //#region Card API

    public userCardList() {
        const url = this.cmoUrl + `user/cards`;
        return new Promise((resolve, reject) => {
            this.globalProvider.httpGet(url)
                .then(res => {
                    resolve(res);
                }, err => {
                    reject(err);
                });
        });
    }

    public detachSingleCard(obj: object) {
        const url = this.cmoUrl + `user/cards/detach`;
        return new Promise((resolve, reject) => {
            this.globalProvider.httpPost(url, obj)
            .then(res => {
                resolve(res);
            }, err => {
                reject(err);
            });
        });
    }

    public addNewCard(data: object) {
        const url = this.cmoUrl + 'user/cards/add';
        return new Promise((resolve, reject) => {
            this.globalProvider.httpPost(url, data)
            .then(data => {
                resolve(data);
            }, err => {
                reject(err);
            });
        });
    }

    public checkCard(data: object) {
        const url = this.cmoUrl + 'user/cards/checker';
        return new Promise((resolve, reject) => {
            this.globalProvider.httpPost(url, data)
            .then(data => {
                resolve(data);
            }, err => {
                reject(err);
            });
        });
    }

    public updateCard(data: object, paymentId: string) {
        const url = this.cmoUrl + `user/cards/update/${paymentId}`;
        return new Promise((resolve, reject) => {
            this.globalProvider.httpPost(url, data)
            .then(data => {
                resolve(data);
            }, err => {
                reject(err);
            });
        });
    }

    //#endregion

    //#region user most used item API

    public userMostUsedItemList(storeId: string) {
        const url = this.cmoUrl + `user/${storeId}/favourite_items`;
        return new Promise((resolve, reject) => {
            this.globalProvider.httpGet(url)
                .then(res => {
                    resolve(res);
                }, err => {
                    reject(err);
                });
        });
    }

    //#endregion

    //#region user tickets API

    public createTicketUsingExternal(obj: object) {
        const url = this.cmoTicketUrl + `api/v1/ticket`;
        return new Promise((resolve, reject) => {
            this.globalProvider.httpPostThirdParty(url, obj).then(res => {
                resolve(res);
            }, err => {
                reject(err);
            });
        });
    }

    public getSingleTicketUsingExternal(ticketId: any) {
        const url = this.cmoTicketUrl + `api/v1/ticket/${ticketId}`;
        return new Promise((resolve, reject) => {
            this.globalProvider.httpGetThirdParty(url).then(res => {
                resolve(res);
            }, err => {
                reject(err);
            });
        });
    }

    public ticketThreadUsingExternal(ticketId: any, obj: object) {
        const url = this.cmoTicketUrl + `api/v1/ticket/${ticketId}/thread`;
        return new Promise((resolve, reject) => {
          this.globalProvider.httpPostThirdParty(url, obj).then(res => {
            resolve(res);
          }, err => {
            reject(err);
          });
        });
      }

    public saveUserTicket(obj: object) {
        const url = this.cmoUrl + `user-tickets/ticket/save`;
        return new Promise((resolve, reject) => {
            this.globalProvider.httpPost(url, obj)
                .then(res => {
                    resolve(res);
                }, err => {
                    reject(err);
                });
        });
    }

    public getUserTicket() {
        const url = this.cmoUrl + `user-tickets/ticket/get`;
        return new Promise((resolve, reject) => {
            this.globalProvider.httpGet(url)
                .then(res => {
                    resolve(res);
                }, err => {
                    reject(err);
                });
        });
    }

    //#endregion
         
   //#region delete user account
  public deleteUser(data:object) {
    const url = this.cmoUrl + `user/delete-account`;
    return new Promise((resolve, reject) => {
      this.globalProvider.httpPost(url,data)
        .then(data => {
          resolve(data);
        }, err => {
          reject(err);
        });
    });
  }
  //#end region

    //#region check near by riders

    public getNearByRiders(lat: number, lng: number) {
        const url = this.cmoRiderUrl + `checkNearbyRiders?latitude=${lat}&longitude=${lng}`;
        return new Promise((resolve, reject) => {
            this.globalProvider.httpGetThirdParty(url)
                .then(res => {
                    resolve(res);
                }, err => {
                    reject(err);
                });
        });
    }

    public checkServiceZone(lat: number, lng: number) {
        const url = this.cmoRiderUrl + `checkServiceZones?latitude=${lat}&longitude=${lng}`;
        return new Promise((resolve, reject) => {
            this.globalProvider.httpGetThirdParty(url).then(res => {
                resolve(res);
            }, err => {
                reject(err);
            });
        });
    }

    public checkETA(zipcode: any, time: number) {
        const url = this.cmoRiderUrl + `getEstimatedDeliveryTime?zipcode=${zipcode}&prepTime=${time}`;
        return new Promise((resolve, reject) => {
            this.globalProvider.httpGetThirdParty(url).then(res => {
                resolve(res);
            }, err => {
                reject(err);
            });
        });
    }

    public addCMORiderRating(orderId: any, rating: number) {
        const url = this.cmoRiderUrl + `rateRider?deliveryId=${orderId}&rating=${rating}`;
        return new Promise((resolve, reject) => {
            this.globalProvider.httpGetThirdParty(url).then(res => {
                resolve(res);
            }, err => {
                reject(err);
            });
        });
    }

    public getCMORiderAnalytics(orderId: any) {
        const url = this.cmoRiderUrl + `getOrderAnalytics?deliveryId=${orderId}`;
        return new Promise((resolve, reject) => {
            this.globalProvider.httpGetThirdParty(url).then(res => {
                resolve(res);
            }, err => {
                reject(err);
            });
        });
    }

    //#endregion

    //#region get order status list

    public getOrderStatusObjList(type: string, isEta: boolean, currentStatus: string) {
        const orderStatusObj = environment.ENV == 'staging' ? 'orderStatusObjectStaging' : (environment.ENV == 'production') ? 'orderStatusObjectProd' : 'orderStatusObjectDev';
        const url = this.cmoFbUrl + `${orderStatusObj}?type=${type}&is_eta=${isEta}&current_status=${currentStatus}`;
        return new Promise((resolve, reject) => {
            this.globalProvider.httpGetThirdParty(url).then(res => {
                resolve(res);
            }, err => {
                reject(err);
            });
        });
    }

    //#endregion

    //#region Delivery tip API

    public applyOrderDeliveryTip(orderId: string, obj: object) {
        const url = this.cmoUrl + `orders/${orderId}/apply_delivery_tip`;
        return new Promise((resolve, reject) => {
            this.globalProvider.httpPost(url, obj).then(res => {
                resolve(res);
            }, err => {
                reject(err);
            });
        });
    }

    //#endregion

    //#region order API

    public cancelSingleOrder(orderId: string, obj: object) {
        const url = this.cmoUrl + `orders/cancel_order/${orderId}`;
        return new Promise((resolve, reject) => {
            this.globalProvider.httpPost(url, obj).then(res => {
                resolve(res);
            }, err => {
                reject(err);
            });
        });
    }

    public confirmSingleOrder(orderId: string) {
        const url = this.cmoUrl + `orders/confirm_order/${orderId}`;
        return new Promise((resolve, reject) => {
            this.globalProvider.httpGet(url).then(res => {
                resolve(res);
            }, err => {
                reject(err);
            });
        });
    }

    public updateSingleOrder(orderId: string, obj: object) {
        const url = this.cmoUrl + `orders/update_order_type/${orderId}`;
        return new Promise((resolve, reject) => {
            this.globalProvider.httpPost(url, obj).then(res => {
                resolve(res);
            }, err => {
                reject(err);
            });
        });
    }

    //#endregion

    //#region Token

    public updateRefreshToken(obj: object) {
        const url = this.cmoUrl + `user/refresh_token`;
        return new Promise((resolve, reject) => {
            this.globalProvider.httpPost(url, obj).then(res => {
                resolve(res);
            }, err => {
                reject(err);
            });
        });
    }

    //#endregion
}
