import { Component, OnInit, ElementRef, ViewChild, ChangeDetectorRef, ComponentRef } from '@angular/core';
import { FormGroup, FormControl, Validators } from '@angular/forms';
import { HelperService } from 'src/app/services/helper.service';
import { CmoapiService } from 'src/app/services/cmoWebsiteApi.service';
import { ToastrService } from 'ngx-toastr';
import { SimpleModalComponent, SimpleModalService } from 'ngx-simple-modal';
import { AddressAddUpdateModel } from 'src/app/models/general';
import { MessageService } from 'src/app/services/message.service';
import { ConstantProviderService } from 'src/app/providers/constant-provider.service';
declare var google: any;

@Component({
  selector: 'app-address-pop-up',
  templateUrl: './address-pop-up.component.html',
  styleUrls: ['./address-pop-up.component.css']
})

export class AddressPopUpComponent extends SimpleModalComponent<AddressAddUpdateModel, null> implements AddressAddUpdateModel, OnInit {

  //#region variables
  
  @ViewChild('gMap', { static: true }) gMap: ElementRef;

  public addressForm: FormGroup;

  public data: any;
  public addressObject: any;
  public from: any;
  public addressModeLabel: any;
  public autoCompleteService: any;
  public placeService: any;
  public map: any;
  public currentLocation: any;
  
  public mode: string;
  public addressId: string;
  public deleteAddressId: string;
  public defualtAddressFromArrayID: string;

  public places: any = [];

  public placeComponents = this.helperService.placeComponents;
  public autoCompleteElements = this.helperService.autoCompleteElements;

  public hasLocation = false;
  public isDefualtAddress = false;

  //#endregion

  //#region life cycle hook

  constructor(
    private helperService: HelperService,
    private cmoApiService: CmoapiService,
    private cdr: ChangeDetectorRef,
    private toastr: ToastrService,
    private messageService: MessageService,
    public constant: ConstantProviderService
  ) {
    super();
    this.addressObject = {
      label: null,
      firstname: null,
      lastname: null,
      street: null,
      address_line_2: null,
      city: null,
      state: null,
      country: null,
      zipcode: null,
      mobile: null,
      email: null,
      latitude: null,
      longitude: null,
      default: false,
      delivery_instruction: null,
      is_permanent: false
    };
    this.intilizeForm();
  }
  
  ngOnInit(): void {
    this.map = new google.maps.Map(this.gMap.nativeElement);
    this.autoCompleteService = new google.maps.places.AutocompleteService();
    this.placeService = new google.maps.places.PlacesService(this.map);
    
    this.addressModeLabel = this.mode;

    if (this.mode == 'edit') {
      this.addressId = this.data.id;
      this.addressObject.label = this.data.label;
      this.addressObject.street = this.data.street;
      this.addressObject.address_line_2 = this.data.address_line_2;
      this.addressObject.city = this.data.city;
      this.addressObject.state = this.data.state;
      this.addressObject.country = this.data.country;
      this.addressObject.zipcode = this.data.zipcode;
      this.addressObject.latitude = this.data.latitude.toString();
      this.addressObject.longitude = this.data.longitude.toString();
      this.addressObject.firstname = this.data.firstname;
      this.addressObject.lastname = this.data.lastname;
      this.addressObject.default = (this.data.default === null || this.data.default === false) ? false : true;
      this.addressObject.email = this.data.email,
      this.addressObject.mobile = this.data.mobile,
      this.addressObject.delivery_instruction = this.data.delivery_instruction;
      this.addressObject.is_permanent = this.data.is_permanent;

      this.addressForm.get('street')?.setValue(this.addressObject.street);
      this.addressForm.get('address_line_2')?.setValue(this.addressObject.address_line_2);
      this.addressForm.get('city')?.setValue(this.addressObject.city);
      this.addressForm.get('state')?.setValue(this.addressObject.state);
      this.addressForm.get('zipcode')?.setValue(this.addressObject.zipcode);
      this.addressForm.get('country')?.setValue(this.addressObject.country);
      this.addressForm.get('latitude')?.setValue(this.addressObject.latitude);
      this.addressForm.get('longitude')?.setValue(this.addressObject.longitude);
    }
  }

  //#endregion

  //#region private methods

  private intilizeForm() {
    this.addressForm = new FormGroup({
      'label': new FormControl(this.addressObject.label, [Validators.required]),
      'firstname': new FormControl(this.addressObject.firstname),
      'lastname': new FormControl(this.addressObject.lastname),
      'street': new FormControl(this.addressObject.street, [Validators.required]),
      'address_line_2': new FormControl(this.addressObject.address_line_2),
      'city': new FormControl(this.addressObject.city, [Validators.required]),
      'state': new FormControl(this.addressObject.state, [Validators.required]),
      'country': new FormControl(this.addressObject.country, [Validators.required]),
      'zipcode': new FormControl(this.addressObject.zipcode, [Validators.required]),
      'mobile': new FormControl(this.addressObject.mobile),
      'email': new FormControl(this.addressObject.email),
      'delivery_instruction': new FormControl(this.addressObject.delivery_instruction),
      'longitude': new FormControl(this.addressObject.longitude, [Validators.required]),
      'latitude': new FormControl(this.addressObject.latitude, [Validators.required]),
      'default': new FormControl(this.addressObject.default),
      'is_permanent': new FormControl(this.addressObject.is_permanent),
    });
  }

  private getGeoLocation(lat: number, lng: number) {
    if (navigator.geolocation) {
      let geocoder = new google.maps.Geocoder();
      let latlng = new google.maps.LatLng(lat, lng);
      geocoder.geocode({ location: latlng }, (results: any) => {
        if (results[0]) {
          this.currentLocation = results[0].formatted_address;
          this.selectLocation(results[0]);
        }
      });  
    }
  }

  //#endregion

  //#region public methods

  get label() { return this.addressForm.get('label'); }
  get street() { return this.addressForm.get('street'); }
  get city() { return this.addressForm.get('city'); }
  get state() { return this.addressForm.get('state'); }
  get country() { return this.addressForm.get('country'); }
  get zipcode() { return this.addressForm.get('zipcode'); }
  get mobile() { return this.addressForm.get('mobile'); }
  get email() { return this.addressForm.get('email'); }
  get longitude() { return this.addressForm.get('longitude'); }
  get latitude() { return this.addressForm.get('latitude'); }

  public async addressChangesAddUpdate() {
    if (this.addressForm.invalid) {
      this.helperService.markFormGroupTouched(this.addressForm);
      return;
    }
    const obj = {
      label: this.addressObject.label,
      street: this.addressObject.street,
      address_line_2: this.addressObject.address_line_2,
      city: this.addressObject.city,
      state: this.addressObject.state,
      country: this.addressObject.country,
      zipcode: this.addressObject.zipcode,
      latitude: this.addressObject.latitude.toString(),
      longitude: this.addressObject.longitude.toString(),
      firstname: this.addressObject.firstname ? this.addressObject.firstname : null,
      lastname: this.addressObject.lastname ? this.addressObject.lastname : null,
      default: this.addressObject.default == false ? false : true,
      mobile: this.addressObject.mobile,
      email: this.addressObject.email,
      delivery_instruction: this.addressObject.delivery_instruction,
      is_permanent: this.from.location == 'address' ? true : this.addressObject.is_permanent
    };

    if (this.addressModeLabel === 'add') {
      this.cmoApiService.addNewAddress(obj).then((res: any) => {
        this.close();
        this.toastr.success(res.message, this.constant.SUCCESS);
        if (this.from !== undefined && this.from.location === 'create-group') {
          this.messageService.sendMessage({msg: 'Open-Create-Group-Order', mode: this.from.mode});

        } else {
          window.location.reload();
        }

      }, (error: any) => {
        const errMsg = this.helperService.getResponseErrorMessage(error);
        if (errMsg?.length) {
          this.toastr.error(errMsg, this.constant.ERROR);
        }
      });

    } else {
      this.cmoApiService.updateAddress(this.addressId, obj).then((res: any) => {
        this.close();
        this.toastr.success(res.message, this.constant.SUCCESS);
        window.location.reload();

      }, (error: any) => {
        const errMsg = this.helperService.getResponseErrorMessage(error);
        if (errMsg?.length) {
          this.toastr.error(errMsg, this.constant.ERROR);
        }
      });
    }
  }

  public getAddress(event: any) {
    if (event == '') {
      this.places = [];
      return;
    }

    this.autoCompleteService.getPlacePredictions({
      types: ['address'],
      input: this.addressObject.street,
      componentRestrictions: { country: 'us' }
    }, (predictions: any, status: any) => {
      if (status === google.maps.places.PlacesServiceStatus.OK && predictions) {
        this.places = [];
        predictions.forEach((pred: any) => {
          if(pred.types.includes('street_address') || pred.types.includes('premise')) {
            this.places.push(pred);
          }
        });
        this.cdr.detectChanges();

      } else {
        this.places = [];
      }
    });
  }

  public selectLocation(place: any) {
    // this.addressObject.street = '';
    // this.addressObject.city = '';
    // this.addressObject.state = '';
    // this.addressObject.country = '';
    // this.addressObject.zipcode = '';
    // this.addressObject.latitude = '';
    // this.addressObject.longitude = '';

    const that = this;
    that.places = [];
    that.placeService.getDetails({ placeId: place.place_id }, (details: any) => {
      that.addressObject.street = details.name;
      for (let i = 0; i < details.address_components.length; i++) {
        const addressType = details.address_components[i].types[0];
        if (that.placeComponents[addressType]) {
          const value = details.address_components[i][this.placeComponents[addressType]];
          that.addressObject[that.autoCompleteElements[addressType]] = value;
        }
      }
      this.cdr.detectChanges();

      // get customer lat long
      that.addressObject.latitude = details.geometry.location.lat();
      that.addressObject.longitude = details.geometry.location.lng();

      this.addressForm.get('street')?.setValue(that.addressObject.street);
      this.addressForm.get('city')?.setValue(that.addressObject.city);
      this.addressForm.get('state')?.setValue(that.addressObject.state);
      this.addressForm.get('zipcode')?.setValue(that.addressObject.zipcode);
      this.addressForm.get('country')?.setValue(that.addressObject.country);
      this.addressForm.get('latitude')?.setValue(that.addressObject.latitude);
      this.addressForm.get('longitude')?.setValue(that.addressObject.longitude);

      this.addressForm.updateValueAndValidity();
    });
  }

  public onClickCurrentLocation(){
    navigator?.geolocation?.getCurrentPosition((potition: any) => {
      this.getGeoLocation(potition.coords.latitude, potition.coords.longitude);
    })
  }

  //#endregion
}
