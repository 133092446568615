import { Injectable } from '@angular/core';
import { AngularFireMessaging } from '@angular/fire/messaging';
import { BehaviorSubject } from 'rxjs';

@Injectable({
    providedIn: 'root'
})
export class FCMMessagingService {

    public currentMessage = new BehaviorSubject(null);

    constructor(
        private angularFireMessaging: AngularFireMessaging
        ) {
    }

    public requestPermission() {
        this.angularFireMessaging.requestToken.subscribe(
        (token) => {
        });
    }

    public receiveMessage() {
        this.angularFireMessaging.messages.subscribe(
        (msg: any) => {
            this.currentMessage.next(msg);
        });
    }
}
