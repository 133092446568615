import { Component, ChangeDetectorRef, HostListener } from '@angular/core';
import { ActivatedRoute, Router, NavigationEnd } from '@angular/router';
import * as Pubnub from 'pubnub';
import { environment } from 'src/environments/environment';
import { ToastrService } from 'ngx-toastr';
import { ConstantProviderService } from './providers/constant-provider.service';
import { MessageService } from './services/message.service';
import { CmoapiService } from './services/cmoWebsiteApi.service';
import { HelperService } from './services/helper.service';
import { SimpleModalService } from 'ngx-simple-modal';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.css']
})
export class AppComponent {

  //#region varibales

  public title = 'chatmyorder-website';
  
  public header = true;
  
  public loggedInUser: any;
  
  public pnbAlert: Pubnub;

  //#endregion

  //#region life cycle hook

  constructor(
    private router: Router,
    private route: ActivatedRoute,
    private cdr: ChangeDetectorRef,
    private toaster: ToastrService,
    private constant: ConstantProviderService,
    private messageService: MessageService,
    private cmoApiService: CmoapiService,
    private helper: HelperService,
    private simpleService: SimpleModalService,
  ) {
    this.getMaintenanceMode();

    this.pnbAlert = new Pubnub({
      subscribeKey: environment.pubnub_real_time.sk
    });

    if (this.helper.getFulfillmentType() == undefined || this.helper.getFulfillmentType() == null) {
      this.helper.setFulfillmentType('Delivery');
    }

    /**
     * Initilize pubnub alert setup
     */
    this.addPubnubAlertListener();

    this.loggedInUser = localStorage.getItem('cmo:id');
    if (this.loggedInUser != undefined && this.loggedInUser != null && this.loggedInUser != '') {
      this.getRefreshToken();
      this.checkSwitchToGroupOrder();
    }

    this.router.events.forEach((e) => {
      if (e instanceof NavigationEnd) {
        this.header = (route.root.firstChild?.snapshot.data.header == false) ? false : true;
        this.checkSwitchToGroupOrder(e);
      }
    });

    setTimeout(() => { this.cdr.detectChanges(); }, 2000);
  }

  //#endregion

  //#region private methods

  @HostListener('window: popstate', ['$event'])
  public onPopState(event: any) {
    // remove all modal when route changes
    this.simpleService.removeAll();
  }

  private addPubnubAlertListener() {
    this.loggedInUser = localStorage.getItem('cmo:id');
    this.loggedInUser = this.loggedInUser?.replace(/["]+/g, '');

    this.pnbAlert.setUUID = this.loggedInUser;
    const channelName = `alert.${this.loggedInUser}`;
    this.pnbAlert.subscribe({
      channels: [channelName, environment.BROADCAST_CHANNEL]
    });

    this.pnbAlert.addListener({
      message: (msg: Pubnub.MessageEvent) => {
        if (msg.message.type == 'ORDER_STATUS_UPDATED' && this.loggedInUser) {
          this.toaster.info('Order Status Updated', 'Hurray!');
          
        } else if ((msg.message.type == 'GROUP_ORDER_JOIN' || msg.message.type == 'GROUP_ORDER_LEAVE' ||
                    msg.message.type == 'GROUP_CART_SUBMITTED' || msg.message.type == 'GROUP_ORDER_ACTION') && this.loggedInUser) {
          this.messageService.sendMessage({ msg: msg.message.type, customer_id: this.loggedInUser });

        } else if (msg.message.type == 'PAUSE_DELIVERY_CANCELED') {
          this.helper.refreshComponent(window.location.pathname);
        
        } else if (msg.message.type == 'PAUSE_DELIVERY_SET') {
          this.helper.refreshComponent(window.location.pathname);

        } else if ((msg.message.type == 'ORDER_ETA_OVERDUE' || msg.message.type == 'ORDER_DELIVERY_UNAVAILABLE') && this.loggedInUser) {
          this.toaster.info(this.constant.RESPONSE_REQUIRED, this.constant.HURRY)
          this.router.navigate(['account', 'order-status-tracking', msg.message.body.order_id]);
        
        } else if (msg.message.type == 'ITEM_UPDATED' && this.loggedInUser) {
          const groupOrder = this.helper.getGroupCartList();
          const normalOrder = this.helper.getNormalCartList();
          if ((groupOrder?.store_details?._id == msg.message.data?.store_id?.toString()) ||
              (normalOrder?.store_details?._id == msg.message.data?.store_id?.toString()))
            this.helper.refreshComponent(window.location.pathname);
        
        } else if(msg.message.type == 'GROUP_OWNER_PLACED_ORDER') {
          localStorage.removeItem('switch_group_order');
          this.toaster.success(this.constant.GROUP_ORDER_PLACED, this.constant.SUCCESS);
          setTimeout(()=>{
            this.router.navigate(['account','order-details',msg.message.data.order_id]);
          },1000) 
        } else if(msg.message.type == 'GROUP_ORDER_CANCELED') {
          let storeName = this.helper.convertToSlug(msg.message.data.store_name);
          localStorage.removeItem('switch_group_order');
          this.toaster.info(this.constant.GROUP_ORDER_CANCELED_MESSAGE, this.constant.GROUP_ORDER_CANCELED);
          setTimeout(()=>{
            this.router.navigate(['restaurant',storeName,msg.message.data.store_id]);
          },1000) 
        }
      }
    });
  }

  private getMaintenanceMode() {
    this.cmoApiService.getMaintenanceMode().subscribe((data: any) => {
      data.forEach((element: any) => {
        if (element.id == environment.MAINTENANCE_MODE_DOC) {
          if (element.status) { // mode status is true

            // if user logged in
            if (localStorage.getItem('chat-my-order:token')) {
              this.helper.clearLocalStorage('maintenance-mode');

            } else {
              this.router.navigate(['maintenance-mode']);
            }

          } else if (window.location.pathname.includes('maintenance-mode')) {
            this.router.navigate(['home']);
          }
        }
      });
    });
  }

  private checkSwitchToGroupOrder(event?: any) {
    const groupOrderRedirectUrl = localStorage.getItem('switch_group_order');
    if (groupOrderRedirectUrl != undefined && groupOrderRedirectUrl != null && groupOrderRedirectUrl != '' && event && event.url.includes('/restaurant/')) {
      this.router.navigate([groupOrderRedirectUrl]);
    
    } else if (this.route.snapshot.params.oid_track) {
      this.router.navigate(['home']);
    }
  }

  private getRefreshToken() {
    const token: any = localStorage?.getItem('chat-my-order:token')
    const obj: object = {
      access_token: JSON.parse(token),
      device: this.constant.DEVICE
    };
    this.cmoApiService.updateRefreshToken(obj).then((res: any) => {
      this.messageService.sendMessage('start-interval');
      localStorage.setItem('chat-my-order:token', JSON.stringify(res.data.token))
    
    }, (error: any) => {
      this.toaster.info(this.constant.LOGIN_MESSAGE, this.constant.SORRY);
      this.helper.clearLocalStorage();
    });
  }
  
  //#endregion
}
